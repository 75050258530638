/*********************/
/*     Headerbar     */
/*********************/
.headerbar {
  display: flex;
}
.headerbar-left, .headerbar-right {
  position: relative;
}
.headerbar-left {
  padding: 0;
  .header-nav {
    margin-inline-start: 0;

    .header-nav-brand {
      .brand-holder {
        height: 80px;
      }
      img {
        max-height: 62px;
      }
    }

  }

}

.headerbar-right {
  display: flex;
  align-items: center;
  margin-inline-start: auto;
  .logout {
    color: @dark;
    i {
      font-size: 16px;
    }
  }
  .links {
    margin-bottom: 0;
    padding-inline-start: 0;
    list-style: none;
    color: @dark;
    margin-inline-end: 4rem;
  }
  .profile {
    margin: 0;
    .user {
      display: inline-flex;

      .dropdown-toggle {
        display: inline-flex;
        align-items: center;
        margin-inline-start: 24px;

        &.left-part {
          border-radius: 24px 0 0 24px;

          &:nth-last-child(2) {
            padding-inline-end: 1.5rem;
            cursor: default;
          }
        }
        i.user-icon {
          color: #B3D4E4;
          margin-inline-end: 0.6rem;
          font-size: 22px;
        }

        &.user-dropdown-toggle {
          border-radius: 0 24px 24px 0;
          border-inline-start: solid 1px #5B96B3;
          padding-inline-start: 10px;
          margin-inline-start: 0;

          i.angle-icon {
            margin-inline-end: 0.6rem;
            font-size: 16px;
          }
          &:last-child {
            display: none;
          }
        }
        .user-name-text {
          padding-inline-end: 10px;
          line-height: 1;
        }
      }
      &.open {
        .dropdown-menu {
          min-width: calc(100% - 24px);
        }
      }
    }
  }

  .headerbar-right-situation {
    font-weight: 400;
    font-size: 2.8rem;
    text-transform: uppercase;
    margin-inline-end: 24px;
    color: #000;
  }

  .headerbar-right-divider {
    border-inline-start: 1px solid @main-bg;
    margin-block: 16px;
    margin-inline-start: 0;
    margin-inline-end: 24px;
    width: 1px;
    display: inline-block;
    height: 51px;
  }

  .dropdown-menu {
    border-radius: 0;
    border: none;
    padding: 12px 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
    min-width: auto;
  }
}
.header-nav-btn > li > a {
  font-size: 14px;
}
.header-nav-btn > li > a > .user-icon {
  margin-inline-end: 0.5rem;
  font-size: 16px;
  color: @dark;
}
.header-nav-btn > li > a[class*="btn-"]:hover, .header-nav-btn > li > a[class*="btn-"]:focus {
  background-color: @dark;
  border-color: @dark;
  color: @white;
}

// Languages selector
.languages {
  margin-inline-end: 4rem;
  
  .dropdown-menu {
    position: absolute;
    border-radius: 6px;
    min-width: auto;
    border: none;
    box-shadow: 0 0 6px 0 @grey;
  }
  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: @dark;
    text-align: inherit;
    white-space: nowrap;
    border: 0;
  }
}

// Overwite Material Admin
#header {
    box-shadow: none;
    height: auto;
    height: initial;
    position: relative;
    flex: 0 1 auto;
}
// Overwrite Bootstrap
.header-nav > li {
  padding: 0;
}
.header-nav > li > a:hover,
.header-nav > li > a:focus {
  text-decoration: none;
  background-color: transparent;
}


// Media Queries
@media (max-width: 768px) {

  #djDebugToolbarHandle,
  #cms-top {
    display: none;
  }

  .headerbar-left {
    .header-nav {
      .header-nav-brand {
        .brand-holder {
          height: 64px;

          > a {
            display: flex;
            align-items: center;

            span {
              display: flex;
            }
          }
        }
        img {
          display: inline-block;
          margin-inline-end: 4px !important;
          height: 30px !important;
          vertical-align: baseline;
        }
      }
  
    }
  
  }


  .header-nav-btn > li > a {
    padding: 2px 4px;
  }

  .header-nav-btn > li {
    padding: 17px 0;
  }

  #connect > li {
    padding: 15px 0;
    margin-inline-end: 1rem !important;
    span {
      display: none;
    }
  }

  .languages {
    margin-inline-end: 2rem !important;
  }

  .links {
    margin-inline-end: 2rem !important;
  }

  .headerbar-right {
    box-shadow: none;
    float: right !important;
    inset-inline-start: auto;
    inset-inline-end: auto;
    top: auto;
    background-color: transparent;
    .profile {
      .user {
        margin-inline-end: 0;
      }
    }


  }

  .header-nav-profile .dropdown > a,
  .header-nav-profile .dropdown.open > a {
    min-height: 38px;
    padding-block: 12px;
    padding-inline-start: 0;
    padding-inline-end: 8px;
  }

  .headerbar-right .dropdown-menu {
    inset-inline-end: auto;
    inset-inline-start: 0;
    background-color: @grey;
    border-radius: 0 0 3px 3px;
  }
}

// overrides djando admin link -- TEMP
.headerbar-right {
    ul {
        &.links {
            li {
                &.child {
                    &:first-child {
                        >a {
                            content: "";
                            background-color: transparent;
                            position: relative;
                            font-family: var(--fontawesome-font);
                            font-weight: 900;
                            -webkit-font-smoothing: antialiased;
                            display: inline-block;
                            font-style: normal;
                            font-variant: normal;
                            text-rendering: auto;
                            line-height: 1;
                            transition: all .3s;
                            font-size: 0;
                            text-decoration: none;
                            &:after {
                                font-size: 1.5rem;
                                content: '\f013';
                            }
                        }
                    }
                }
            }
        }
    }
}