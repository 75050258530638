.management_bar {
    padding-top: 60px;
    margin-inline-start: 2.4rem;
    margin-inline-end: 2.4rem;

    & + #base {
        border-top: none;

        #content {
            .section-body {
                &:first-child {
                    padding-top: 0;
                    margin-top: 0;
                    margin: 0;
                }
            }
        }
    }

    ul {
        li {
            &.dropdown {
                margin-inline-start: auto;
                width: auto;
                text-decoration: none;
                /* display: inline-flex; */
                >a {
                    align-items: center;
                    padding: 6px 16px;
                    border-radius: 24px;
                    background: #fff;
                    color: #333;
                    font-weight: 300;
                    text-align: center;
                    margin-top: 9px;
                    border: 1px solid #333;
                    transition: all .3s;
                    &:hover {
                        transition: all .3s;
                        background: #333;
                        color: white;
                    }
                    .left-border-angle {
                        padding: 0 0 0 10px;
                        margin-inline-start: 10px;
                        border-inline-start: solid 1px #333;
                        line-height: 100%;
                    }
                }
                .dropdown-menu {
                    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.13);
                    width: 220px;
                    border: none;
                    padding: 0;
                    border-radius: 0;
                    max-height: 270px;
                    overflow: auto;
                    a {
                        padding: 1.5rem 2rem;
                        display: block;
                        line-height: 1;
                        font-size: 15px;
                        &:hover {
                            text-decoration: none;
                            background-color: rgba(50, 50, 50, 0.025);
                        }
                        .menu-item-icon {
                            margin-inline-end: 5px;
                        }
                        &.bottom-sticky {
                            background: #FFF;
                            border-top: solid 1px #EFEFEF;
                        }
                    }
                }
                &.open {
                    > a {
                        border-color: transparent;
                        box-shadow: 0 0 1px rgba(50, 50, 50, 0.2);
                        border-radius: 16px 16px 0 0;
                        background: #efefef;
                        color: #333;
                    }

                    >.dropdown-menu {
                        top: 40px;
                        inset-inline-end: 0;
                        inset-inline-start: auto;
                    }
                }
            }
            > a {
                padding: 1.4rem 2rem;
                font-weight: 500;
                color: #999999;
                line-height: 1;
                font-size: 15px;
                border-radius: 0;
                border-top: solid 2px transparent;
                border-bottom: solid 2px transparent;
                
                sup {
                    color: #999999;

                    &.red-badge-notif {
                        background-color: #ED5858;
                        color: white !important;
                        padding: 1px 4px 0;
                        border-radius: 20px;
                    }
                }
                &:hover, &.active {
                    color: #222222;
                    border-bottom-color: #72adca;
                }
            }
            &.nav-item:not(.dropdown) {
                > a {                    
                    font-size: 20px;
                    border-bottom: none;

                    &.active {
                        background: #3b8fb9;
                        color: white;
                        border-radius: 5px 5px 0 0;

                        sup {
                            color: white;
                            opacity: 0.5;

                            &.red-badge-notif {
                                background-color: white;
                                color: #3b8fb9 !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

.dashboard {
    table {
        > thead > tr > th {
            color: #222222;
            font-size: 12px;
            letter-spacing: 0;
        }
        &.table-stripped {
            tbody {
                tr {
                    &:nth-child(odd) {
                        background: #FAFAFA;
                    }
                    td {
                        &.file-state_to-coreect {
                            &::before {
                                border-top: 26px solid transparent;
                                border-bottom: 26px solid transparent;
                                border-inline-start: 26px solid @warning;
                            }
                        }
                    }
                }
            }
        }
    }
    .see-more {
        color: #4393bb;
        font-weight: 700;
        padding: 2rem 3.5rem;
        display: inline-block;
        line-height: 1;
    }

    .big-title {
        font-weight: 700;
        color: #444444;
        font-size: 22px;
    }

    .dashboard-tiles {
        padding-top: 2rem;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 32px;
        grid-row-gap: 32px;

        > a {
            background: #f5f6f8;
            padding: 2rem 3rem;
            transition-duration: 0.2s;
            align-items: center;
            position: relative;
            overflow: hidden;

            .service-name-container {
                position: relative;
                transition: all .3s;
                padding-inline-end: 1rem;
            }
            
            &:hover {
                box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
                text-decoration: none;
            }

            .service-description {
                line-height: 1.4;
                text-align: start;
            }

            .service-label {
                display: block;
                font-size: 15px;
                color: #AAA;
                line-height: 1;
                margin-bottom: 5px;
            }
            .service-name {
                font-size: 18px;
                line-height: 1.4;
                font-weight: 700;
                color: #333333;
                display: inline-block;
                margin-bottom: 6px;
            }
            .service-icon {
                margin-inline-start: auto;
                color: #3b8fb9;
                font-size: 4rem;
                line-height: 1;
            }
        }
    }

    .all-services-filters {
        display: none;

        &.show-filters {
            display: flex;
        }
    }

    .service-category {
        padding: 0 1em;
        display: inline-block;

        &.active {
            background-color: #999;
            border-radius: 2em;
            color: #fff;
        }
    }

    ul.service-categories {
        display: flex;
        align-items: stretch; /* Default */
        justify-content: flex-start;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    ul.service-categories li {
        display: block;
        flex: 0 1 auto;
        margin-inline-end: 0.5em;
        list-style-type: none;
        line-height: 11px;
        font-size: 11px;
        font-weight: 400;
    }

    .service-visibility-switch {
        padding: 0.33em 1em 0.22em;
        background-color: #f5f6f8;
        color: #AAA;
        &:hover,
        &.active {
            background-color: #999;
            color: #fff;
        }
    }

    .services-title-search-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 1em;
        margin-bottom: 2em;
        margin-inline: 0;

        .services-search-icon {
            margin-inline-end: -27px;
            z-index: 99;
            margin-inline-start: 10px;
            color: #222222;
        }

        .form-control {
            opacity: 0.3;
            transition-duration: 0.2s;
            height: 32px;
            padding-inline-start: 34px;

            &:focus,
            &:hover {
                opacity: 1;
                transition-duration: 0.2s;
                box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
            }
        }
        .legend-info-icon {
            opacity: 0.7;
            transition-duration: 0.2s;

            &:hover {
                opacity: 1;
            }
        }
    }

    .services-title-search-container * {
        display: flex;
        width: auto;
        flex: 0 1 auto;
        margin: 0;
        margin-inline-end: 1em;
        &.form-control {
            border: solid 1px transparent;
            border-radius: 16px;
            background: rgba(229, 230, 230, 0.25);
        }
    }

    #applications-list,
    #tab-documents,
    #tab-messages {
        padding: 2rem;
        border: solid 1px #3b8fb9;
    }
    #tab-documents {
        .badge-valid {
            background-color: #8AD8B2;
        }
        .badge-certified {
            background-color: #92C8DC;
        }
    }
    #applications-list {
        ul.warning-list {
            margin-top: -21px;
        }
        .legend-info-icon {
            opacity: 0.7;
            transition-duration: 0.2s;

            &:hover {
                opacity: 1;
            }
        }
        .table-paging-container {
            overflow: hidden;
        }
    }
    .d-table {
        display: table;
    }
    .d-table-cell {
        display: table-cell;
    }
    .h-100 {
        height: 100%;
    }
    .v-align-middle {
        vertical-align: middle;
    }
    .mb-6 {
        margin-bottom: 6rem;
    }
    .w-30 {
        width: 30%;
    }
    .w-22 {
        width: 22%;
    }
    table {
        thead {
            &.heading-with-filters {
                tr {
                    th {
                        text-align: start;
                        vertical-align: top !important;
                        
                        input {
                            display: block;
                            width: 100%;
                            border-radius: 2px;
                            margin-top: 10px;
                            border: solid 1px transparent;
                            border-radius: 12px;
                            padding: 4px 8px;
                            transition: all .3s;
                            font-weight: 400;

                            &:where(:hover, :focus) {
                                background-color: rgba(229, 230, 230, 0.5);
                                border-color:rgba(229, 230, 230, 0.75);
                                outline: none;
                                transition: all .3s;
                                box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
                            }
                        }
                    }
                }
            }
        }
    }
}

.messages-container {
    padding: 20px;
    background-color: #ddeeeb;

    .alert-callout {
        max-width: 1200px;
        margin-left: auto !important;
        margin-right: auto !important;
        padding-left: 2rem !important;

        &::before {
            width: 6px;
            background: #389F8F;
            border-radius: 4px;
        }
        &::after {
            display: none !important;
        }
    }
}

/* Documents Tab revamp / redesign */ 
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) {
    & + #base {
        background-color: white;
        border-top: none;
        overflow: visible;

        .dashboard {
            :where(#tab-documents, #tab-messages, #tab-applications) {
                h2.big-title {
                    font-weight: 700;
                    color: #000000;
                    display: flex;

                    .docs-count {
                        margin-inline-start: 6px;
                    }

                    .add-document-from-list {
                        margin-inline-start: auto;
                        display: inline-block;
                        background-color: #3F8EB9;
                        color: #ffffff;
                        text-transform: uppercase;
                        font-size: 13px;
                        font-weight: 600;
                        padding: 10px 15px;
                        border-radius: 2px;
                        cursor: pointer;

                        &:hover {
                            box-shadow: 3px 4px 4px rgba(0,0,0,0.1);
                        }
                    }
                }
                table.table {
                    thead {
                        tr {
                            th {
                                padding: 1.5rem 2.5rem;
                                color: #000000;
                                vertical-align: middle;
                                border: none;

                                &.date-col {
                                    width: 100px;
                                    padding: 1.5rem;
                                    text-align: center;
                                }
                                &:hover {
                                    background-color: white;
                                }
                                &.opt-col {
                                    width: 97px;
                                }
                                &.msg-subj {
                                    padding-inline-start: 5.8rem;
                                }
                                &.status-col {
                                    width: 256px;
                                }
                            }
                        }
                    }
                    tbody {
                        tr {
                            td {
                                border: solid 2px #ffffff;
                                background-color: #F5F6F8;
                                padding: 1.5rem 2.5rem;
                                color: #313335;
                                vertical-align: middle;
                                position: relative;

                                &:not(.app-options) a {
                                    display: block;
                                }
                                &.no-border {
                                    border-inline-start: none;
                                    border-inline-end: none;
                                }
                                &.doc-status {
                                    border-inline-start: none;

                                    &.valid {
                                        box-shadow: inset 5px 0 0 #8AD8B2;
                                    }
                                    &.expiring {
                                        box-shadow: inset 5px 0 0 #FFB99A;
                                    }
                                    &.expired {
                                        box-shadow: inset 5px 0 0 #F88B8B;
                                    }
                                }
                                &.date-col {
                                    width: 100px;
                                    padding: 1.5rem;
                                    text-align: center;
                                }
                                &.status-col {
                                    width: 256px;
                                }
                                &.msg-status {
                                    border-inline-start: none;
                                    box-shadow: inset 5px 0 0 #418DB9;
                                    font-weight: 500;
                                    padding-inline-start: 2.5rem;

                                    i {
                                        margin-inline-end: 1.5rem;
                                        background-color: white;
                                        padding: 5px;
                                        font-size: 10px;
                                        border-radius: 20px;
                                        transform: scale(0);
                                        transition-duration: 0.1s;
                                    }

                                    &.seen {
                                        box-shadow: inset 5px 0 0 #DCDEE3;
                                    }
                                }
                                &.app-status {
                                    border-inline-start: none;

                                    &.status-warning {
                                        box-shadow: inset 5px 0 0 #FFAF89;
                                    }
                                    &.status-success {
                                        box-shadow: inset 5px 0 0 #7BC39B;
                                    }
                                    &.status-info {
                                        box-shadow: inset 5px 0 0 #92C8DC;
                                    }
                                    &.status-danger {
                                        box-shadow: inset 5px 0 0 #D56D72;
                                    }
                                    &.status-default {
                                        box-shadow: inset 5px 0 0 #c7ced7;
                                    }
                                }
                                &.results-col {
                                    font-size: 24px;
                                }
                                .document-options {
                                    background: transparent;
                                    background-image: none;
                                    border: none;
                                    transition-duration: 0.1s;
                                    outline: none;
                                    display: inline-flex;
                                    align-items: center;
                                    height: 20px;

                                    &:hover {
                                        transition-duration: 0.1s;

                                        i {
                                            transform: scale(1.2);
                                        }
                                    }
                                    &:focus {
                                        transition-duration: 0.1s;
                                        background: white;
                                        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);

                                        ~ .document-options-modal {
                                            transition-duration: 0.1s;
                                            transition-delay: 0.1s;
                                            transition-timing-function: ease-in-out;
                                            transform: scale(1);
                                        }
                                    }
                                }
                                .document-options-modal {
                                    position: absolute;
                                    transition-delay: 0.3s;
                                    transition-duration: 0.1s;
                                    transition-timing-function: ease-in-out;
                                    transform: scale(0);
                                    z-index: 10;
                                    background: white;
                                    inset-inline-end: 3.5rem;
                                    top: calc(50% + 9px);
                                    width: 150px;
                                    margin: 0;
                                    padding: 0;
                                    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);

                                    li {
                                        list-style: none;
                                        display: grid;
                                        grid-template-columns: 48px auto;
                                        align-items: center;
                                        padding: 1rem 0;
                                        text-align: start;
                                        text-transform: capitalize;
                                        cursor: pointer;

                                        i {
                                            text-align: center;
                                        }
                                        &:hover {
                                            background: #fafbfd;
                                        }
                                        &:active {
                                            background: #edeff1;
                                        }
                                    }
                                }
                                .remove-msg {
                                    transform: scale(0);
                                    transition-duration: 0.1s;
                                    color: #ED5858;
                                }
                                .badge {
                                    &.blue {
                                        background-color: #54B3E6;
                                    }
                                }
                            }
                            &:hover {
                                td {
                                    background-color: #fafbfd;

                                    &.msg-status {
                                        i {
                                            transform: scale(1);
                                            transition-duration: 0.1s;
                                        }
                                    }
                                    .remove-msg {
                                        transform: scale(1);
                                        transition-duration: 0.1s;
                                    }
                                }
                            }
                            &.active {
                                td {
                                    border-bottom: solid 2px #3C8FB9;
                                    color: #3C8FB9;
                                    background-color: #fafbfd;

                                    .remove-msg {
                                        transform: scale(1);
                                        transition-duration: 0.1s;
                                    }
                                    &.msg-status {
                                        i {
                                            transform: scale(1);
                                            transition-duration: 0.1s;
                                        }
                                    }
                                }
                            }
                            &.message-body {
                                td {
                                    box-shadow: inset 5px 0 0 #DCDEE3;
                                    border: none;
                                    padding-inline-start: 5.8rem !important;
                                    background-color: white;
                                }
                            }
                        }
                    }
                }
                .table-paging-container {
                    background-color: white;
                }
            }
        }
    }
}
.doc-preview-modal {
    position: fixed;
    z-index: 9999;
    top: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);

    .doc-preview-modal-grid {
        height: 80vh;
        width: 80%;
        margin: 10vh auto;
        display: grid;
        grid-template-rows: minmax(48px, max-content) auto;
        grid-gap: 20px;
        background-color: white;
        padding: 32px;
        .doc-preview-modal-header {
            position: relative;
            display: grid;
            align-items: center;
            grid-template-columns: auto auto;

            .doc-preview-modal-title {
                font-size: 19px;
                font-weight: 400;
                display: flex;
                align-items: center;
                margin: 0;

                i {
                    font-size: 18px;
                    margin-inline-end: 10px;
                }
                .doc-status-label {
                    line-height: 1;
                    font-size: 10px;
                    text-transform: uppercase;
                    background: darkgray;
                    color: white;
                    padding: 3px 10px;
                    border-radius: 10px;
                    font-weight: 600;
                    letter-spacing: 1px;
                    margin-inline-start: 15px;

                    span {
                        line-height: 1;
                    }
                    &.status-valid {
                        background-color: #8AD8B2;
                    }
                    &.status-expiring {
                        background-color: #FFB99A;
                    }
                    &.status-expired {
                        background-color: #F88B8B;
                    }
                }
            }
            .close-modal-btn {
                position: absolute;
                top: -86px;
                inset-inline-end: -30px;
                font-size: 32px;
                color: white;
            }
            .doc-preview-modal-opts {
                display: flex;
                justify-content: flex-end;

                .doc-preview-modal-opt-item {
                    padding: 1rem 2rem;
                    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
                    text-transform: capitalize;
                    display: inline-flex;
                    align-items: center;
                    margin-inline-start: 10px;

                    &:first-child {
                        margin-inline-start: 0;
                    }                    
                    i {
                        margin-inline-end: 10px;
                    }
                    &.results-paging {
                        .results-paging-text {
                            span + span {
                                text-transform: lowercase;
                            }
                        }
                        .paging-btn {
                            padding: 0 8px;

                            i {
                                margin: 0;
                            }
                            &.previous {
                                margin-inline-end: 6px;
                            }
                            &.next {
                                margin-inline-start: 6px;
                            }
                        }
                    }
                }
            }
            
        }
        
    }
}

/* Dashboard tooltip */
.dashboard-tooltip-handler {
    z-index: 9;
    position: relative;
    padding-inline-end: 18px;

    .heading-label {
        &::before {
            content: "";
            width: 12px;
            height: 12px;
            border-radius: 3px;
            transform: rotate(45deg);
            background-color: @accentColor10Solid;
            position: absolute;
            inset-inline-start: 50px;
            transition-delay: 0.6s;
            transition-duration: 0.4s;
            top: 0;
            opacity: 0;
        }
        &::after {
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
            font-family: var(--fontawesome-font);
            font-weight: 300;
            content: "\F059";
            z-index: 9999;
            margin-inline-start: 8px;
        }
    }
    &:hover {
        .heading-label {
            &::before {
                top: -14px;
                opacity: 1;
                transition-delay: 0.2s;
            }
        }
        .dashboard-tooltip {
            opacity: 1;
            bottom: calc(100% + 8px);
            z-index: 1;
            transition-delay: 0.2s;
        }
    }
    .dashboard-tooltip {
        position: absolute;
        z-index: -1;
        opacity: 0;
        transition-duration: 0.4s;
        transition-delay: 0.6s;
        bottom: 0;
        inset-inline-end: -2px;
        background-color: #e7e9ed;
        color: #4a6288;
        padding: 12px;
        border-radius: 6px;
        text-transform: none;
        white-space: nowrap;

        &::after {
            content: "";
            width: 12px;
            height: 12px;
            border-radius: 3px;
            transform: rotate(45deg);
            background-color: #e7e9ed;
            position: absolute;
            inset-inline-end: 8px;
            bottom: -5px;
            opacity: 0;
        }
    }
}

/* My Account */
#my-account-page {
    padding: 2.4rem 2.4rem 0;
    z-index: 1000;
    min-height: initial;
    
    .formio-form {
        max-width: var(--page-wrapper-form-width, 1200px);
        margin-inline: auto;
        min-height: initial;

        .formio-wizard-nav-container {
            display: none;
        }
    }
    > div {
        > div {
            > nav {
                display: none;
            }
        }
    }
}

@media all and (max-width: 768px) {
    .tab-applications-active {
        & + #base {
            .dashboard {
                #tab-applications {
                    table {
                        &.table {
                            thead, tbody {
                                tr {
                                    th, td {
                                        padding: 1.5rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media all and (max-width: 640px) {
    .dashboard .dashboard-tiles {
        grid-template-columns: 1fr;
    }
}