body {
    &.modern-theme {
        #base {
            background-color: var(--color-gray-75);
      
            #content {
                .section-body {
                    &:has(.application-details-view-part-a) {
                        margin-top: 0px;
                    }
                    .contain-lg {
                        .current-service-container {
                            &.finished-service-bar {
                                height: 40px;

                                .service-bar-user-lang-menu {
                                    margin-inline-start: auto;
                                    margin-inline-end: -1.5rem;

                                    .headerbar-right {
                                        height: 40px;
                                        flex-direction: row-reverse;

                                        ul {
                                            &#language_selector_container {
                                            > li {
                                                &.dropdown-user {
                                                    a {
                                                        &.dropdown-toggle {
                                                            padding: 0 1.5rem;
                                                            height: 40px;
                                                            display: flex;
                                                            align-items: center;
                                                            gap: 6px;
                                                            line-height: 1;

                                                            i {
                                                                font-size: 16px;
                                                                margin-inline-start: 0.8rem;
                                                            }
                                                        }
                                                    }
                                                    &.open {
                                                        ul {
                                                            &.dropdown-menu {
                                                                padding: 0;
                                                                inset-inline-end: inherit;
                                                                border-radius: 0 @roundedCorners @roundedCorners @roundedCorners;
                                                                min-width: 100%;

                                                                li {
                                                                    
                                                                    &:first-child {
                                                                        a {
                                                                            border-radius: 0 @roundedCorners 0 0;
                                                                        }
                                                                    }
                                                                    &:last-child {
                                                                        a {
                                                                            border-radius: 0 0 @roundedCorners @roundedCorners;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                    @media screen and (max-width: 768px) {
                                                        padding: 0;
                                                    }
                                                }
                                            }
                                            }
                                            &#connect {
                                                height: 100%;

                                                li {
                                                    &.dropdown {
                                                        &.user {
                                                            height: 100%;

                                                            a {
                                                                text-transform: none;
                                                                font-weight: 600;

                                                                &.dropdown-toggle {
                                                                    &.left-part {
                                                                        margin: 0;
                                                                        border: none;
                                                                        padding-inline-start: 1.5rem;
                                                                        padding-inline-end: 0;
                                                                        border-radius: 0;

                                                                        i {
                                                                            &.user-icon {
                                                                                display: none;
                                                                            }
                                                                        }
                                                                        span {
                                                                            &.user-name-text {
                                                                                padding: 0;

                                                                                @media screen and (max-width: 768px) {
                                                                                    display: block;

                                                                                    .user-initials {
                                                                                        &[hidden] {
                                                                                            display: inline-flex;

                                                                                            span {
                                                                                                display: block;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                    &.user-dropdown-toggle {
                                                                        border: none;
                                                                        padding-inline-end: 1.3rem;
                                                                        padding-inline-start: 1.3rem;
                                                                        border-radius: 0;
                                                                        
                                                                        i {
                                                                            &.angle-icon {
                                                                                margin-inline-end: 0;
                                                                                font-weight: 300;
                                                                                font-size: 16px;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            &.open {
                                                               
                                                                ul {
                                                                    &.dropdown-menu {
                                                                        width: 100%;
                                                                        min-width: fit-content;
                                                                        padding: 0;
                                                                        border-radius: 0 0 @roundedCorners @roundedCorners;

                                                                        li {
                                                                            &.divider {
                                                                                margin: 0;
                                                                                background-color: @accentColor10;
                                                                                height: 1px;

                                                                                &:first-child {
                                                                                    display: none;
                                                                                }
                                                                            }
                                                                            a {
                                                                                padding: 7px 20px;
                                                                                font-size: 13px;
                                                                                font-weight: 600;
                                                                            }
                                                                            &:last-child {
                                                                                a {
                                                                                    border-radius: 0 0 @roundedCorners @roundedCorners !important;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            @media screen and (max-width: 768px) {
                                                                padding: 0;
                                                            }
                                                        }
                                                    }
                                                    &:not(.dropdown) {
                                                        height: 100%;

                                                        a {
                                                            height: 100%;
                                                            display: flex;
                                                            align-items: center;
                                                            border-radius: 0 !important;
                                                            margin: 0 !important;
                                                            padding: 0 2rem;
                                                            text-transform: none;
                                                            font-weight: 600;
                                                            box-shadow: none !important;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        @media screen and (max-width: 768px) {
                                            min-height: 40px;
                                        }
                                    }
                                    @media screen and (max-width: 1200px) {
                                        margin-inline-end: 0;
                                    }
                                }
                                &.affix {
                                    z-index: 1050;
                                    top: 0;
                                    margin: 0;
                                    width: 100%;
                                    max-width: inherit;

                                    & + div {
                                        padding-top: 40px;
                                    }
                                }
                            }
                        }
                        .application-details-view-part-a {
                            background-color: var(--menu-bar-bg-color);
                            border-block: solid 1px var(--color-gray-200);
                            --go-offset: 50%;

                            &::after,
                            &::before {
                                content: "";
                                height: 64px;
                                position: absolute;
                                width: var(--go-offset);
                                background-color: var(--menu-bar-bg-color);
                                z-index: -1;
                                border-block: solid 1px var(--color-gray-200);
                            }
                            &::after {
                                right: calc(0% - var(--go-offset));
                            }
                            &::before {
                                left: calc(0% - var(--go-offset));
                            }
                            & + div {
                                margin-top: 30px;
                            }
                            &.affix {
                                width: calc(100% - 4.8rem - (100% - var(--page-wrapper-content-width, 1200px)));
                                z-index: 1060;
                                top: 0px;

                                & + div {
                                    margin-top: 94px;
                                }
                            }
                        }
                        .finished-service-forms,
                        div {
                            > .row {
                                > .col-sm-12 {
                                    > .panel {
                                        padding: @panelBodyPadding;
                                        background: @whiteColor;
                                        border: none;
                                        border-radius: @roundedCorners;
                                        box-shadow: 0px 0px 20px @black5Color;

                                        .panel-heading {
                                            padding: 0 !important;
                                            align-items: center;

                                            h3 {
                                                padding: 0;
                                                font-size: 21px;
                                                color: var(--color-gray-900);
                                                font-weight: 600;
                                                font-family: var(--main-font-family);
                                            }
                                            .process-box-toggler {
                                                cursor: pointer;
                                                display: flex;
                                                align-items: center;
                                                gap: 6px;
                                                
                                                .fa-times {
                                                    display: none;
                                                }
                                                span {
                                                    color: var(--color-gray-800);
                                                    line-height: 1.2;
                                                    border-bottom: solid 1px;
                                                }
                                                &:not(.closed) {
                                                    .fa-times {
                                                        display: inline-block;
                                                        margin-inline-end: 10px;
                                                    }
                                                    .fa-cog {
                                                        display: none;
                                                    }
                                                    span {
                                                        display: none;
                                                    }
                                                }
                                            }
                                            .restart-process-box {
                                                margin-top: 0 !important;

                                                &.closed {
                                                    display: none;
                                                }
                                            }
                                        }
                                        .panel-body {
                                            padding: 2rem 0 0 !important;

                                            > .row {
                                                margin: 0;

                                                > .col-sm-12 {
                                                    padding: 0;

                                                    table {
                                                        &.table {
                                                            &.table-history {
                                                                display: block;

                                                                tbody {
                                                                    display: flex;
                                                                    padding: 1rem 0;

                                                                    tr {
                                                                        flex: 1;
                                                                        display: grid;
                                                                        position: relative;
                                                                        padding: 20px;
                                                                        transition-duration: 0.3s;
                                                                        max-width: 230px;
                                                                        min-width: 150px;

                                                                        td {
                                                                            text-align: center;
                                                                            padding: 0 !important;
                                                                            min-width: 150px;

                                                                            &:nth-child(2) {
                                                                                margin-bottom: 4rem;
                                                                                font-size: 12px;
                                                                                font-weight: 500;
                                                                                color: @darkTextColor80;
                                                                                min-height: 18px;

                                                                                &::before {
                                                                                    content: "";
                                                                                    display: block;
                                                                                    position: absolute;
                                                                                    top: calc(50% - 1px);
                                                                                    inset-inline-start: 0;
                                                                                    z-index: 1;
                                                                                    width: 100%;
                                                                                    height: 2px;
                                                                                    background-color: @grayAColor;
                                                                                    transition-duration: 0.3s;
                                                                                }
                                                                                &::after {
                                                                                    content: "";
                                                                                    display: block;
                                                                                    position: absolute;
                                                                                    top: calc(50% - 6px);
                                                                                    inset-inline-start: calc(50% - 6px);
                                                                                    z-index: 2;
                                                                                    border: solid 2px @grayAColor;
                                                                                    background-color: @whiteColor;
                                                                                    width: 12px;
                                                                                    height: 12px;
                                                                                    border-radius: 8px;
                                                                                    transition-duration: 0.3s;
                                                                                }
                                                                                small {
                                                                                    font-size: 100%;
                                                                                }
                                                                            }
                                                                            &:nth-child(3) {
                                                                                grid-row: 4;
                                                                                min-height: 24px;
                                                                                overflow: hidden;

                                                                                span {
                                                                                    text-overflow: ellipsis;
                                                                                    white-space: nowrap;
                                                                                    overflow: hidden;
                                                                                    padding-inline: 6px;
                                                                                }
                                                                            }
                                                                            span {
                                                                                &.label {
                                                                                    background-color: transparent;
                                                                                    text-transform: none;
                                                                                    font-size: 14px;
                                                                                    font-weight: 500;
                                                                                    color: @darkTextColor;
                                                                                    overflow: hidden;
                                                                                    white-space: nowrap;
                                                                                    text-overflow: ellipsis;
                                                                                    display: block;
                                                                                }
                                                                                &.text-light {
                                                                                    font-size: 12px;
                                                                                    font-weight: 500;
                                                                                    color: @darkTextColor80;
                                                                                    margin-top: 6px;
                                                                                    display: block;
                                                                                    text-transform: capitalize;
                                                                                }
                                                                            }
                                                                        }
                                                                        &:first-child {
                                                                            td {
                                                                                &:nth-child(2) {    
                                                                                    &::before {
                                                                                        inset-inline-start: 50%;
                                                                                        width: 50%;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                        &:last-child {
                                                                            box-shadow: 0px 0px 10px @black5Color;
                                                                            border: 1px solid @grayAColor3;
                                                                            border-radius: @roundedCorners;

                                                                            td {
                                                                                &:nth-child(2) {    
                                                                                    &::before {
                                                                                        inset-inline-start: -1px;
                                                                                        width: 50%;
                                                                                    }
                                                                                    &::after {
                                                                                        background-color: @grayAColor;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                        &:hover {
                                                                            background-color: transparent;
                                                                            box-shadow: 0px 0px 10px @black5Color;
                                                                            border-radius: @roundedCorners;

                                                                            td {
                                                                                background-color: transparent;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            &.collapse {
                                                padding-top: 0 !important;
                                                display: none;

                                                &.in {
                                                    padding-top: 2rem !important;
                                                    display: flex;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            #rolereview {
                                .formio-component-form {
                                    .formio-form {
                                        .formio-component-tabs {
                                            > .card {
                                                background-color: transparent;
                                                box-shadow: none;
                                                border-radius: @roundedCorners !important;
                                                padding: 0px;

                                                > .card-header {
                                                    height: auto;
                                                    margin-bottom: -2px;
                                                    z-index: 99;
                                                    position: relative;
                          
                                                    ul {
                                                        &.card-header-tabs {
                                                            background-color: transparent;
                                                            height: auto;
                                                            max-width: 100%;
                                                            min-width: 100px;
                                                            overflow-y: auto;
                          
                                                            li {
                                                                &.nav-item {
                                                                    flex: 0;
                                                                    height: auto;
                                                                    margin-bottom: 0;
                          
                                                                    &::before {
                                                                        display: none;
                                                                    }
                                                                    a {
                                                                        &.nav-link {
                                                                            font-size: 20px;
                                                                            color: @inactiveTabTextColor;
                                                                            font-weight: 600;
                                                                            padding-inline-start: 24px;
                                                                            padding-inline-end: 24px;
                                                                            border-top-left-radius: calc(@roundedCorners + 2px); // (2px the border width) it creates the illusion of smaller corner
                                                                            border-top-right-radius: calc(@roundedCorners + 2px);
                                                                            line-height: 1;
                                                                            padding-top: 22px;
                                                                            padding-bottom: 20px;
                                                                            text-decoration: none;
                                                                            white-space: nowrap;
                                                                            text-transform: none;
                                                                            border-style: solid;
                                                                            border-width: 0px;
                                                                            border-color: transparent;
                                                                            box-shadow: none;
                          
                                                                            &.active {
                                                                                font-size: @guidePanelHeadingFontSize;
                                                                                font-weight: 600;
                                                                                color: var(--color-primary-600);
                                                                                background-color: @tabsComponentBgColor;
                                                                                border-color: #edeff2;
                                                                                border-bottom-color: transparent;
                                                                            }
                                                                            &:hover {
                                                                                box-shadow: inset 0 -2px white;
                                                                            }
                                                                        }
                                                                    }
                                                                    &.active {
                                                                        box-shadow: 0px 16px 20px @black3Color;
                                                                    }
                                                                }
                                                            }
                                                            &::-webkit-scrollbar {
                                                                width: 6px;
                                                                height: 6px;
                                                            }
                                                            &::-webkit-scrollbar-thumb {
                                                                border-radius: 4px;
                                                                background-color: @accentColor40;
                                                            }
                                                            &::-webkit-scrollbar-track {
                                                                background: @black5Color;
                                                            }
                                                            &:hover {
                                                                &::-webkit-scrollbar-thumb {
                                                                    background-color: @accentColor70;
                                                                }
                                                            }
                                                        }
                                                    }
                                                    & + .card-body {
                                                        &.tab-pane {
                                                            &.active {
                                                                border-top-left-radius: 0;
                                                            }
                                                        }
                                                    }
                                                }
                                                .card-body {
                                                    &.tab-pane {
                                                        background-color: @tabsComponentBgColor;
                                                        box-shadow: none;
                                                        border-radius: @roundedCorners;
                                                        padding: calc(@panelBodyPadding + @panelBodyPadding / 2) @panelBodyPadding @panelBodyPadding;
                                                        margin-top: 0;
                                                        flex-direction: column;
                                                        gap: 3rem;

                                                        > .formio-component-panel,
                                                        > .formio-component-container > div > .formio-component-panel {
                                                            margin-top: 0;

                                                            .card {
                                                                background-color: transparent;

                                                                .card-header {
                                                                    padding: 0;
                                                                }
                                                                .card-body {
                                                                    &:not(:first-child) {
                                                                        padding: 1rem 0 0 !important;
                                                                    }
                                                                    &:first-child {
                                                                        padding: 0 !important
                                                                    }

                                                                    .formio-component-file {
                                                                        .list-group {
                                                                            margin-bottom: 0;
                                                                        }
                                                                    }
                                                                    
                                                                    > .formio-component-panel {
                                                                        .card {
                                                                            margin: 0;
                                                                            border: none;

                                                                            .card-header {
                                                                                margin: 0 0 1rem;
                                                                                background-color: transparent;
                                                                            }
                                                                            .card-body {
                                                                                padding-top: 0;

                                                                                .formio-component-panel {
                                                                                    &:first-child {
                                                                                        .card {
                                                                                            .card-body {
                                                                                                padding-top: 0;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                    .readonly-form-columns {
                                                                        .formio-component-textarea {
                                                                            .card {
                                                                                &.card-body {
                                                                                    padding-top: 0 !important;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                &::after {
                                                                    display: none;
                                                                }
                                                            }
                                                            &:last-child {
                                                                margin-bottom: 0;

                                                                > .card {
                                                                    margin-bottom: 0 !important;
                                                                }
                                                            }
                                                        }
                                                        > .formio-component-container {
                                                            margin-top: 0;

                                                            .readonly-forms-block ~ .readonly-forms-block {
                                                                margin-top: 2.5rem;
                                                            }
                                                        }
                                                        > .formio-component-table {
                                                            &.formio-component-documentsTabTable {
                                                                margin-top: 0px;
                                                                > table {
                                                                    > tbody {
                                                                        > tr {
                                                                            > td {
                                                                                &:first-child {
                                                                                    padding-inline-start: 0;
                                                                                }
                                                                                &:last-child {
                                                                                    padding-inline-end: 0;
                                                                                }
                                                                                td {
                                                                                    padding: 1.2rem;

                                                                                    &.review-certificates-container {
                                                                                        padding: 0px;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                td {
                                                                    .formio-component-datagrid {
                                                                        &:only-child {
                                                                            margin-top: 0px;
                                                                        }
                                                                        .card {
                                                                            margin-bottom: 0px;
                                                                        }
                                                                    }
                                                                    &.dg-action-col {
                                                                        display: none;
                                                                    }
                                                                }
                                                                table.datagrid-table {
                                                                    .formio-component-panel:only-child {
                                                                        margin-block: 0px;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        > .formio-component-button {
                                                            &.formio-component-printEntirePartAButton {
                                                                text-align: end;
                                                                margin-bottom: -24px !important;
                                                                
                                                                button {
                                                                    &.btn {
                                                                        border-radius: @roundedCorners !important;
                                                                        background-color: var(--color-primary-600);
                                                                        padding-inline-start: 25px;
                                                                        padding-inline-end: 25px;
                                                                        font-weight: 600;
                                                                        text-transform: none;
                                                                        position: relative;
                                                                        z-index: 1;
                                                                        height: 30px;
                                
                                                                        &:hover {
                                                                            box-shadow: 0 9px 20px @accentColor40 !important;
                                                                            opacity: 0.9;
                                                                        }
                                                                    }
                                                                }
                                                                & + .formio-component-container {
                                                                    > [ref="nested-previewcontainer"] {
                                                                        > .readonly-forms-block:first-child {
                                                                            > .card {
                                                                                > .card-body {
                                                                                    &:first-child {
                                                                                        margin-top: 24px;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        &.active {
                                                            display: flex !important;
                                                            border-style: solid;
                                                            border-width: 0px;
                                                            border-color: #edeff2;
                                                            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
                                                        }
                                                        &::before,
                                                        &::after {
                                                            display: none;
                                                        }
                                                    }
                                                }
                                                &.card-vertical {
                                                    > .card-header + .card-body {
                                                        &.tab-pane {
                                                            &.active {
                                                                border-top-left-radius: @roundedCorners;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .panel {
                                &.overview-panel {
                                    padding: @panelBodyPadding;
                                    border: solid 1px white;
                                    margin-bottom: 24px;

                                    .panel-heading {
                                        padding: 0;
                                        cursor: pointer;

                                        .panel-title {
                                            padding: 0;
                                            font-size: 20px;
                                            color: var(--color-gray-900);
                                            font-weight: 600;
                                            font-family: inherit;

                                            i {
                                                height: 26px;
                                                font-size: 27px;
                                            }
                                        }
                                        & + .panel-body {
                                            &.collapsing,
                                            &.in {
                                                padding-top: 2rem !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .finished-service-tabs {
                            ul {
                                &.nav.nav-tabs.card-head {
                                    > li {
                                        box-shadow: none;
                                        height: auto;

                                        a {
                                            font-size: 20px;
                                            color: var(--color-gray-900);
                                            font-weight: 600;
                                            padding-inline-start: 42px;
                                            padding-inline-end: 42px;
                                            border-top: none;
                                            border-top-left-radius: @roundedCorners;
                                            border-top-right-radius: @roundedCorners;
                                        }
                                        &.active {
                                            a {
                                                background-color: @accentColor10;
                                                color: var(--color-primary-600);
                                            }
                                        }
                                    }
                                }
                            }
                            .panel-body {
                                &.tab-content {
                                    background: @whiteColor;
                                    border: none;
                                    border-radius: @roundedCorners;
                                    box-shadow: 0px 0px 20px @black5Color;

                                    .tab-pane {
                                        box-shadow: none;
                                        background-color: @whiteColor;

                                        &#documents {
                                            padding: 35px !important;
                                            border-radius: 0 @roundedCorners @roundedCorners @roundedCorners;
    
                                            #documents-list,
                                            #certificats-list {
                                                h3 {
                                                    margin-top: 0;
                                                    padding-top: 0;
                                                }
                                            }
                                        }
                                        &#file_cata {
                                            border-radius: @roundedCorners;

                                            .zone_revision {
                                                .revision-block {
                                                    form {
                                                        > .form-field-type-button {
                                                            margin-inline-end: 35px;
                                                            margin-top: 21px;
                                                        }
                                                        > .form-field-type-mytabs {
                                                            .tabs-above {
                                                                ul {
                                                                    &.nav-tabs {
                                                                        background-color: @whiteColor;
                                                                        border-radius: @roundedCorners @roundedCorners 0 0;
                                                                        margin-top: 14px;
    
                                                                        li {
                                                                            &:first-of-type {
                                                                                a {
                                                                                    border-radius: @roundedCorners 0 0 0;
                                                                                }
                                                                            }
                                                                            a {
                                                                                color: var(--color-primary-600);
                                                                                text-decoration: none;
                                                                                font-weight: 600;
                                                                                padding: 0 35px;
                                                                            }
                                                                            &:hover {
                                                                                a {
                                                                                    border-bottom: none;
                                                                                    box-shadow: inset 0 -2px 0 var(--color-primary-600) !important;
                                                                                }
                                                                            }
                                                                            &.active {
                                                                                box-shadow: none;    
    
                                                                                a {
                                                                                    background-color: transparent;
                                                                                    box-shadow: inset 0 -2px 0 var(--color-primary-600) !important;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                .tab-content {
                                                                    .tab-pane {
                                                                        .card {
                                                                            .card-body {
                                                                                > .form-field-type-panel {
                                                                                    > .panel {
                                                                                        > .panel-body {
                                                                                            padding: 35px;
                                                                                            background: @whiteColor;

                                                                                            .form-field-type-panel {
                                                                                                .panel {
                                                                                                    background-color: @whiteColor;

                                                                                                    .panel-heading {
                                                                                                        background-color: transparent;
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        > .card {
                                            > .card-body {
                                                > .form-field-type-panel {
                                                    .panel {
                                                        background-color: @whiteColor;

                                                        .form-field-type-panel {
                                                            .panel {
                                                                margin: 0;
                                                                padding: 0;
                                                                border: none;

                                                                .panel-heading {
                                                                    margin: 0;
                                                                    padding: 0;
                                                                }
                                                                .panel-body {
                                                                    padding: 2.7rem 0 2.5rem;
                                                                }
                                                            }
                                                        }
                                                        > .panel-heading {
                                                            padding-inline-start: 35px;
                                                            padding-inline-end: 35px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            #rolereview {
                .part-b-document-tab {
                    .formio-component-documentsTabTable {
                        .formio-component-datagrid {
                            td.review-documents-container {
                                padding: 0px !important;
                
                                .card {
                                    background-color: transparent;
                                }
                            }
                            tr + tr {
                                .formio-component-panel.review-documents-container {
                                    margin-top: @componentSeparation !important;
                                }
                            }
                        }
                    }
                }
            }
            :is(.review-document-item, .review-certificate-item) {
                border: solid 1px var(--color-gray-200);
                border-radius: 8px;
                padding: 16px;
                
                [ref="html"] {
                    display: flex;
                    flex-wrap: wrap;
                    column-gap: 8px;
                    row-gap: 12px;
                }
                .document-file-container {
                    flex: 0 0 100%;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 12px;
                }
                .document-file-link {
                    margin-bottom: 0px !important;

                    &:hover {
                        .document-file-image {
                            outline: solid 3px var(--color-blue-200);
                        }
                    }
                }
                .document-file-image {
                    max-width: 96px;
                    width: 96px;
                    height: 76px;
                    max-height: 76px;
                    object-fit: cover;
                    object-position: top center;
                    border-radius: 4px;
                }
                .document-number {
                    color: var(--color-gray-900);
                    font-size: 14px;
                    font-weight: 600;
                }
                .document-name {
                    font-weight: 600;
                    flex: 1;
                }
                &:hover {
                    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
                }
                .datetime {
                    font-size: 13px;
                    font-weight: 500;
                    color: var(--color-gray-500);
                }
            }
            .review-certificate-item {
                a i.fa-file {
                    background-image: url(../../../images/file-pdf.png);
                    margin: 16px;
                    width: 126px;
                    height: 100px;
                    background-size: cover;

                    &::before {
                        display: none;
                    }
                }
            }
            :is([ref="datagrid-documentsTabCertificates-tbody"], [ref="datagrid-documentsTabDocuments-tbody"]) {
                :is(.review-documents-container, .review-certificates-container) {
                    .alert-danger {
                        p {
                            --custom-text-color: @errorColor;
                        }
                        span {
                            color: inherit !important;
                        }
                    }
                    .alert:not(.formio-hidden) ~ :is(.review-document-item, .review-certificate-item) {
                        margin-top: 1rem !important;
                    }
                }
                > tr + tr {
                    :is(.review-documents-container, .review-certificates-container) {
                        margin-top: @componentSeparation !important;
                    }
                }
            }
            .history-status-cell {
                white-space: nowrap;
                text-overflow: ellipsis;
                transition-duration: 0.3s;
                line-height: 1;
                height: 26px;
                max-width: 100%;
                --custom-bg-color: @darkTextColor5;
                --custom-text-color: @darkTextColor;

                span {
                    &:empty {
                        display: none;
                    }
                    &.status-tooltip-toggler {
                        cursor: pointer;
                        display: inline-block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 100%;
                        height: 26px;
                    }
                    &.status-tooltip {
                        position: absolute;
                        opacity: 0;
                        z-index: -99;
                        background: white !important;
                        border: solid 1px var(--color-gray-300);
                        border-radius: @roundedCorners;
                        font-size: 12px;
                        font-weight: 600;
                        inset-inline-start: 2%;
                        width: 96%;
                        text-overflow: initial;
                        transition-duration: 0.5s;
                        max-height: 51px;
                        overflow-y: auto;
                        overflow-x: hidden;
                        top: -50px;
                        padding: 5px;

                        &.open {
                            opacity: 1;
                            z-index: 99;
                            transition-duration: 0.5s;
                            overflow-y: hidden;
                            overflow-x: hidden;
                            top: 2%;
                            max-height: 100%;
                            display: flex;
                        }
                        div {
                            white-space: normal;
                            overflow-y: auto;
                            overflow-x: auto;
                            display: block;
                            line-height: 1.4;
                            word-break: break-word;
                            width: 100%;

                            ng-container {
                                display: block;
                                text-align: left;
                            }

                            &::-webkit-scrollbar {
                                width: 6px;
                                height: 6px;
                            }
                            &::-webkit-scrollbar-track {
                                background: rgba(255,255,255,0.3);
                                border-radius: 6px;
                            }
                            &::-webkit-scrollbar-thumb {
                                border-radius: 4px;
                                background-color: rgba(0,0,0,0.4);
                                width: 100%;
                            }
                        }
                        i {
                            position: absolute;
                            width: 20px;
                            height: 20px;
                            line-height: 1;
                            top: 4px;
                            inset-inline-end: 0;
                            display: block;
                            color: @errorNotice;
                            background: @whiteColor;
                            border-radius: 50%;
                            display: flex;
                            z-index: 99;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;

                            &:hover {
                                background-color: #FEEFEF;
                            }
                        }
                    }
                    &.white-text {
                        color: white;
                    }
                }
                &.status-filevalidated {
                    --custom-bg-color: @successNotice5;
                    --custom-text-color: @successNotice;
                    color: var(--custom-text-color);
                }
                &.status-filepending {
                    --custom-bg-color: @infoNotice5;
                    --custom-text-color: @infoNotice;
                    color: var(--custom-text-color);
                }
                &.status-filedecline {
                    --custom-bg-color: @warningNotice5;
                    --custom-text-color: @warningNotice;
                    color: var(--custom-text-color);
                }
                &.status-filereject {
                    --custom-bg-color: @errorNotice5;
                    --custom-text-color: @errorNotice;
                    color: var(--custom-text-color);
                }
                &.status-cancelled {
                    --custom-bg-color: @errorNotice5;
                    --custom-text-color: @errorNotice;
                    color: var(--custom-text-color);
                }
            }
            .file-rejection-attention {
                margin-inline-start: 22px;
                line-height: 27px;
                color: @warningNew;
                
                i {
                    margin-inline-end: 4px;
                }
                &:nth-last-child(2) {
                    margin-inline-end: auto;
                    margin-inline-start: 8px;
                }
                & + .file-rejection-attention {
                    i {
                        display: none;
                    }
                    &::before {
                        content: " ";
                        width: 1px;
                        height: 10px;
                        display: inline-block;
                        background-color: var(--color-gray-500);
                        margin-inline-end: 5px;
                    }
                }
            }
        }
    }
}