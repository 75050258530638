/* Background colors */
.white-bg {
    background-color: #ffffff;
}
.no-hover-bg {
    background: transparent;
}
.gray-bg {
    background-color: #FEFEFE;
}
/* Margins & paddings */
.mb-2 {
    margin-bottom: 2rem;
}
.mt-2 {
    margin-top: 2rem;
}
.mt-4 {
    margin-top: 4rem;
}
.mt-5 {
    margin-top: 5rem;
}
.mt-1-5 {
    margin-top: 1.5rem;;
}
.mr-1 {
    margin-inline-end: 1rem;
}
.ml-auto {
    margin-inline-start: auto !important;
}
.mr-auto {
    margin-inline-end: auto;
}
.p2 {
    padding: 2rem !important;
}
/* Text */
.strong {
    font-weight: 700;
}
.dark-text, .dark-text-hover:hover, .dark-text-hover:hover span, .dark-text-hover:hover i {
    color: #3f4451 !important;
}
.upper-text {
    text-transform: uppercase !important;
}
.no-underline, .no-underline:hover {
    text-decoration: none;
}
.text-1-2 {
    font-size: 1.2rem;
}
.text-center {
    text-align: center;
}
.text-normal {
    font-weight: 400;
}
.text-left {
    text-align: start;
}
/* Helpers */
.d-flex {
    display: flex;
}
.white-space-nowrap {
    white-space: nowrap;
}
.list-group-item div.row {
    align-items: center;
}
.bottom-sticky {
    position: sticky;
    bottom: 0;
}
.back-link {
    display: inline-flex;
    align-items: center;
    width: 100px;
    transition-duration: 0.2s;

    &:hover {
        text-decoration: none;
        
        .back-link-icon, .back-link-text {
            color: #3C8FB9;
        }
        .back-link-icon {
            margin-inline-end: 16px;
        }
    }

    .back-link-icon {
        margin-inline-end: 11px;
        font-size: 11px;
        transition-duration: 0.2s;
    }

    .back-link-text {
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 1px;
        font-size: 12px;
        transition-duration: 0.2s;
    }
}
.full-width {
    width: 100%;
}
.flex-align-center {
    align-items: center;
}
.flex-auto {
    flex: auto;
}
.w-30 {
    width: 30%;
}
/* Confirm modal */
.confirmation-modal {
    position: fixed;
    width: 100%;
    height: 0;
    inset-inline-start: 0;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0);
    transition-duration: 0.1s;
    inset-inline-end: 0;
    top: -100px;
    z-index: 9999;
    display: flex;
    align-items: center;

    .confirmation-modal-box {
        transition-duration: 0.3s;
        transition-delay: 0.1s;
        width: 100%;
        max-width: 320px;
        margin: 0 auto;
        padding: 2rem;
        max-height: 500px;
        overflow-y: auto;
        background-color: #FFFFFF;
        border-radius: 2px;
        box-shadow: 0 2px 3px rgba(50, 50, 50, 0.1);
        border-top: solid 4px #f45b53;

        .confirmation-modal-heading {
            text-transform: uppercase;
            margin: 0;
            font-size: 15px;
        }

        .confirmation-modal-body {
            margin-top: 2rem;
            font-weight: normal;

            .confirmation-modal-text {
                line-height: 1.5;
                font-size: 15px;
            }

            .confirmation-modal-change-detail-container {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 10px;

                .orig-name {
                    border-inline-end: dotted 1px #AFAFAF;
                }

                h6 {
                    margin-top: 0;
                    margin-bottom: 8px;
                    font-weight: normal;
                    color: #999;
                }

                .name-value {
                    font-size: 15px;
                }
            }
        }

        .confirmation-modal-buttons {
            padding: 1rem 0 0;

            .confirmation-modal-btn {
                color: #999999;
                display: inline-block;
                font-size: 14px !important;
                cursor: pointer;

                &.primary {
                    color: #3C8FB9;
                    margin-inline-end: 12px;
                }
            }
        }
    }

    &.show-modal {
        top: 0;
        height: 100%;
    }
}
.overlay-simple {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
}

.formio-select-autocomplete-input {
    display: none;
}

#carouselRevision .hide-validation {
    display: none;
}

ul.pagination {
    li:first-child {
        margin-left: 1em;
    }

    li:last-child div:nth-of-type(2) {
        font-weight: bold;
        margin-left: 0.5em;
        margin-right: 1em;
    }
}