:root {
  --service-bar-height: 64px;
  --menu-bar-bg-color: var(--color-white);
  --menu-bar-text-color: var(--color-gray-800);
  --menu-bar-text-weight: 600;
  --menu-bar-text-size: 18px;
  --menu-bar-be-text-color: var(--color-gray-500);
  --menu-bar-be-bg-color: var(--color-gray-50);
}
body {
  &.modern-theme {
    :is(.modern-theme-dashboard-menu, .demo-page-menu, .affix-menu-size-container) {
      background-color: var(--menu-bar-bg-color);
      border-block: solid 1px var(--color-gray-200);
    }
    .demo-page-menu {
      margin-inline: -2.4rem;
    }
    .affix-menu-size-container {
      min-height: auto;
      height: auto;
    }
    .current-service-text {
      color: var(--menu-bar-text-color);
      font-size: var(--menu-bar-text-size);
      font-weight: var(--menu-bar-text-weight);
      min-width: 100px;
      height: 100%;
      display: inline-flex;
      padding-inline-end: 1.5rem;
      
      @media screen and (max-width: 480px) {
        line-height: 1;
        font-size: 12px;
      }
    }
    .home-button {
      color: inherit;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 9px;
      margin-block: 0;
      margin-inline-start: -9px;
      margin-inline-end: 6px;

      &:is(:hover, :active) {
        background-color: var(--color-gray-100);
        color: var(--color-gray-800);
        text-decoration: none;
      }
    }
    .be-title {
      font-size: 70%;
      color: var(--menu-bar-be-text-color);
      background-color: var(--menu-bar-be-bg-color);
      padding: 0 10px;
      margin-inline-start: 6px;
      border-radius: @roundedCorners;
    }
    #base {
      #content {
        .affix-menu-size-container {
          .user-steps-menu {
            background-color: transparent;

            .user-steps-menu-wrapper {
              position: relative;

              .current-service-container {
                height: var(--service-bar-height);
              }
            }
          }
        }
      }
    }
    /* New Formio */
    &.formio-new {
      #base {
        #content {
          .demo-page-menu {
            .contain-lg {
              .current-service-container {
                .current-service-text {
                  .demo-heading-title {
                    display: none;
                  }
                }
              }
            }
          }
          .affix-menu-size-container {
            &.demo-modern {
              display: none;
            }
            &.affix {
              & ~ .section-body {
                .contain-lg {
                  #formio-form-wizard {
                    > .alert {
                      &.alert-danger {
                        top: 76px;

                        ul {
                          max-height: ~"min(calc(100vh - 120px), 320px)";
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    ul {
      padding-inline-start: 0;
    }
    .current-service-container {
      display: flex;
      justify-content: space-between;
      height: var(--service-bar-height);

      .current-service-text {
        .fa-chevron-right {
          font-size: 10px;
          margin-inline-end: 12px;
        }
        span {
          font-size: var(--menu-bar-page-name-size);
        }
      }
      .service-bar-user-lang-menu {
        margin-inline-start: auto;
        margin-inline-end: -1.5rem;

        .headerbar-right {
          height: var(--service-bar-height);
          flex-direction: row-reverse;

          ul {
            &#language_selector_container {
              > li {
                &.dropdown-user {
                  a {
                    &.dropdown-toggle {
                      padding: 0 1.5rem;
                      height: var(--service-bar-height);
                      display: flex;
                      align-items: center;
                      gap: 6px;
                      line-height: 1;

                      i {
                        font-size: 16px;
                        margin-inline-start: 0.8rem;
                      }
                    }
                  }
                  &.open {
                    ul {
                      &.dropdown-menu {
                        padding: 0;
                        inset-inline-end: 0;
                        inset-inline-start: initial;
                        border-radius: 0 @roundedCorners @roundedCorners @roundedCorners;
                        min-width: 100%;

                        li {
                          &:first-child {
                            a {
                              border-radius: 0 @roundedCorners 0 0;
                            }
                          }
                          &:last-child {
                            a {
                              border-radius: 0 0 @roundedCorners @roundedCorners;
                            }
                          }
                        }
                      }
                    }
                  }
                  @media screen and (max-width: 768px) {
                    padding: 0;
                  }
                }
              }
            }
            &#connect {
              height: 100%;

              li {
                &.dropdown {
                  &.user {
                    height: 100%;

                    a {
                      text-transform: none;
                      font-weight: 600;

                      &.dropdown-toggle {
                        &.left-part {
                          margin: 0;
                          border: none;
                          padding-inline-start: 1.5rem;
                          padding-inline-end: 0;
                          border-radius: 0;

                          i {
                            &.user-icon {
                              display: none;
                            }
                          }
                          span {
                            &.user-name-text {
                              padding: 0;

                              .user-initials {
                                display: none;
                              }
                              @media screen and (max-width: 768px) {
                                display: block;

                                .user-initials {
                                  display: block;

                                  span {
                                    display: inline;
                                  }
                                }
                              }
                            }
                          }
                          &:nth-last-child(2) {
                            padding-inline-end: 1.5rem;
                            cursor: default;
                          }
                        }
                        &.user-dropdown-toggle {
                          border: none;
                          padding-inline-end: 1.3rem;
                          padding-inline-start: 1.3rem;
                          border-radius: 0;

                          i {
                            &.angle-icon {
                              margin-inline-end: 0;
                              font-weight: 300;
                              font-size: 16px;
                            }
                          }
                          &:last-child {
                            display: none;
                          }
                        }
                      }
                    }
                    &.open {
                      ul {
                        &.dropdown-menu {
                          width: 100%;
                          min-width: fit-content;
                          padding: 0;
                          border-radius: 0 0 @roundedCorners @roundedCorners;

                          li {
                            &.divider {
                              margin: 0;
                              background-color: @accentColor10;
                              height: 1px;

                              &:first-child {
                                display: none;
                              }
                            }
                            a {
                              padding: 7px 20px;
                            }
                            &:last-child {
                              a {
                                border-radius: 0 0 @roundedCorners @roundedCorners !important;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                &:not(.dropdown) {
                  height: 100%;

                  a {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    border-radius: 0 !important;
                    margin: 0 !important;
                    padding: 0 2rem;
                    text-transform: none;
                    font-weight: 600;
                    box-shadow: none !important;
                  }
                }
              }
            }
          }

          @media screen and (max-width: 768px) {
            min-height: var(--service-bar-height);
          }
        }
        @media screen and (max-width: 1200px) {
          margin-inline-end: 0;
        }
      }
    }
  }
}