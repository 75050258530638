/* Theme color vars */
@accentColor: rgba(215, 160, 72, 1);
@accentColor80: rgba(215, 160, 72, 0.8);
@accentColor70: rgba(215, 160, 72, 0.7);
@accentColor50: rgba(215, 160, 72, 0.5);
@accentColor40: rgba(215, 160, 72, 0.4);
@accentColor30: rgba(215, 160, 72, 0.3);
@accentColor20: rgba(215, 160, 72, 0.2);
@accentColor10: rgba(215, 160, 72, 0.1);
@accentColor075: rgba(215, 160, 72, 0.075);
@accentColor5: rgba(215, 160, 72, 0.05);
@accentColor3: rgba(215, 160, 72, 0.03);
@accentColor70Solid: #E3BC7F;
@accentColor10Solid: #FBF5EC;
@accentColor5Solid: #FDFAF6;
@accentHEX: #D7A048;

:root {
  // Colors
  // Primary
  --color-primary-50: #FDF9F4;
  --color-primary-75: #FBF6EE;
  --color-primary-100: #F8ECDB;
  --color-primary-200: #F4E1C7;
  --color-primary-300: #F0D7B0;
  --color-primary-400: #EDCB97;
  --color-primary-500: #E7B964;
  --color-primary-600: #D7A048;
  --color-primary-700: #BD8C3F;
  --color-primary-800: #A97E39;
  --color-primary-900: #926D31;
  --color-primary-1000: #775928;
  --color-primary-1100: #543F1C;
}