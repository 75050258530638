ul.nav.nav-tabs {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    background-color: #f5f5f6;
    border: none !important;
    border-radius: 0;
    min-height: 54px;

    >li {
        flex: 1;
        text-align: center;

        >a {
            outline: none;
            border: none;
            border-radius: 0;
            margin-inline-end: 0;
            &:hover {
                border: none;
                background: #f0f0f0;
            }
        }

        &.active {
            a, a:hover, a:focus {
                font-weight: 600;
                border-color: transparent;
            }
            a:hover {
                background: #ffffff;
            }
        }
    }
}

.tab-pane {
    .tabs-above {
        padding: 0 2rem;

        ul.nav.nav-tabs {
            display: block;
            background: white;
            border-bottom: 1px solid @main-bg !important;
            
            &[role="tablist"] {
                border-bottom: none !important;
            }
            li {
                height: auto;
                a {
                    line-height: 34px;
                    padding: 1rem 4rem;
                    border-bottom: 1px solid transparent;

                    &:hover,
                    &:focus,
                    &:active,
                    &.active {
                        background: transparent;
                    }
                }
                &:hover,
                &:focus,
                &:active,
                &.active {
                    a {
                        border-bottom-color: @blue;
                        background: transparent;
                    }
                }
            }
        }

        .tab-content {
            .card {
                box-shadow: none;
                border: none !important;
                background: #F5F6F8 !important;
                >.card-body {
                    margin: 0 -2rem !important;
                    padding: 0 !important;
                    background: #F5F6F8 !important;
                    &:after,
                    &:before {
                        display: none !important;
                    }

                    .form-field-type-panel {
                        .panel {
                            border-inline-start: none !important;
                        }
                    }
                }
            }
        }
    }
}


#tabs-zone {
    margin-top: 4rem;
    &.panel,
    > .panel {
        box-shadow: none;
        background-color: transparent;
        ul {
            &.nav {
                &.nav-tabs {
                    background-color: transparent;
                    > li {
                        flex: none;

                        a {
                            text-transform: none;
                            font-size: 2rem;
                            padding: 0 3rem;
                        }
                        &.hover,
                        &.active {
                            box-shadow: 0 4px 3px rgba(0, 0, 0, 0.16);
                            z-index: 99;

                            a {
                                color: #404351;
                                background-color: @basegrey;
                            }
                        }
                    }
                }
            }
        }

        > .panel-body {
            padding: 0;

            ul {
                &.nav {
                    &.nav-tabs {
                        background-color: @basegrey;
                        > li {
                            a {
                                text-transform: none;
                                font-size: 1.4rem;
                            }
                            &.active {
                                box-shadow: none;
                                a {
                                    color: #404351;
                                    box-shadow: inset 0 -2px 0 #3C8FB9 !important;
                                    border: none !important;
                                }
                            }
                        }
                    }
                }
            }
            > .tab-pane {
                &:not(#form-type-role) {
                    background: @basegrey;
                    box-shadow: 0 0 3px rgba(0, 0, 0, 0.16);
                }
            }
            .formio-component-form {
                .formio-component-panel {
                    > .card {
                        margin: 0;
                        box-shadow: 0 0 3px rgba(0, 0, 0, 0.16);
                        background: @basegrey;

                        > .card-body {
                            
                        }
                    }
                }
            }
        }
    }
}