html {
    margin-top: 0 !important;
}

body {
    background-color: @white;
    overflow-y: scroll;

    &.no-toolbar {
        #cms-top {
            display: none;
        }
    }
}

/* Bootstrap overrides */
.row {
    flex: 1;
    display: flex;
    flex-flow: row;
    align-items: stretch;
    > div {
        /*flex: 1 100%;*/

        &.col-init,
        &.col-sm-1 {
            flex: auto;
        }

        >.panel {
            height: 100%;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
            border-radius: 0;
            border: none;

            >.panel-heading {
                position: relative;
                .see-more {
                    text-transform: capitalize;
                    font-size: 12px;
                    color: fade(@blue,50%);
                    position: absolute;
                    inset-inline-end: 1.5rem;
                    top: 1.5rem;
                    cursor: pointer;
                    transition: all .3s;
                    &:hover {
                        color: fade(@blue,100%);
                        transition: all .3s;
                        text-decoration: none;
                    }
                }
            }

            >.panel-body {
                display: flex;
                flex-wrap: wrap;
                padding: 1rem 2rem;
                overflow-y: auto;

                &.panel-body--short {
                    overflow: hidden;
                }
            }
        }
    }
}

.panel {
    border: none;
    >.panel-body {
        padding: 2rem 2rem .5rem;

        &.tab-content {
            padding: 1rem 0;

            .panel {
                box-shadow: none;
            }

            hr {
                display: none;
            }
        }
    }

    >.panel-heading {
        & + .panel-body {
            padding: 1.5rem 2.5rem 1.9rem;
        }
    }
}

.page-title {
    color: @dark;
    font-size: 20px;
    font-weight: 400;
    border: none;
    position: relative;
    padding: 0.5rem 0;
}

section {
    &.section-body {
       padding: 0;

        > .contain-lg {
           position: relative;
        }
    }
}
#shoppingcart.service-cart > .regs-docs-fees-drawer {
    position: fixed;
    width: 20vw;
    min-width: 320px;
    z-index: 99999;
    box-shadow: -2px 0 12px rgba(0, 0, 0, 0.2);
    background: #f5f6f8;
    height: 100%;
    inset-inline-end: 0;
    top: 0;

    .alert {
        display: none;
    }
    form {
        &.formio-form {
            .form-group {
                &.form-field-type-mycontent {
                    margin-top: 0;
                }
            }
        }
    }
    .drawer-header {
        padding: 2rem;
        color: #555555;

        span {
            font-size: 18px;
            
            &.cart-icon {
                color: #ffffff;
                margin-inline-end: 10px;
                background: #80c49b;
                padding-block: 5px;
                padding-inline-start: 0;
                padding-inline-end: 12px;
                border-radius: 0 20px 20px 0;
                box-shadow: -15px 0 #80c49b;
                margin-inline-start: -5px;
            }
        }
        i {
            width: 30px;
            height: 30px;
            align-items: center;
            justify-content: center;
            display: flex;
            cursor: pointer;
            margin-inline-end: -8px;
            border-radius: 20px;

            &:hover {
                background: #e1e4eb;
                color: #f44336;
            }
        }
    }
    .drawer-section-header {
        width: 100%;
        border: none;
        background: no-repeat;
        background-image: none;
        padding: 1rem 2rem;
        color: #555555;
        font-size: 16px;
        font-weight: 600;

        i {
            border: solid 1px #AAA;
            color: #AAA;
            border-radius: 20px;
            width: 16px;
            height: 16px;
            font-size: 14px;
        }
        &:hover {
            color: black;

            i {
                color: black;
                border-color: black;
            }
        }
        &:focus {
            outline: none;
        }
        &:not(.opened) {
            i::before {
                content: "\F107";
            }
        }
        &.opened + .drawer-section-content {
            /*display: block;*/
        }
    }
    .drawer-section-content {
        background: white;
        padding: 1rem 2rem;
        display: none;
        color: #555555;

        &#guidemenuregs > div > div label {
            cursor: pointer;

            input {
                position: relative;
                top: 2px;
                width: 14px;
                height: 14px;
                margin-inline-end: 6px;
                display: inline-block;
            }
        }
    }
}

.financial-report {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
    background-color: white;

    .big-title {
        font-size: 22px;
        font-weight: 400;
    }

    .export-format-buttons {
        align-items: center;

        a {
            background: #f7f7f8;
            padding: 0.5rem 1.5rem;
            border-radius: 2px;
            margin-inline-start: 8px;
            text-decoration: none;
            color: #222222;

            &:first-child {
                margin-inline-start: 0;
            }
            &:hover,
            &:focus {
                box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
                background-color: #efeff3;
            }
            &:active {
                box-shadow: inset 0 1px 2px 0 rgba(34, 36, 38, 0.15);
                background-color: #efeff3;
            }
            &.red-icon i {
                color: #D56D72;
            }
            &.green-icon i {
                color: #7BC39B;
            }
            i {
                margin-inline-end: 8px;
            }
        }
    }
}
.gray-bg-no-print {
    background-color: #f0f2f4;
}

.alpha-50 {
    opacity: .5;
}
.align-items-center {
    align-items: center;
}
.pointer {
    cursor: pointer;
}

#content {
    >.affix-menu-size-container {
        z-index: 2;
    }
    >.section-body {
        z-index: 1;
    }
}