@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-ms-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes zoom-down {
  from {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-moz-keyframes zoom-down {
  from {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-o-keyframes zoom-down {
  from {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-ms-keyframes zoom-down {
  from {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@keyframes zoom-down {
  from {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-webkit-keyframes zoom-up {
  from {
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-moz-keyframes zoom-up {
  from {
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-o-keyframes zoom-up {
  from {
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-ms-keyframes zoom-up {
  from {
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@keyframes zoom-up {
  from {
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-webkit-keyframes slide-right {
  from {
    -webkit-transform: translate(-20px, 0);
    -ms-transform: translate(-20px, 0);
    -o-transform: translate(-20px, 0);
    transform: translate(-20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-moz-keyframes slide-right {
  from {
    -webkit-transform: translate(-20px, 0);
    -ms-transform: translate(-20px, 0);
    -o-transform: translate(-20px, 0);
    transform: translate(-20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-o-keyframes slide-right {
  from {
    -webkit-transform: translate(-20px, 0);
    -ms-transform: translate(-20px, 0);
    -o-transform: translate(-20px, 0);
    transform: translate(-20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-ms-keyframes slide-right {
  from {
    -webkit-transform: translate(-20px, 0);
    -ms-transform: translate(-20px, 0);
    -o-transform: translate(-20px, 0);
    transform: translate(-20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes slide-right {
  from {
    -webkit-transform: translate(-20px, 0);
    -ms-transform: translate(-20px, 0);
    -o-transform: translate(-20px, 0);
    transform: translate(-20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes slide-left {
  from {
    -webkit-transform: translate(20px, 0);
    -ms-transform: translate(20px, 0);
    -o-transform: translate(20px, 0);
    transform: translate(20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-moz-keyframes slide-left {
  from {
    -webkit-transform: translate(20px, 0);
    -ms-transform: translate(20px, 0);
    -o-transform: translate(20px, 0);
    transform: translate(20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-o-keyframes slide-left {
  from {
    -webkit-transform: translate(20px, 0);
    -ms-transform: translate(20px, 0);
    -o-transform: translate(20px, 0);
    transform: translate(20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-ms-keyframes slide-left {
  from {
    -webkit-transform: translate(20px, 0);
    -ms-transform: translate(20px, 0);
    -o-transform: translate(20px, 0);
    transform: translate(20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes slide-left {
  from {
    -webkit-transform: translate(20px, 0);
    -ms-transform: translate(20px, 0);
    -o-transform: translate(20px, 0);
    transform: translate(20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes slide-down {
  from {
    -webkit-transform: translate(0, -20px);
    -ms-transform: translate(0, -20px);
    -o-transform: translate(0, -20px);
    transform: translate(0, -20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-moz-keyframes slide-down {
  from {
    -webkit-transform: translate(0, -20px);
    -ms-transform: translate(0, -20px);
    -o-transform: translate(0, -20px);
    transform: translate(0, -20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-o-keyframes slide-down {
  from {
    -webkit-transform: translate(0, -20px);
    -ms-transform: translate(0, -20px);
    -o-transform: translate(0, -20px);
    transform: translate(0, -20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-ms-keyframes slide-down {
  from {
    -webkit-transform: translate(0, -20px);
    -ms-transform: translate(0, -20px);
    -o-transform: translate(0, -20px);
    transform: translate(0, -20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes slide-down {
  from {
    -webkit-transform: translate(0, -20px);
    -ms-transform: translate(0, -20px);
    -o-transform: translate(0, -20px);
    transform: translate(0, -20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes slide-up {
  from {
    -webkit-transform: translate(0, 20px);
    -ms-transform: translate(0, 20px);
    -o-transform: translate(0, 20px);
    transform: translate(0, 20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-moz-keyframes slide-up {
  from {
    -webkit-transform: translate(0, 20px);
    -ms-transform: translate(0, 20px);
    -o-transform: translate(0, 20px);
    transform: translate(0, 20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-o-keyframes slide-up {
  from {
    -webkit-transform: translate(0, 20px);
    -ms-transform: translate(0, 20px);
    -o-transform: translate(0, 20px);
    transform: translate(0, 20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-ms-keyframes slide-up {
  from {
    -webkit-transform: translate(0, 20px);
    -ms-transform: translate(0, 20px);
    -o-transform: translate(0, 20px);
    transform: translate(0, 20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes slide-up {
  from {
    -webkit-transform: translate(0, 20px);
    -ms-transform: translate(0, 20px);
    -o-transform: translate(0, 20px);
    transform: translate(0, 20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
/*
// Example usage

.make-ratio(xs, 2, 1);
.make-ratio(xs, 3, 1);


// Small grid
//
// Small device range, from phones to tablets.

@media (min-width: @screen-sm-min) {
  .make-ratio(sm, 2, 1);
  .make-ratio(sm, 3, 1);
}


// Medium grid
//
// For the desktop device range.

@media (min-width: @screen-md-min) {
  .make-ratio(md, 2, 1);
  .make-ratio(md, 3, 1);
}


// Large grid
//
// For the large desktop device range.

@media (min-width: @screen-lg-min) {
  .make-ratio(lg, 2, 1);
  .make-ratio(lg, 3, 1);
}
*/
html,
body,
.full-height {
  height: 100%;
}
body {
  background: #e5e6e6;
  background-size: 100% 100%;
  color: #313335;
  -ms-overflow-style: scrollbar;
}
.container {
  padding: 0;
}
a {
  color: inherit;
  text-decoration: none;
}
a:hover,
a:focus {
  color: inherit;
}
hr {
  border-top-color: rgba(12, 12, 12, 0.12);
}
hr.ruler-lg {
  margin: 8px 0;
}
hr.ruler-xl {
  margin: 24px 0;
}
hr.ruler-xxl {
  margin: 56px 0;
}
.img-backdrop {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.img-backdrop img {
  display: block;
  min-width: 100%;
  min-height: 100%;
  height: auto;
}
.img-rounded {
  border-radius: 4px;
}
.doc-section {
  margin-bottom: 80px;
}
.doc-section.doc-lead {
  margin-bottom: 20px;
}
.doc-section h3 {
  margin-top: 20px;
}
.doc-section h4 {
  margin-top: 17px;
}
.doc-section h5 {
  margin-top: 15px;
}
.fuelphp-dump {
  position: fixed;
  z-index: 999;
  right: 0;
  bottom: 0;
}
.hbox-sm,
.hbox-md,
.hbox-lg {
  padding: 0;
}
.hbox-sm .hbox-column,
.hbox-md .hbox-column,
.hbox-lg .hbox-column {
  display: block;
  vertical-align: top;
}
.hbox-sm .hbox-column.v-top,
.hbox-md .hbox-column.v-top,
.hbox-lg .hbox-column.v-top {
  vertical-align: top;
}
.hbox-sm .hbox-column.v-middle,
.hbox-md .hbox-column.v-middle,
.hbox-lg .hbox-column.v-middle {
  vertical-align: middle;
}
.hbox-sm .hbox-column.v-bottom,
.hbox-md .hbox-column.v-bottom,
.hbox-lg .hbox-column.v-bottom {
  vertical-align: bottom;
}
.hbox-xs {
  display: table;
  table-layout: fixed;
  padding: 0;
}
.hbox-xs .hbox-column {
  display: table-cell;
}
@media (min-width: 769px) {
  .hbox-sm {
    display: table;
    table-layout: fixed;
  }
  .hbox-sm .hbox-column {
    float: none;
    display: table-cell;
  }
}
@media (min-width: 992px) {
  .hbox-md {
    float: none;
    display: table;
    table-layout: fixed;
  }
  .hbox-md .hbox-column {
    float: none;
    display: table-cell;
  }
}
@media (min-width: 1200px) {
  .hbox-lg {
    display: table;
    table-layout: fixed;
  }
  .hbox-lg .hbox-column {
    float: none;
    display: table-cell;
  }
}
.v-top,
.v-inline-top {
  float: none!important;
  display: table-cell;
  vertical-align: top;
}
.v-middle,
.v-inline-middle {
  float: none!important;
  display: table-cell;
  vertical-align: middle;
}
.v-bottom,
.v-inline-bottom {
  float: none!important;
  display: table-cell;
  vertical-align: bottom;
}
.v-inline-top,
.v-inline-middle,
.v-inline-bottom {
  display: inline-block;
}
.card-tiles .hbox-column {
  padding: 20px;
}
.text-light {
  font-weight: 300;
}
.text-normal {
  font-weight: 400;
}
.text-medium,
strong {
  font-weight: 500;
}
.text-bold {
  font-weight: 700;
}
.text-ultra-bold {
  font-weight: 900;
}
h1 > strong,
h2 > strong,
h3 > strong,
h4 > strong,
h5 > strong,
h6 > strong {
  font-weight: 700;
}
h3,
h4,
h5,
h6 {
  font-weight: 500;
}
.text-display-lines {
  line-height: 1.1;
}
.text-display-lines span,
.text-display-lines p,
.text-display-lines h1,
.text-display-lines h2,
.text-display-lines h3,
.text-display-lines h4,
.text-display-lines h5,
.text-display-lines h6 {
  position: relative;
}
.text-display-lines span:before,
.text-display-lines p:before,
.text-display-lines h1:before,
.text-display-lines h2:before,
.text-display-lines h3:before,
.text-display-lines h4:before,
.text-display-lines h5:before,
.text-display-lines h6:before,
.text-display-lines span:after,
.text-display-lines p:after,
.text-display-lines h1:after,
.text-display-lines h2:after,
.text-display-lines h3:after,
.text-display-lines h4:after,
.text-display-lines h5:after,
.text-display-lines h6:after {
  content: '';
  position: absolute;
  display: block;
  height: 1px;
  width: 100%;
  background: #ff00cc;
}
.text-display-lines span:before,
.text-display-lines p:before,
.text-display-lines h1:before,
.text-display-lines h2:before,
.text-display-lines h3:before,
.text-display-lines h4:before,
.text-display-lines h5:before,
.text-display-lines h6:before {
  top: 0;
}
.text-display-lines span:after,
.text-display-lines p:after,
.text-display-lines h1:after,
.text-display-lines h2:after,
.text-display-lines h3:after,
.text-display-lines h4:after,
.text-display-lines h5:after,
.text-display-lines h6:after {
  bottom: 0;
}
.text-xs {
  font-size: 75%;
}
.text-sm {
  font-size: 85%;
}
.text-lg {
  font-size: 125%;
}
.text-xl {
  font-size: 180%;
}
.text-xxl {
  font-size: 225%;
}
.text-xxxl {
  font-size: 330%;
}
.text-xxxxl {
  font-size: 500%;
}
p {
  letter-spacing: 0.1px;
}
.text-columns-2 {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 24px;
  -moz-column-gap: 24px;
  column-gap: 24px;
}
.text-columns-3 {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-gap: 24px;
  -moz-column-gap: 24px;
  column-gap: 24px;
}
.text-columns-4 {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
  -webkit-column-gap: 24px;
  -moz-column-gap: 24px;
  column-gap: 24px;
}
.text-columns-sm {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
  -webkit-column-gap: 24px;
  -moz-column-gap: 24px;
  column-gap: 24px;
}
.text-columns {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-gap: 24px;
  -moz-column-gap: 24px;
  column-gap: 24px;
}
.text-columns-lg {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 24px;
  -moz-column-gap: 24px;
  column-gap: 24px;
}
@media (max-width: 992px) {
  .text-columns-sm {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 24px;
    -moz-column-gap: 24px;
    column-gap: 24px;
  }
  .text-columns {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 24px;
    -moz-column-gap: 24px;
    column-gap: 24px;
  }
  .text-columns-lg {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 24px;
    -moz-column-gap: 24px;
    column-gap: 24px;
  }
}
@media (max-width: 769px) {
  .text-columns-sm {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 24px;
    -moz-column-gap: 24px;
    column-gap: 24px;
  }
  .text-columns {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 24px;
    -moz-column-gap: 24px;
    column-gap: 24px;
  }
  .text-columns-lg {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 24px;
    -moz-column-gap: 24px;
    column-gap: 24px;
  }
}
@media (max-width: 480px) {
  .text-columns-sm {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 24px;
    -moz-column-gap: 24px;
    column-gap: 24px;
  }
  .text-columns {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 24px;
    -moz-column-gap: 24px;
    column-gap: 24px;
  }
  .text-columns-lg {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 24px;
    -moz-column-gap: 24px;
    column-gap: 24px;
  }
}
.line-height-normal {
  line-height: 1.84615385;
}
.line-height-lg {
  line-height: 2.14615385;
}
.line-height-xl {
  line-height: 2.39615385;
}
.line-height-xxl {
  line-height: 2.74615385;
}
.text-caption {
  display: block;
  margin-bottom: 29px;
  font-size: 12px;
}
.card ~ .text-caption,
.panel-group ~ .text-caption {
  margin-top: -15px;
}
.no-linebreak {
  white-space: nowrap;
}
.text-shadow {
  text-shadow: 0 1px 2px #000000;
}
blockquote small {
  color: inherit;
  opacity: .7;
}
article img.pull-right,
p img.pull-right {
  margin-left: 10px;
  margin-bottom: 2px;
}
article img.pull-left,
p img.pull-left {
  margin-right: 10px;
  margin-bottom: 2px;
}
ul,
ol {
  padding-left: 16px;
}
ul.list-divided li,
ol.list-divided li {
  margin-top: 16px;
}
ul.list-divided li:first-child,
ol.list-divided li:first-child {
  margin-top: 0;
}
ol {
  list-style-position: inside;
  padding-left: 0;
}
.page-header {
  border-bottom-color: rgba(0, 0, 0, 0.3);
}
.page-header.no-border {
  border: none;
  margin-bottom: 10px;
  padding-bottom: 0;
}
.text-divider {
  display: table;
  width: 100%;
  margin: 13px 0 20px 0;
  opacity: 0.5;
}
.text-divider span {
  display: table-cell;
  padding: 0 10px;
  position: relative;
  bottom: -7px;
  white-space: nowrap;
}
.text-divider:before,
.text-divider:after {
  content: '';
  width: 50%;
  display: table-cell;
  border-bottom: 1px solid rgba(12, 12, 12, 0.3);
}
.dl-horizontal.dl-icon dd {
  margin-bottom: 5px;
}
@media (min-width: 769px) {
  .dl-horizontal.dl-icon dt {
    width: 30px;
    text-align: left;
    padding-top: 0;
  }
  .dl-horizontal.dl-icon dd {
    margin-left: 54px;
  }
}
.dl-horizontal dd.full-width {
  margin-left: 0;
}
.text-default-bright {
  color: #ffffff;
}
a.text-default-bright:hover {
  color: #e6e6e6;
}
.text-default-light {
  color: #96999c;
}
a.text-default-light:hover {
  color: #7c8083;
}
.text-default {
  color: #313335;
}
a.text-default:hover {
  color: #191a1a;
}
.text-default-dark {
  color: #2B2A65;
}
a.text-default-dark:hover {
  color: #051e33;
}
.text-primary-bright {
  color: #c7dbec;
}
a.text-primary-bright:hover {
  color: #a0c2e0;
}
.text-primary-light {
  color: #78a6ce;
}
a.text-primary-light:hover {
  color: #528dc1;
}
.text-primary {
  color: #2267a2;
}
a.text-primary:hover {
  color: #194c78;
}
.text-primary-dark {
  color: #164271;
}
a.text-primary-dark:hover {
  color: #0d2946;
}
.text-accent-bright {
  color: #f0f4c3;
}
a.text-accent-bright:hover {
  color: #e5ec98;
}
.text-accent-light {
  color: #81d4fa;
}
a.text-accent-light:hover {
  color: #50c3f8;
}
.text-accent {
  color: #14BF6E;
}
a.text-accent:hover {
  color: #31b0d5;
}
.text-accent-dark {
  color: #1a237e;
}
a.text-accent-dark:hover {
  color: #111754;
}
.text-info {
  color: #2196f3;
}
a.text-info:hover {
  color: #0c7cd5;
}
.text-warning {
  color: #ff9800;
}
a.text-warning:hover {
  color: #cc7a00;
}
.text-danger {
  color: #f44336;
}
a.text-danger:hover {
  color: #ea1c0d;
}
.text-success {
  color: #4caf50;
}
a.text-success:hover {
  color: #3d8b40;
}
#base {
  overflow: hidden;
  padding-left: 64px;
  width: 100%;
  min-height: 100%;
}
#base > .backdrop {
  position: fixed;
  z-index: 1031;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: block;
  background-color: rgba(12, 12, 12, 0.33);
}
#base.hide-backdrop > .backdrop {
  display: none;
  position: relative;
}
#content {
  position: relative;
  width: 100%;
  left: 0;
  padding-top: 64px;
}
@media (min-width: 1200px) {
  .menubar-pin #base {
    padding-left: 240px;
  }
}
@media (min-width: 769px) {
  .full-content #base,
  .full-content #content {
    height: 100%;
  }
}
@media (max-width: 768px) {
  #base {
    padding-left: 0;
    padding-right: 0;
  }
}
#header {
  position: absolute;
  left: 0;
  right: 0;
  height: 64px;
  z-index: 1005;
  background: #ffffff;
  color: rgba(49, 51, 53, 0.6);
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
}
.header-fixed #header {
  position: fixed;
}
.no-sidebar #header {
  right: 12px;
}
.headerbar {
  position: relative;
  min-height: 64px;
}
.headerbar:before,
.headerbar:after {
  content: " ";
  display: table;
}
.headerbar:after {
  clear: both;
}
.headerbar .navbar-devider {
  display: block;
  width: 20px;
  height: 5px;
}
.headerbar-left {
  position: absolute;
}
.headerbar-left a {
  float: left;
}
.headerbar-left .header-nav {
  margin-left: 24px;
}
.headerbar-right {
  float: right;
}
.headerbar-right .header-nav {
  display: inline-block;
  float: left;
}
.headerbar-right .dropdown-menu {
  left: auto;
  right: 0;
}
.headerbar-right .header-nav-toggle {
  margin-left: 0;
}
.header-nav {
  margin: 0 12px;
  padding-left: 0;
  list-style: none;
}
.header-nav > li {
  position: relative;
  display: block;
  padding: 14px 0 14px 12px;
  float: left;
}
.header-nav > li:first-child {
  padding-left: 0;
}
.header-nav > li > a {
  position: relative;
  display: block;
}
.header-nav > li > a:hover,
.header-nav > li > a:focus {
  text-decoration: none;
  background-color: rgba(12, 12, 12, 0.07);
}
.header-nav > li.disabled > a {
  color: #96999c;
}
.header-nav > li.disabled > a:hover,
.header-nav > li.disabled > a:focus {
  color: #96999c;
  text-decoration: none;
  background-color: transparent;
  cursor: not-allowed;
}
.header-nav .open > a,
.header-nav .open > a:hover,
.header-nav .open > a:focus {
  background-color: none;
  border-color: #2267a2;
}
.header-nav > li > a > img {
  max-width: none;
}
.header-nav .btn-icon-toggle:hover {
  background-color: rgba(12, 12, 12, 0.07);
}
.header-nav .navbar-search {
  float: right;
}
.header-nav .header-nav-brand {
  display: inline-table;
  padding: 0;
  height: 64px;
}
.header-nav .header-nav-brand .brand-holder {
  display: table-cell;
  vertical-align: middle;
}
.header-nav .header-nav-brand a {
  text-decoration: none;
}
.header-nav .header-nav-brand img {
  max-height: 48px;
}
.header-nav-profile .dropdown {
  padding: 0;
}
.header-nav-profile .dropdown > a {
  position: relative;
  min-height: 64px;
  min-width: 210px;
  padding: 12px;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}
.header-nav-profile .dropdown > a:after {
  content: "\f107";
  position: absolute;
  *margin-right: .3em;
  right: 10px;
  top: 22px;
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
}
.header-nav-profile .dropdown > a .ink {
  background-color: rgba(83, 86, 88, 0.15);
}
.header-nav-profile .dropdown img {
  width: 40px;
  height: 40px;
  border-radius: 40px;
}
.header-nav-profile .dropdown .profile-info {
  display: inline-block;
  vertical-align: middle;
  padding: 0 20px 0 10px;
  line-height: 15px;
}
.header-nav-profile .dropdown .profile-info small {
  display: block;
  opacity: 0.5;
}
.header-nav-profile .dropdown .dropdown-menu {
  min-width: 100%;
}
.header-nav-profile .dropdown.open {
  color: #313335;
}
.header-nav-profile .dropdown.open > a {
  margin-top: 6px;
  padding: 6px 12px 12px 12px;
  min-height: 58px;
  background-color: #ffffff;
  border-color: rgba(255, 255, 255, 0.9);
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
}
.header-nav-profile .dropdown.open > a:after {
  top: 16px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.header-nav-profile .dropdown.open .dropdown-menu {
  margin-top: -1px;
  border-top: none;
}
.header-nav-options .dropdown > a .badge {
  position: absolute;
  font-size: 10px;
  top: -1px;
  right: 4px;
}
.header-nav-options .dropdown .dropdown-menu {
  top: 14px;
}
@media (min-width: 1200px) {
  .menubar-first.menubar-pin .header-nav .header-nav-brand {
    display: none;
  }
  .menubar-first.menubar-pin #header {
    left: 240px;
  }
}
@media (min-width: 769px) {
  .menubar-first #header .menubar-toggle {
    display: none;
  }
  .menubar-first #header {
    left: 64px;
  }
}
@media (max-width: 768px) {
  #header {
    position: relative;
  }
  .header-fixed #header {
    position: relative;
  }
  .header-fixed #header:before {
    content: '';
    z-index: 0;
    position: fixed;
    top: 0;
    display: block;
    width: 100%;
    height: 64px;
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
  }
  .headerbar-left {
    z-index: 1011;
    left: 0;
    right: 0;
    height: 64px;
    background: #ffffff;
  }
  .header-fixed .headerbar-left {
    position: fixed;
  }
  .headerbar-right {
    position: absolute;
    left: 0;
    right: 0;
    min-height: 64px;
    top: 64px;
    float: none !important;
    background: #ffffff;
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
  }
  .headerbar-right .header-nav-options {
    margin-right: 12px;
    float: right;
  }
  .headerbar-right .header-nav-profile {
    position: absolute;
    float: left;
  }
  .headerbar-right .header-nav-toggle {
    position: absolute;
    z-index: 1012;
    top: -64px;
    right: 0;
  }
  .header-fixed .headerbar-right .header-nav-toggle {
    position: fixed;
    top: 0;
  }
  .headerbar-left .header-nav {
    margin-left: 12px;
  }
  .header-nav-profile .dropdown img {
    width: 0px;
    opacity: 0;
  }
  .header-nav-profile .dropdown .profile-info {
    padding-left: 0;
    padding-right: 30px;
  }
  .navbar-search {
    position: relative;
    z-index: 1;
  }
  .navbar-search.expanded .form-group {
    max-width: 100%;
    width: 480px;
  }
}
#header.header-inverse {
  background: #242a31;
  color: rgba(255, 255, 255, 0.6);
}
.header-inverse .header-nav .btn-icon-toggle:hover {
  background-color: rgba(255, 255, 255, 0.15);
}
@media (max-width: 768px) {
  .header-inverse .headerbar-left {
    background: #242a31;
    border-bottom-color: rgba(255, 255, 255, 0.15);
  }
  .header-inverse .headerbar-right {
    background: #242a31;
  }
}
#menubar {
  position: fixed;
  z-index: 1004;
  left: 0;
  top: 64px;
  bottom: 0;
  width: 64px;
  color: rgba(12, 12, 12, 0.85);
}
.menubar-first #menubar {
  z-index: 1006;
}
#menubar:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background: #f2f2f3;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
}
#menubar .nano:focus,
#menubar .nano-content:focus {
  outline: none;
}
#menubar .nano-pane {
  z-index: 2;
  width: 5px;
  background: transparent;
}
#menubar .nano-slider {
  background: rgba(12, 12, 12, 0.2);
}
#menubar .menubar-scroll-panel {
  position: relative;
  padding-top: 16px;
  z-index: 2;
  min-height: 100%;
}
#menubar .menubar-foot-panel {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid rgba(12, 12, 12, 0.07);
  padding: 16px;
}
.menubar-visible #menubar {
  width: 240px;
}
#menubar.animate {
  -webkit-transition: width 0.5s cubic-bezier(0.15, 0.99, 0.18, 1.01);
  -o-transition: width 0.5s cubic-bezier(0.15, 0.99, 0.18, 1.01);
  transition: width 0.5s cubic-bezier(0.15, 0.99, 0.18, 1.01);
}
body:not(.header-fixed) #menubar,
.menubar-first #menubar {
  top: 0;
}
.menubar-fixed-panel {
  position: relative;
  display: inline-table;
  width: 100%;
  height: 64px;
  z-index: 10;
  border-bottom: 1px solid rgba(12, 12, 12, 0.07);
}
.menubar-fixed-panel > div {
  display: table-cell;
  vertical-align: middle;
  padding: 0 0 0 12px;
}
.menubar-fixed-panel > div:first-child {
  max-width: 26px;
}
.menubar-fixed-panel a {
  text-decoration: none;
}
.menubar-fixed-panel img {
  max-height: 48px;
}
.menubar-fixed-panel .expanded {
  display: none;
}
.menubar-visible .menubar-fixed-panel .expanded,
.menubar-pin .menubar-fixed-panel .expanded {
  display: table-cell;
}
body:not(.menubar-first) .menubar-fixed-panel {
  display: none;
}
#menubar.menubar-inverse {
  color: rgba(255, 255, 255, 0.55);
}
#menubar.menubar-inverse:before {
  background: #2b323a;
}
#menubar.menubar-inverse .nano-slider {
  background: rgba(255, 255, 255, 0.4);
}
.hidden-folded {
  display: none;
}
.menubar-visible .hidden-folded {
  display: inherit;
}
@media (min-width: 1200px) {
  .menubar-pin #menubar {
    width: 240px;
  }
  .menubar-pin .brand-holder {
    display: table-cell;
  }
  .menubar-pin .hidden-folded {
    display: inherit;
  }
}
@media (max-width: 768px) {
  #menubar {
    width: 240px;
    z-index: 1013;
    -webkit-transform: translate(-250px, 0);
    -ms-transform: translate(-250px, 0);
    -o-transform: translate(-250px, 0);
    transform: translate(-250px, 0);
    -webkit-transition: -webkit-transform 0.5s ease-in;
    -moz-transition: -moz-transform 0.5s ease-in;
    -o-transition: -o-transform 0.5s ease-in;
    transition: transform 0.5s ease-in;
  }
  .menubar-visible #menubar {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.15, 0.99, 0.18, 1.01);
    -moz-transition: -moz-transform 0.5s cubic-bezier(0.15, 0.99, 0.18, 1.01);
    -o-transition: -o-transform 0.5s cubic-bezier(0.15, 0.99, 0.18, 1.01);
    transition: transform 0.5s cubic-bezier(0.15, 0.99, 0.18, 1.01);
  }
}
.gui-controls {
  font-size: 12px;
  padding: 0;
  margin-bottom: 30px;
}
.gui-controls small {
  display: block;
  margin: 30px 12px 12px 12px;
  font-size: 10px;
  font-weight: 500;
  opacity: 0.7;
}
.menubar-visible .gui-controls small,
.menubar-pin .gui-controls small {
  color: rgba(12, 12, 12, 0.85);
}
.gui-controls li:first-child > small {
  margin-top: 0;
}
.gui-controls .badge {
  position: absolute;
  top: 1px;
  margin-left: 8px;
  border-radius: 2px;
  padding: 2px 4px;
  font-size: 10px;
}
.gui-controls li {
  list-style: none;
}
.gui-controls li.gui-folder > a {
  cursor: pointer;
}
.gui-controls > li {
  position: relative;
  margin-bottom: 12px;
}
.gui-controls > li > a {
  position: relative;
  display: block;
  text-decoration: none;
  padding: 12px 0px;
  font-size: 12px;
  line-height: normal;
  border-radius: 0;
  padding-left: 0;
  min-height: 40px;
  min-width: 64px;
  z-index: 1;
}
.gui-controls > li > a.btn-flat {
  padding: 12px 0px;
}
.gui-controls > li > a:focus {
  outline: none;
}
.gui-controls > li ul > li > a {
  position: relative;
  display: block;
  text-decoration: none;
  padding: 7px 17px;
  font-size: 12px;
  line-height: normal;
  border-radius: 0;
  padding-left: 57px;
}
.gui-controls > li ul > li > a.btn-flat {
  padding: 7px 8.5px;
}
.gui-controls > li ul > li > a:active,
.gui-controls > li ul > li > a.active {
  background-color: #ffffff;
}
.gui-controls > li ul > li > a:hover,
.gui-controls > li ul > li > a:focus {
  background-color: rgba(12, 12, 12, 0.07);
}
.gui-controls.nav > li > a:hover {
  background: inherit;
}
.expanded-menu-item-markup .gui-controls > li:not(.gui-folder) > a:hover {
  background-color: rgba(12, 12, 12, 0.07);
}
.expanded-menu-item-markup .gui-controls > li.active:not(.gui-folder) > a {
  background-color: #ffffff;
  -webkit-transition: background 0.3s linear;
  -o-transition: background 0.3s linear;
  transition: background 0.3s linear;
}
.menubar-visible .gui-controls > li:not(.gui-folder) > a:hover {
  background-color: rgba(12, 12, 12, 0.07);
}
.menubar-visible .gui-controls > li.active:not(.gui-folder) > a {
  background-color: #ffffff;
  -webkit-transition: background 0.3s linear;
  -o-transition: background 0.3s linear;
  transition: background 0.3s linear;
}
.menubar-visible .menubar-inverse .gui-controls > li:not(.gui-folder) > a:hover {
  background-color: #313942;
}
.menubar-visible .menubar-inverse .gui-controls > li.active:not(.gui-folder) > a {
  background-color: #20252b;
}
.gui-controls li ul {
  padding: 0;
  display: none;
}
.gui-controls li.expanded > ul {
  display: block;
}
.gui-controls > li > ul > li > ul li:before {
  left: 51px;
}
.gui-controls > li > ul > li > ul a {
  padding-left: 78px;
}
.gui-controls > li > ul > li > ul a:before {
  left: 51px;
}
.gui-controls > li > ul > li > ul > li > ul li:before {
  left: 72px;
}
.gui-controls > li > ul > li > ul > li > ul a {
  padding-left: 99px;
}
.gui-controls > li > ul > li > ul > li > ul a:before {
  left: 72px;
}
.gui-controls > li > ul > li > ul > li > ul > li > ul li:before {
  left: 93px;
}
.gui-controls > li > ul > li > ul > li > ul > li > ul a {
  padding-left: 120px;
}
.gui-controls > li > ul > li > ul > li > ul > li > ul a:before {
  left: 93px;
}
.gui-controls > li > ul > li > ul > li > ul > li > ul > li > ul li:before {
  left: 114px;
}
.gui-controls > li > ul > li > ul > li > ul > li > ul > li > ul a {
  padding-left: 141px;
}
.gui-controls > li > ul > li > ul > li > ul > li > ul > li > ul a:before {
  left: 114px;
}
.gui-controls > li ul > li {
  position: relative;
}
.gui-controls > li ul > li:before {
  content: "";
  left: 30px;
  top: 15px;
  bottom: -15px;
  position: absolute;
  display: block;
  width: 1px;
  border-left: 1px dashed rgba(12, 12, 12, 0.3);
  z-index: 1;
}
.gui-controls > li ul > li:first-child:before {
  top: 0;
}
.gui-controls > li ul > li:last-child:before,
.gui-controls > li ul > li.last-child:before {
  display: none;
}
.gui-controls > li ul > li > a:before {
  content: "";
  left: 30px;
  top: 15px;
  position: absolute;
  display: block;
  width: 21px;
  border-bottom: 1px dashed rgba(12, 12, 12, 0.3);
  z-index: 1;
}
.gui-controls li .title {
  position: relative;
  left: -10px;
  display: block;
  padding-left: 6px;
  color: rgba(12, 12, 12, 0.85);
  white-space: nowrap;
  overflow: hidden;
  opacity: 0;
  text-shadow: none;
  -webkit-transition: all 0.5s cubic-bezier(0.15, 0.99, 0.18, 1.01);
  -o-transition: all 0.5s cubic-bezier(0.15, 0.99, 0.18, 1.01);
  transition: all 0.5s cubic-bezier(0.15, 0.99, 0.18, 1.01);
}
.gui-controls li > a:hover .title {
  color: #0c0c0c;
}
.gui-controls li.active > a .title {
  color: #2267a2;
  font-weight: 500;
}
.gui-controls li.gui-folder > a .title {
  font-style: italic;
  color: rgba(12, 12, 12, 0.75);
}
.gui-controls li.gui-folder.expanded > a .title,
.gui-controls li.gui-folder.active > a .title {
  color: rgba(12, 12, 12, 0.85);
}
.gui-controls > li > a .title {
  margin-left: 57px;
}
.expanded-menu-title .gui-controls li .title {
  left: 0;
  opacity: 1;
}
.menubar-visible .gui-controls li .title {
  left: 0;
  opacity: 1;
}
.gui-folder > a:after {
  content: "+";
  position: absolute;
  top: 7px;
  display: inline-block;
  margin-left: -12px;
  color: rgba(12, 12, 12, 0.5);
  font-size: 12px;
  font-weight: inherit;
  opacity: 0;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.15s linear;
}
.gui-folder.expanding > a:after,
.gui-folder.expanded > a:after {
  font-size: 16px;
  font-weight: 400;
  margin-top: -2px;
  margin-left: -12px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
}
.gui-controls > .gui-folder > a:after {
  top: 12px;
  margin-left: 2px;
}
.gui-controls > .gui-folder.expanding > a:after,
.gui-controls > .gui-folder.expanded > a:after {
  color: rgba(12, 12, 12, 0.4);
  margin-left: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.expanded-menu-sign .gui-folder > a:after {
  opacity: 1;
}
.menubar-visible .gui-folder > a:after {
  opacity: 1;
}
.gui-icon {
  position: absolute;
  left: 12px;
  top: 0;
  width: 40px;
  height: 40px;
  color: #535658;
  font-size: 18px;
  overflow: hidden;
  border-radius: 999px;
  -webkit-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.15s linear;
}
.gui-icon .fa:first-child,
.gui-icon .md:first-child,
.gui-icon .glyphicon:first-child {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  line-height: 40px;
  width: 1em;
}
.gui-icon:hover {
  background-color: rgba(12, 12, 12, 0.07);
  color: #313335;
}
.gui-icon:hover img {
  left: -5px;
  top: -5px;
  width: 50px;
  height: 50px;
}
.gui-controls a.expanded .gui-icon,
.gui-controls li.active .gui-icon,
.gui-controls li.active .gui-icon:hover {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #2267a2;
}
.gui-icon-active {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #2267a2;
}
.expanded-menu-icon .gui-folder:hover:not(.active) .gui-icon {
  background-color: rgba(12, 12, 12, 0.07);
  color: #313335;
}
.expanded-menu-icon .gui-controls > li:not(.gui-folder) .gui-icon:hover {
  background: inherit;
}
.expanded-menu-icon .gui-icon:hover {
  background: inherit;
}
.menubar-visible .gui-folder:hover:not(.active) .gui-icon {
  background-color: rgba(12, 12, 12, 0.07);
  color: #313335;
}
.menubar-visible .gui-controls > li:not(.gui-folder) .gui-icon:hover {
  background: inherit;
}
.menubar-visible .gui-icon:hover {
  background: inherit;
}
.menubar-visible .menubar-inverse .gui-folder:hover:not(.active) .gui-icon {
  background-color: rgba(255, 255, 255, 0.12);
  color: #ffffff;
}
.menubar-visible .menubar-inverse .gui-controls > li:not(.gui-folder) .gui-icon:hover {
  background: inherit;
}
.menubar-visible .menubar-inverse .gui-icon:hover {
  background: inherit;
}
.gui-controls a.blink .gui-icon {
  -webkit-animation: normal 1.4s 4 blink cubic-bezier(0.9, 0, 0.1, 1);
  -o-animation: normal 1.4s 4 blink cubic-bezier(0.9, 0, 0.1, 1);
  animation: normal 1.4s 4 blink cubic-bezier(0.9, 0, 0.1, 1);
  -webkit-animation: normal 1.4s 4 blink cubic-bezier(0.9, 0, 0.1, 1) 1s ease-in;
  -moz-animation: normal 1.4s 4 blink cubic-bezier(0.9, 0, 0.1, 1) 1s ease-in;
  -ms-animation: normal 1.4s 4 blink cubic-bezier(0.9, 0, 0.1, 1) 1s ease-in;
  -o-animation: normal 1.4s 4 blink cubic-bezier(0.9, 0, 0.1, 1) 1s ease-in;
  animation: normal 1.4s 4 blink cubic-bezier(0.9, 0, 0.1, 1) 1s ease-in;
}
@-moz-keyframes blink {
  50% {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #2267a2;
  }
}
@-webkit-keyframes blink {
  50% {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #2267a2;
  }
}
@keyframes blink {
  50% {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #2267a2;
  }
}
@media (min-width: 1200px) {
  .menubar-pin .gui-controls > li:not(.gui-folder) > a:hover {
    background-color: rgba(12, 12, 12, 0.07);
  }
  .menubar-pin .gui-controls > li.active:not(.gui-folder) > a {
    background-color: #ffffff;
    -webkit-transition: background 0.3s linear;
    -o-transition: background 0.3s linear;
    transition: background 0.3s linear;
  }
  .menubar-pin .menubar-inverse .gui-controls > li:not(.gui-folder) > a:hover {
    background-color: #313942;
  }
  .menubar-pin .menubar-inverse .gui-controls > li.active:not(.gui-folder) > a {
    background-color: #20252b;
  }
  .menubar-pin .gui-controls li .title {
    left: 0;
    opacity: 1;
  }
  .menubar-pin .gui-folder > a:after {
    opacity: 1;
  }
  .menubar-pin .gui-folder:hover:not(.active) .gui-icon {
    background-color: rgba(12, 12, 12, 0.07);
    color: #313335;
  }
  .menubar-pin .gui-controls > li:not(.gui-folder) .gui-icon:hover {
    background: inherit;
  }
  .menubar-pin .gui-icon:hover {
    background: inherit;
  }
  .menubar-pin .menubar-inverse .gui-folder:hover:not(.active) .gui-icon {
    background-color: rgba(255, 255, 255, 0.12);
    color: #ffffff;
  }
  .menubar-pin .menubar-inverse .gui-controls > li:not(.gui-folder) .gui-icon:hover {
    background: inherit;
  }
  .menubar-pin .menubar-inverse .gui-icon:hover {
    background: inherit;
  }
}
.menubar-visible .menubar-inverse .gui-controls small,
.menubar-pin .menubar-inverse .gui-controls small {
  color: rgba(255, 255, 255, 0.55);
}
.menubar-inverse .gui-controls > li ul > li > a:active,
.menubar-inverse .gui-controls > li ul > li > a.active {
  background-color: #20252b;
}
.menubar-inverse .gui-controls > li ul > li > a:hover,
.menubar-inverse .gui-controls > li ul > li > a:focus {
  background-color: #313942;
}
.expanded-menu-item-markup .menubar-inverse .gui-controls > li:not(.gui-folder) > a:hover {
  background-color: #313942;
}
.expanded-menu-item-markup .menubar-inverse .gui-controls > li.active:not(.gui-folder) > a {
  background-color: #20252b;
}
.menubar-visible .gui-controls > li:not(.gui-folder) > a:hover {
  background-color: rgba(12, 12, 12, 0.07);
}
.menubar-visible .gui-controls > li.active:not(.gui-folder) > a {
  background-color: #ffffff;
  -webkit-transition: background 0.3s linear;
  -o-transition: background 0.3s linear;
  transition: background 0.3s linear;
}
.menubar-visible .menubar-inverse .gui-controls > li:not(.gui-folder) > a:hover {
  background-color: #313942;
}
.menubar-visible .menubar-inverse .gui-controls > li.active:not(.gui-folder) > a {
  background-color: #20252b;
}
.menubar-inverse .gui-controls > li ul > li:before {
  border-left: 1px dashed rgba(255, 255, 255, 0.3);
}
.menubar-inverse .gui-controls > li ul > li > a:before {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.3);
}
.menubar-inverse .gui-controls li .title {
  color: rgba(255, 255, 255, 0.55);
  text-shadow: 0 1px 2px #000000;
}
.menubar-inverse .gui-controls li > a:hover .title {
  color: #ffffff;
}
.menubar-inverse .gui-controls li.active > a .title {
  color: #ffffff;
}
.menubar-inverse .gui-controls li.gui-folder > a .title {
  color: rgba(255, 255, 255, 0.5);
}
.menubar-inverse .gui-controls li.gui-folder.expanded > a .title,
.menubar-inverse .gui-controls li.gui-folder.active > a .title {
  color: rgba(255, 255, 255, 0.55);
}
.menubar-inverse .gui-folder > a:after {
  color: rgba(255, 255, 255, 0.5);
}
.menubar-inverse .gui-controls > .gui-folder.expanding > a:after,
.menubar-inverse .gui-controls > .gui-folder.expanded > a:after {
  color: rgba(255, 255, 255, 0.4);
}
.menubar-inverse .gui-icon {
  color: rgba(255, 255, 255, 0.65);
}
.menubar-inverse .gui-icon:hover {
  background-color: rgba(255, 255, 255, 0.12);
  color: #ffffff;
}
.menubar-inverse .gui-controls a.expanded .gui-icon,
.menubar-inverse .gui-controls li.active .gui-icon,
.menubar-inverse .gui-controls li.active .gui-icon:hover {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #2267a2;
}
.menubar-inverse .gui-icon-active {
  background-color: #2267a2;
  border-color: #ffffff;
  color: #ffffff;
}
.expanded-menu-icon .menubar-inverse .gui-folder:hover:not(.active) .gui-icon {
  background-color: rgba(255, 255, 255, 0.12);
  color: #ffffff;
}
.expanded-menu-icon .menubar-inverse .gui-controls > li:not(.gui-folder) .gui-icon:hover {
  background: inherit;
}
.expanded-menu-icon .menubar-inverse .gui-icon:hover {
  background: inherit;
}
.menubar-visible .gui-folder:hover:not(.active) .gui-icon {
  background-color: rgba(12, 12, 12, 0.07);
  color: #313335;
}
.menubar-visible .gui-controls > li:not(.gui-folder) .gui-icon:hover {
  background: inherit;
}
.menubar-visible .gui-icon:hover {
  background: inherit;
}
.menubar-visible .menubar-inverse .gui-folder:hover:not(.active) .gui-icon {
  background-color: rgba(255, 255, 255, 0.12);
  color: #ffffff;
}
.menubar-visible .menubar-inverse .gui-controls > li:not(.gui-folder) .gui-icon:hover {
  background: inherit;
}
.menubar-visible .menubar-inverse .gui-icon:hover {
  background: inherit;
}
@media (min-width: 1200px) {
  .menubar-pin .gui-controls > li:not(.gui-folder) > a:hover {
    background-color: rgba(12, 12, 12, 0.07);
  }
  .menubar-pin .gui-controls > li.active:not(.gui-folder) > a {
    background-color: #ffffff;
    -webkit-transition: background 0.3s linear;
    -o-transition: background 0.3s linear;
    transition: background 0.3s linear;
  }
  .menubar-pin .menubar-inverse .gui-controls > li:not(.gui-folder) > a:hover {
    background-color: #313942;
  }
  .menubar-pin .menubar-inverse .gui-controls > li.active:not(.gui-folder) > a {
    background-color: #20252b;
  }
  .menubar-pin .gui-folder:hover:not(.active) .gui-icon {
    background-color: rgba(12, 12, 12, 0.07);
    color: #313335;
  }
  .menubar-pin .gui-controls > li:not(.gui-folder) .gui-icon:hover {
    background: inherit;
  }
  .menubar-pin .gui-icon:hover {
    background: inherit;
  }
  .menubar-pin .menubar-inverse .gui-folder:hover:not(.active) .gui-icon {
    background-color: rgba(255, 255, 255, 0.12);
    color: #ffffff;
  }
  .menubar-pin .menubar-inverse .gui-controls > li:not(.gui-folder) .gui-icon:hover {
    background: inherit;
  }
  .menubar-pin .menubar-inverse .gui-icon:hover {
    background: inherit;
  }
}
section {
  position: relative;
  padding: 24px;
}
section:first-child {
  padding-top: 0;
}
.row section {
  margin-bottom: 0;
}
section.full-bleed {
  padding: 0 !important;
}
.section-body:first-child {
  margin-top: 24px;
}
.full-bleed .section-body:first-child {
  margin-top: 0;
}
.section-body.full-bleed {
  margin: 0 -24px;
}
.section-header {
  height: 64px;
  padding: 14px 0;
}
.section-header h1,
.section-header h2,
.section-header h3,
.section-header h4,
.section-header h5,
.section-header h6 {
  text-shadow: none;
  line-height: 1.84615385;
  margin: 0;
}
.section-header h1 {
  margin-top: -9px;
  margin-bottom: -6px;
}
.section-header h2 {
  margin-top: -3px;
  margin-bottom: -6px;
}
.section-header h3 {
  margin-top: 0px;
}
.section-header h4 {
  margin-top: 5px;
}
.section-header h5 {
  margin-top: 9px;
}
.section-header h6 {
  margin-top: 8px;
}
.section-action {
  position: relative;
  height: 64px;
  padding-bottom: 8px;
  padding-top: 8px;
}
.section-action:before,
.section-action:after {
  content: " ";
  display: table;
}
.section-action:after {
  clear: both;
}
.full-content .section-action {
  position: fixed;
  z-index: 1001;
  bottom: 0;
  left: 64px;
  right: 0;
  -webkit-box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.33);
  box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.33);
}
.section-action-row {
  padding: 6px 16px;
}
.section-floating-action-row {
  position: absolute;
  right: 24px;
  top: 0;
}
.section-floating-action-row .btn {
  top: -20px;
}
.section-floating-action-row .btn-lg {
  top: -28px;
}
.section-floating-action-row .btn-sm {
  top: -18px;
}
.section-floating-action-row .btn-xs {
  top: -15px;
}
section.section-account .img-backdrop {
  background-position: center;
  height: 0;
}
section.section-account .spacer {
  display: block;
  position: relative;
}
section.section-account .img-circle {
  float: left;
  right: 0;
  border: 5px solid #ffffff;
  margin-right: 20px;
  width: 120px;
  height: 120px;
}
@media (min-height: 500px) and (max-height: 700px) {
  section.section-account .img-backdrop,
  section.section-account .spacer {
    height: 150px;
  }
  section.section-account .img-circle {
    position: absolute;
    top: -80px;
    margin-right: 0;
  }
  section.section-account .img-backdrop {
    -webkit-animation: backdrop-animation 0.8s ease-out;
    -o-animation: backdrop-animation 0.8s ease-out;
    animation: backdrop-animation 0.8s ease-out;
    -webkit-animation: backdrop-animation 0.8s ease-out 1s ease-in;
    -moz-animation: backdrop-animation 0.8s ease-out 1s ease-in;
    -ms-animation: backdrop-animation 0.8s ease-out 1s ease-in;
    -o-animation: backdrop-animation 0.8s ease-out 1s ease-in;
    animation: backdrop-animation 0.8s ease-out 1s ease-in;
  }
  section.section-account .card-body {
    -webkit-animation: box-animation 1s ease-out;
    -o-animation: box-animation 1s ease-out;
    animation: box-animation 1s ease-out;
    -webkit-animation: box-animation 1s ease-out 1s ease-in;
    -moz-animation: box-animation 1s ease-out 1s ease-in;
    -ms-animation: box-animation 1s ease-out 1s ease-in;
    -o-animation: box-animation 1s ease-out 1s ease-in;
    animation: box-animation 1s ease-out 1s ease-in;
  }
}
@media (min-height: 700px) {
  section.section-account .img-backdrop,
  section.section-account .spacer {
    height: 300px;
  }
  section.section-account .img-circle {
    position: absolute;
    top: -80px;
    margin-right: 0;
  }
  section.section-account .img-backdrop {
    -webkit-animation: backdrop-animation 0.8s ease-out;
    -o-animation: backdrop-animation 0.8s ease-out;
    animation: backdrop-animation 0.8s ease-out;
    -webkit-animation: backdrop-animation 0.8s ease-out 1s ease-in;
    -moz-animation: backdrop-animation 0.8s ease-out 1s ease-in;
    -ms-animation: backdrop-animation 0.8s ease-out 1s ease-in;
    -o-animation: backdrop-animation 0.8s ease-out 1s ease-in;
    animation: backdrop-animation 0.8s ease-out 1s ease-in;
  }
  section.section-account .card-body {
    -webkit-animation: box-animation 1s ease-out;
    -o-animation: box-animation 1s ease-out;
    animation: box-animation 1s ease-out;
    -webkit-animation: box-animation 1s ease-out 1s ease-in;
    -moz-animation: box-animation 1s ease-out 1s ease-in;
    -ms-animation: box-animation 1s ease-out 1s ease-in;
    -o-animation: box-animation 1s ease-out 1s ease-in;
    animation: box-animation 1s ease-out 1s ease-in;
  }
}
@-webkit-keyframes backdrop-animation {
  from {
    margin-top: -10px;
  }
  to {
    margin-top: 0;
  }
}
@keyframes backdrop-animation {
  from {
    margin-top: -10px;
  }
  to {
    margin-top: 0;
  }
}
@-webkit-keyframes box-animation {
  from {
    opacity: 0;
    margin-top: -20px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes box-animation {
  from {
    opacity: 0;
    margin-top: -20px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}
@media (min-width: 1200px) {
  .menubar-pin.full-content .section-action {
    left: 240px;
  }
}
@media (min-width: 769px) {
  .full-content section {
    position: absolute;
    top: 64px;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
  }
  .full-content section.has-actions {
    bottom: 64px;
  }
}
@media (max-width: 768px) {
  section {
    padding: 12px;
  }
  .full-content section.has-actions {
    padding-bottom: 64px;
  }
  .full-content .section-action {
    left: 0;
  }
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  vertical-align: baseline;
}
.table thead {
  opacity: 0.75;
}
.table thead > tr > th {
  color: #2B2A65;
  font-weight: 700;
}
.table-bordered {
  border-color: rgba(163, 166, 168, 0.2);
}
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border-color: rgba(163, 166, 168, 0.2);
}
.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: rgba(242, 242, 243, 0.35);
}
.table-banded > tbody > tr > td:nth-child(odd),
.table-banded > tbody > tr > th:nth-child(odd) {
  background-color: rgba(242, 242, 243, 0.35);
}
.table-no-border {
  border-color: transparent;
}
.table-no-border > thead > tr > th,
.table-no-border > tbody > tr > th,
.table-no-border > tfoot > tr > th,
.table-no-border > thead > tr > td,
.table-no-border > tbody > tr > td,
.table-no-border > tfoot > tr > td {
  border-color: transparent;
}
.table-condensed > tbody > tr > td {
  vertical-align: middle;
}
.table-hover > tbody > tr:hover > td,
.table-hover > tbody > tr:hover > th {
  background-color: rgba(229, 230, 230, 0.25);
}

legend {
  display: table;
  width: 100%;
  margin: 13px 0 20px 0;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
}
legend span {
  display: table-cell;
  padding: 0 10px 0 0;
  position: relative;
  bottom: -7px;
  white-space: nowrap;
  opacity: 0.8;
}
legend:before,
legend:after {
  content: '';
  display: table-cell;
  border-bottom: 1px solid rgba(12, 12, 12, 0.25);
}
legend:before {
  width: 0%;
}
legend:after {
  width: 100%;
}
legend.text-center span {
  padding: 0 10px;
}
legend.text-center:before,
legend.text-center:after {
  width: 50%;
}
legend.text-right span {
  padding: 0 0 0 10px;
}
legend.text-right:before {
  width: 100%;
}
legend.text-right:after {
  width: 0%;
}
label {
  font-weight: 500;
}
.form-group > label,
.form-group .control-label {
  font-size: 14px;
  opacity: 1;
}
/*.form,
.form-inline {
  .form-group > label {
    position: absolute;
    top: 0;
    left: 0;
  }
}*/
.form-control-static {
  font-size: 16px;
}
.has-feedback label ~ .form-control-feedback,
.form-control-feedback {
  top: auto;
  bottom: 0;
}
/*.form-control {
  //padding: 0;
  height: 37px;
  //border-left: none;
  //border-right: none;
  //border-top: none;
  border-bottom-color: @input-border;
  background: transparent;
  color: @input-color;
  font-size: 16px;
  .box-shadow(none);

  // Placeholder colors
  &.placeholder {
    color: @input-color-placeholder;
    option {
      color: @input-color;
    }
  }

  // Disabled and read-only inputs
  &[disabled],
  &[readonly],
  fieldset[disabled] & {
    background-color: transparent;
    border-bottom-color: @input-border-disabled;
    border-bottom-style: dashed;

    .placeholder(lighten(@input-color-placeholder, 10%));

    // Hide the bottom line when its a readonly
    ~ label:after, ~ .form-control-line:after {
      display: none;
    }
  }
}

// Textarea
textarea.form-control {
  padding: 4px 0 4px 0;
}
textarea.autosize {
  min-height: 38px;
}


// Form groups controls
// -------------------------

.form,
.form-inline {
  .form-group {
    padding-top: 16px;
  }
}
.form-group {
  position: relative;
  margin-bottom: 19px;

  // Animate label, except when a input is already filled on load
  .form-control:not(.static) {
    ~ label,
    ~ .form-control-line {
      .transition(0.2s ease all);
    }
  }

  // Add styling for the bottom input line
  .form-control {
    ~ label,
    ~ .form-control-line {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      pointer-events: none;

      &:after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 45%;
        height: 2px;
        width: 10px;
        visibility: hidden;
        background-color: @input-border-focus;
        .transition(0.2s ease all);
      }
    }

    // Basic label styling
    ~ label {
      top: 0;
      margin-bottom: 0;
      font-size: @font-size-small;
      opacity: @form-label-opacity;
    }

    &:focus {
      border-color: @input-border;

      ~ label {
        color: @form-label-focus;
        opacity: @form-label-focus-opacity;
      }
    }
  }

  // Expand the line when the input is focused
  .form-control:focus {
    ~ label:after, ~ .form-control-line:after {
      visibility: visible;
      width: 100%;
      left: 0;
    }
  }
}


// Floating labels
// -------------------------

.floating-label {
  // Basic label styling
  .form-control ~ label {
    top: 19px;
    font-size: 16px;
  }

  // Expand the line when the input is focused or dirty
  .form-control:focus ~ label, .form-control.dirty ~ label {
    top: 0px;
    font-size: @font-size-small !important;
  }
}*/
.input-sm {
  padding: 4.5px 14px;
  height: 30px;
  font-size: 1em;
  line-height: 1.5;
  border-radius: 4px;
}
select.input-sm {
  height: 30px;
  line-height: 30px;
}
textarea.input-sm,
select[multiple].input-sm {
  height: auto;
}
select.input-sm {
  height: 30px;
  line-height: 30px;
}
textarea.input-sm,
select[multiple].input-sm {
  height: auto;
}
.floating-label .input-sm ~ label {
  font-size: 1em;
}
.input-lg {
  padding: 4.5px 14px;
  height: 46px;
  font-size: 1.6em;
  line-height: 1.3333333;
  border-radius: 6px;
}
select.input-lg {
  height: 46px;
  line-height: 46px;
}
textarea.input-lg,
select[multiple].input-lg {
  height: auto;
}
select.input-lg {
  height: 46px;
  line-height: 46px;
}
textarea.input-lg,
select[multiple].input-lg {
  height: auto;
}
.floating-label .input-lg ~ label {
  font-size: 1.6em;
}
.form-group.control-width-tiny {
  max-width: 40px;
}
.form-group.control-width-mini {
  max-width: 72px;
}
.form-group.control-width-small {
  max-width: 136px;
}
.form-group.control-width-medium {
  max-width: 200px;
}
.form-group.control-width-normal {
  max-width: 300px;
}
.form-group.control-width-large {
  max-width: 500px;
}
.form-group.control-width-xlarge {
  max-width: 800px;
}
.form-group.control-width-xxlarge {
  max-width: 1200px;
}
textarea.control-2-rows {
  height: 64px;
}
textarea.control-3-rows {
  height: 96px;
}
textarea.control-4-rows {
  height: 128px;
}
textarea.control-5-rows {
  height: 160px;
}
textarea.control-6-rows {
  height: 192px;
}
textarea.control-7-rows {
  height: 224px;
}
textarea.control-8-rows {
  height: 256px;
}
textarea.control-9-rows {
  height: 288px;
}
textarea.control-10-rows {
  height: 320px;
}
textarea.control-11-rows {
  height: 352px;
}
textarea.control-12-rows {
  height: 384px;
}
textarea.no-resize {
  resize: none;
}
/*.form-horizontal {
  .form-group {
    padding-top: 0;
    margin-bottom: 11px;
  }
  .form-control ~ .form-control-line {
    left: (@grid-gutter-width / 2);
    right: (@grid-gutter-width / 2);
    width: auto;
  }
  .input-group {
    .form-control ~ .form-control-line {
      left: 0;
      right: 0;
    }
  }
}*/
@media (min-width: 769px) {
  .form-inline .form-group {
    margin-right: 12px;
  }
  .form-inline .radio,
  .form-inline .checkbox,
  .form-inline .btn {
    margin-top: 16px;
    margin-right: 12px;
  }
}
.help-block {
  margin: 0;
  color: inherit;
  opacity: .9;
  font-size: 10px;
  text-align: right;
}
.form-horizontal .help-block {
  bottom: -20px;
  right: 10px;
}
span.help-block {
  right: auto;
}
.form-horizontal span.help-block {
  right: auto;
}
.form-inverse .form-group .form-control {
  color: #f2f2f3;
  border-bottom-color: rgba(255, 255, 255, 0.25);
}
.form-inverse .form-group .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}
.form-inverse .form-group .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.form-inverse .form-group .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.form-inverse .form-group .form-control ~ label:after,
.form-inverse .form-group .form-control ~ .form-control-line:after {
  background-color: #a6cced;
}
.form-inverse .form-group .form-control:focus {
  border-color: rgba(255, 255, 255, 0.25);
}
.form-inverse .form-group .form-control:focus ~ label {
  color: #a6cced;
}
.form-inverse .form-group .form-control[disabled],
.form-inverse .form-group .form-control[readonly],
fieldset[disabled] .form-inverse .form-group .form-control {
  border-bottom-color: rgba(255, 255, 255, 0.4);
}
.form-inverse select:focus {
  background: #ffffff;
  color: #313335 !important;
}
.has-warning .help-block {
  color: #ff9800;
  opacity: 1;
}
.has-warning .form-control {
  border-color: #ff9800;
}
.has-warning .form-control:focus,
.has-warning .form-control.dirty {
  border-color: #ff9800;
}
.has-warning .form-control:focus ~ label,
.has-warning .form-control.dirty ~ label {
  color: inherit;
}
.has-warning .form-control ~ label,
.has-warning .form-control ~ .form-control-line {
  color: inherit;
}
.has-warning .form-control ~ label:after,
.has-warning .form-control ~ .form-control-line:after {
  background-color: #ff9800;
}
.has-warning label,
.has-warning .control-label,
.has-warning .form-control-feedback {
  color: #ff9800;
}
.form-horizontal .has-warning label,
.form-horizontal .has-warning .control-label,
.form-horizontal .has-warning .form-control-feedback {
  opacity: 1;
}
.form-inverse .has-warning .help-block {
  color: #e69c2e;
  opacity: 1;
}
.form-inverse .has-warning .form-control {
  border-color: #e69c2e;
}
.form-inverse .has-warning .form-control:focus,
.form-inverse .has-warning .form-control.dirty {
  border-color: #e69c2e;
}
.form-inverse .has-warning .form-control:focus ~ label,
.form-inverse .has-warning .form-control.dirty ~ label {
  color: inherit;
}
.form-inverse .has-warning .form-control ~ label,
.form-inverse .has-warning .form-control ~ .form-control-line {
  color: inherit;
}
.form-inverse .has-warning .form-control ~ label:after,
.form-inverse .has-warning .form-control ~ .form-control-line:after {
  background-color: #e69c2e;
}
.form-inverse .has-warning label,
.form-inverse .has-warning .control-label,
.form-inverse .has-warning .form-control-feedback {
  color: #e69c2e;
}
.form-horizontal .form-inverse .has-warning label,
.form-horizontal .form-inverse .has-warning .control-label,
.form-horizontal .form-inverse .has-warning .form-control-feedback {
  opacity: 1;
}
.has-error .help-block {
  color: #f44336;
  opacity: 1;
}
.has-error .form-control {
  border-color: #f44336;
}
.has-error .form-control:focus,
.has-error .form-control.dirty {
  border-color: #f44336;
}
.has-error .form-control:focus ~ label,
.has-error .form-control.dirty ~ label {
  color: inherit;
}
.has-error .form-control ~ label,
.has-error .form-control ~ .form-control-line {
  color: inherit;
}
.has-error .form-control ~ label:after,
.has-error .form-control ~ .form-control-line:after {
  background-color: #f44336;
}
.has-error label,
.has-error .control-label,
.has-error .form-control-feedback {
  color: #f44336;
}
.form-horizontal .has-error label,
.form-horizontal .has-error .control-label,
.form-horizontal .has-error .form-control-feedback {
  opacity: 1;
}
.form-inverse .has-error .help-block {
  color: #e65045;
  opacity: 1;
}
.form-inverse .has-error .form-control {
  border-color: #e65045;
}
.form-inverse .has-error .form-control:focus,
.form-inverse .has-error .form-control.dirty {
  border-color: #e65045;
}
.form-inverse .has-error .form-control:focus ~ label,
.form-inverse .has-error .form-control.dirty ~ label {
  color: inherit;
}
.form-inverse .has-error .form-control ~ label,
.form-inverse .has-error .form-control ~ .form-control-line {
  color: inherit;
}
.form-inverse .has-error .form-control ~ label:after,
.form-inverse .has-error .form-control ~ .form-control-line:after {
  background-color: #e65045;
}
.form-inverse .has-error label,
.form-inverse .has-error .control-label,
.form-inverse .has-error .form-control-feedback {
  color: #e65045;
}
.form-horizontal .form-inverse .has-error label,
.form-horizontal .form-inverse .has-error .control-label,
.form-horizontal .form-inverse .has-error .form-control-feedback {
  opacity: 1;
}
.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
  color: #ff9800;
}
.has-warning .form-control {
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-warning .form-control:focus {
  border-color: #cc7a00;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ffc166;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ffc166;
}
.has-warning .input-group-addon {
  color: #ff9800;
  border-color: #ff9800;
  background-color: #faebd4;
}
.has-warning .form-control-feedback {
  color: #ff9800;
}
.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline {
  color: #ff9800;
}
.has-warning .form-control {
  border-color: #ff9800;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-warning .form-control:focus {
  border-color: #ff9800;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-warning .input-group-addon {
  color: #ff9800;
  background-color: #faebd4;
}
.has-warning .form-control-feedback {
  color: #ff9800;
}
.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: #f44336;
}
.has-error .form-control {
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-error .form-control:focus {
  border-color: #ea1c0d;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #f99d97;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #f99d97;
}
.has-error .input-group-addon {
  color: #f44336;
  border-color: #f44336;
  background-color: #fad7d4;
}
.has-error .form-control-feedback {
  color: #f44336;
}
.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline {
  color: #f44336;
}
.has-error .form-control {
  border-color: #f44336;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-error .form-control:focus {
  border-color: #f44336;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-error .input-group-addon {
  color: #f44336;
  background-color: #fad7d4;
}
.has-error .form-control-feedback {
  color: #f44336;
}
.has-success .help-block {
  color: #4caf50;
  opacity: 1;
}
.has-success .form-control {
  border-color: #4caf50;
}
.has-success .form-control:focus,
.has-success .form-control.dirty {
  border-color: #4caf50;
}
.has-success .form-control:focus ~ label,
.has-success .form-control.dirty ~ label {
  color: inherit;
}
.has-success .form-control ~ label,
.has-success .form-control ~ .form-control-line {
  color: inherit;
}
.has-success .form-control ~ label:after,
.has-success .form-control ~ .form-control-line:after {
  background-color: #4caf50;
}
.has-success label,
.has-success .control-label,
.has-success .form-control-feedback {
  color: #4caf50;
}
.form-horizontal .has-success label,
.form-horizontal .has-success .control-label,
.form-horizontal .has-success .form-control-feedback {
  opacity: 1;
}
.form-inverse .has-success .help-block {
  color: #45e64a;
  opacity: 1;
}
.form-inverse .has-success .form-control {
  border-color: #45e64a;
}
.form-inverse .has-success .form-control:focus,
.form-inverse .has-success .form-control.dirty {
  border-color: #45e64a;
}
.form-inverse .has-success .form-control:focus ~ label,
.form-inverse .has-success .form-control.dirty ~ label {
  color: inherit;
}
.form-inverse .has-success .form-control ~ label,
.form-inverse .has-success .form-control ~ .form-control-line {
  color: inherit;
}
.form-inverse .has-success .form-control ~ label:after,
.form-inverse .has-success .form-control ~ .form-control-line:after {
  background-color: #45e64a;
}
.form-inverse .has-success label,
.form-inverse .has-success .control-label,
.form-inverse .has-success .form-control-feedback {
  color: #45e64a;
}
.form-horizontal .form-inverse .has-success label,
.form-horizontal .form-inverse .has-success .control-label,
.form-horizontal .form-inverse .has-success .form-control-feedback {
  opacity: 1;
}
.btn-default-bright {
  color: #e5e6e6;
  color: #313335;
  background-color: #ffffff;
  border-color: #e5e6e6;
}
.btn-default-bright:hover,
.btn-default-bright:focus,
.btn-default-bright.focus,
.btn-default-bright:active,
.btn-default-bright.active,
.open > .dropdown-toggle.btn-default-bright {
  color: #e5e6e6;
  background-color: #e6e6e6;
  border-color: #c5c7c9;
}
.btn-default-bright:active,
.btn-default-bright.active,
.open > .dropdown-toggle.btn-default-bright {
  background-image: none;
}
.btn-default-bright.disabled,
.btn-default-bright[disabled],
fieldset[disabled] .btn-default-bright,
.btn-default-bright.disabled:hover,
.btn-default-bright[disabled]:hover,
fieldset[disabled] .btn-default-bright:hover,
.btn-default-bright.disabled:focus,
.btn-default-bright[disabled]:focus,
fieldset[disabled] .btn-default-bright:focus,
.btn-default-bright.disabled.focus,
.btn-default-bright[disabled].focus,
fieldset[disabled] .btn-default-bright.focus,
.btn-default-bright.disabled:active,
.btn-default-bright[disabled]:active,
fieldset[disabled] .btn-default-bright:active,
.btn-default-bright.disabled.active,
.btn-default-bright[disabled].active,
fieldset[disabled] .btn-default-bright.active {
  background-color: #ffffff;
  border-color: #e5e6e6;
}
.btn-default-bright .badge {
  color: #ffffff;
  background-color: #e5e6e6;
}
.btn-default-bright:hover,
.btn-default-bright:focus,
.open .dropdown-toggle.btn-default-bright {
  color: #313335;
  background-color: #ededed;
  border-color: #ededed;
}
.btn-default-bright:active,
.btn-default-bright.active {
  color: #313335;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
}
.btn-default-bright.btn-flat,
.btn-default-bright.btn-icon-toggle {
  color: #e5e6e6;
}
.open .dropdown-toggle.btn-default-bright.btn-flat,
.open .dropdown-toggle.btn-default-bright.btn-icon-toggle {
  color: #ffffff;
}
.btn-default-light {
  color: #bdbfc1;
  color: #313335;
  background-color: #f2f2f3;
  border-color: #f2f2f3;
}
.btn-default-light:hover,
.btn-default-light:focus,
.btn-default-light.focus,
.btn-default-light:active,
.btn-default-light.active,
.open > .dropdown-toggle.btn-default-light {
  color: #bdbfc1;
  background-color: #d8d9da;
  border-color: #d2d4d5;
}
.btn-default-light:active,
.btn-default-light.active,
.open > .dropdown-toggle.btn-default-light {
  background-image: none;
}
.btn-default-light.disabled,
.btn-default-light[disabled],
fieldset[disabled] .btn-default-light,
.btn-default-light.disabled:hover,
.btn-default-light[disabled]:hover,
fieldset[disabled] .btn-default-light:hover,
.btn-default-light.disabled:focus,
.btn-default-light[disabled]:focus,
fieldset[disabled] .btn-default-light:focus,
.btn-default-light.disabled.focus,
.btn-default-light[disabled].focus,
fieldset[disabled] .btn-default-light.focus,
.btn-default-light.disabled:active,
.btn-default-light[disabled]:active,
fieldset[disabled] .btn-default-light:active,
.btn-default-light.disabled.active,
.btn-default-light[disabled].active,
fieldset[disabled] .btn-default-light.active {
  background-color: #f2f2f3;
  border-color: #f2f2f3;
}
.btn-default-light .badge {
  color: #f2f2f3;
  background-color: #bdbfc1;
}
.btn-default-light:hover,
.btn-default-light:focus,
.open .dropdown-toggle.btn-default-light {
  color: #313335;
  background-color: #dfe0e1;
  border-color: #dfe0e1;
}
.btn-default-light:active,
.btn-default-light.active {
  color: #313335;
  background-color: #d8d9da;
  border-color: #d8d9da;
}
.btn-default-light.btn-flat,
.btn-default-light.btn-icon-toggle {
  color: #bdbfc1;
}
.open .dropdown-toggle.btn-default-light.btn-flat,
.open .dropdown-toggle.btn-default-light.btn-icon-toggle {
  color: #f2f2f3;
}
.btn-default-dark {
  color: #2B2A65;
  color: #ffffff;
  background-color: #2B2A65;
  border-color: #2B2A65;
}
.btn-default-dark:hover,
.btn-default-dark:focus,
.btn-default-dark.focus,
.btn-default-dark:active,
.btn-default-dark.active,
.open > .dropdown-toggle.btn-default-dark {
  color: #2B2A65;
  background-color: #051e33;
  border-color: #04192a;
}
.btn-default-dark:active,
.btn-default-dark.active,
.open > .dropdown-toggle.btn-default-dark {
  background-image: none;
}
.btn-default-dark.disabled,
.btn-default-dark[disabled],
fieldset[disabled] .btn-default-dark,
.btn-default-dark.disabled:hover,
.btn-default-dark[disabled]:hover,
fieldset[disabled] .btn-default-dark:hover,
.btn-default-dark.disabled:focus,
.btn-default-dark[disabled]:focus,
fieldset[disabled] .btn-default-dark:focus,
.btn-default-dark.disabled.focus,
.btn-default-dark[disabled].focus,
fieldset[disabled] .btn-default-dark.focus,
.btn-default-dark.disabled:active,
.btn-default-dark[disabled]:active,
fieldset[disabled] .btn-default-dark:active,
.btn-default-dark.disabled.active,
.btn-default-dark[disabled].active,
fieldset[disabled] .btn-default-dark.active {
  background-color: #2B2A65;
  border-color: #2B2A65;
}
.btn-default-dark .badge {
  color: #2B2A65;
  background-color: #2B2A65;
}
.btn-default-dark:hover,
.btn-default-dark:focus,
.open .dropdown-toggle.btn-default-dark {
  color: #ffffff;
  background-color: #072741;
  border-color: #072741;
}
.btn-default-dark:active,
.btn-default-dark.active {
  color: #ffffff;
  background-color: #051e33;
  border-color: #051e33;
}
.btn-default-dark.btn-flat,
.btn-default-dark.btn-icon-toggle {
  color: #2B2A65;
}
.open .dropdown-toggle.btn-default-dark.btn-flat,
.open .dropdown-toggle.btn-default-dark.btn-icon-toggle {
  color: #2B2A65;
}
.btn-primary-bright {
  color: #c7dbec;
  color: #313335;
  background-color: #c7dbec;
  border-color: #c7dbec;
}
.btn-primary-bright:hover,
.btn-primary-bright:focus,
.btn-primary-bright.focus,
.btn-primary-bright:active,
.btn-primary-bright.active,
.open > .dropdown-toggle.btn-primary-bright {
  color: #c7dbec;
  background-color: #a0c2e0;
  border-color: #99bddd;
}
.btn-primary-bright:active,
.btn-primary-bright.active,
.open > .dropdown-toggle.btn-primary-bright {
  background-image: none;
}
.btn-primary-bright.disabled,
.btn-primary-bright[disabled],
fieldset[disabled] .btn-primary-bright,
.btn-primary-bright.disabled:hover,
.btn-primary-bright[disabled]:hover,
fieldset[disabled] .btn-primary-bright:hover,
.btn-primary-bright.disabled:focus,
.btn-primary-bright[disabled]:focus,
fieldset[disabled] .btn-primary-bright:focus,
.btn-primary-bright.disabled.focus,
.btn-primary-bright[disabled].focus,
fieldset[disabled] .btn-primary-bright.focus,
.btn-primary-bright.disabled:active,
.btn-primary-bright[disabled]:active,
fieldset[disabled] .btn-primary-bright:active,
.btn-primary-bright.disabled.active,
.btn-primary-bright[disabled].active,
fieldset[disabled] .btn-primary-bright.active {
  background-color: #c7dbec;
  border-color: #c7dbec;
}
.btn-primary-bright .badge {
  color: #c7dbec;
  background-color: #c7dbec;
}
.btn-primary-bright:hover,
.btn-primary-bright:focus,
.open .dropdown-toggle.btn-primary-bright {
  color: #313335;
  background-color: #accae4;
  border-color: #accae4;
}
.btn-primary-bright:active,
.btn-primary-bright.active {
  color: #313335;
  background-color: #a0c2e0;
  border-color: #a0c2e0;
}
.btn-primary-bright.btn-flat,
.btn-primary-bright.btn-icon-toggle {
  color: #c7dbec;
}
.open .dropdown-toggle.btn-primary-bright.btn-flat,
.open .dropdown-toggle.btn-primary-bright.btn-icon-toggle {
  color: #c7dbec;
}
.btn-primary-light {
  color: #78a6ce;
  color: #ffffff;
  background-color: #78a6ce;
  border-color: #78a6ce;
}
.btn-primary-light:hover,
.btn-primary-light:focus,
.btn-primary-light.focus,
.btn-primary-light:active,
.btn-primary-light.active,
.open > .dropdown-toggle.btn-primary-light {
  color: #78a6ce;
  background-color: #528dc1;
  border-color: #4b88be;
}
.btn-primary-light:active,
.btn-primary-light.active,
.open > .dropdown-toggle.btn-primary-light {
  background-image: none;
}
.btn-primary-light.disabled,
.btn-primary-light[disabled],
fieldset[disabled] .btn-primary-light,
.btn-primary-light.disabled:hover,
.btn-primary-light[disabled]:hover,
fieldset[disabled] .btn-primary-light:hover,
.btn-primary-light.disabled:focus,
.btn-primary-light[disabled]:focus,
fieldset[disabled] .btn-primary-light:focus,
.btn-primary-light.disabled.focus,
.btn-primary-light[disabled].focus,
fieldset[disabled] .btn-primary-light.focus,
.btn-primary-light.disabled:active,
.btn-primary-light[disabled]:active,
fieldset[disabled] .btn-primary-light:active,
.btn-primary-light.disabled.active,
.btn-primary-light[disabled].active,
fieldset[disabled] .btn-primary-light.active {
  background-color: #78a6ce;
  border-color: #78a6ce;
}
.btn-primary-light .badge {
  color: #78a6ce;
  background-color: #78a6ce;
}
.btn-primary-light:hover,
.btn-primary-light:focus,
.open .dropdown-toggle.btn-primary-light {
  color: #ffffff;
  background-color: #5d94c5;
  border-color: #5d94c5;
}
.btn-primary-light:active,
.btn-primary-light.active {
  color: #ffffff;
  background-color: #528dc1;
  border-color: #528dc1;
}
.btn-primary-light.btn-flat,
.btn-primary-light.btn-icon-toggle {
  color: #78a6ce;
}
.open .dropdown-toggle.btn-primary-light.btn-flat,
.open .dropdown-toggle.btn-primary-light.btn-icon-toggle {
  color: #78a6ce;
}
.btn-primary-dark {
  color: #164271;
  color: #ffffff;
  background-color: #164271;
  border-color: #164271;
}
.btn-primary-dark:hover,
.btn-primary-dark:focus,
.btn-primary-dark.focus,
.btn-primary-dark:active,
.btn-primary-dark.active,
.open > .dropdown-toggle.btn-primary-dark {
  color: #164271;
  background-color: #0d2946;
  border-color: #0c243d;
}
.btn-primary-dark:active,
.btn-primary-dark.active,
.open > .dropdown-toggle.btn-primary-dark {
  background-image: none;
}
.btn-primary-dark.disabled,
.btn-primary-dark[disabled],
fieldset[disabled] .btn-primary-dark,
.btn-primary-dark.disabled:hover,
.btn-primary-dark[disabled]:hover,
fieldset[disabled] .btn-primary-dark:hover,
.btn-primary-dark.disabled:focus,
.btn-primary-dark[disabled]:focus,
fieldset[disabled] .btn-primary-dark:focus,
.btn-primary-dark.disabled.focus,
.btn-primary-dark[disabled].focus,
fieldset[disabled] .btn-primary-dark.focus,
.btn-primary-dark.disabled:active,
.btn-primary-dark[disabled]:active,
fieldset[disabled] .btn-primary-dark:active,
.btn-primary-dark.disabled.active,
.btn-primary-dark[disabled].active,
fieldset[disabled] .btn-primary-dark.active {
  background-color: #164271;
  border-color: #164271;
}
.btn-primary-dark .badge {
  color: #164271;
  background-color: #164271;
}
.btn-primary-dark:hover,
.btn-primary-dark:focus,
.open .dropdown-toggle.btn-primary-dark {
  color: #ffffff;
  background-color: #103053;
  border-color: #103053;
}
.btn-primary-dark:active,
.btn-primary-dark.active {
  color: #ffffff;
  background-color: #0d2946;
  border-color: #0d2946;
}
.btn-primary-dark.btn-flat,
.btn-primary-dark.btn-icon-toggle {
  color: #164271;
}
.open .dropdown-toggle.btn-primary-dark.btn-flat,
.open .dropdown-toggle.btn-primary-dark.btn-icon-toggle {
  color: #164271;
}
.btn-accent-bright {
  color: #f0f4c3;
  color: #313335;
  background-color: #f0f4c3;
  border-color: #f0f4c3;
}
.btn-accent-bright:hover,
.btn-accent-bright:focus,
.btn-accent-bright.focus,
.btn-accent-bright:active,
.btn-accent-bright.active,
.open > .dropdown-toggle.btn-accent-bright {
  color: #f0f4c3;
  background-color: #e5ec98;
  border-color: #e3eb8f;
}
.btn-accent-bright:active,
.btn-accent-bright.active,
.open > .dropdown-toggle.btn-accent-bright {
  background-image: none;
}
.btn-accent-bright.disabled,
.btn-accent-bright[disabled],
fieldset[disabled] .btn-accent-bright,
.btn-accent-bright.disabled:hover,
.btn-accent-bright[disabled]:hover,
fieldset[disabled] .btn-accent-bright:hover,
.btn-accent-bright.disabled:focus,
.btn-accent-bright[disabled]:focus,
fieldset[disabled] .btn-accent-bright:focus,
.btn-accent-bright.disabled.focus,
.btn-accent-bright[disabled].focus,
fieldset[disabled] .btn-accent-bright.focus,
.btn-accent-bright.disabled:active,
.btn-accent-bright[disabled]:active,
fieldset[disabled] .btn-accent-bright:active,
.btn-accent-bright.disabled.active,
.btn-accent-bright[disabled].active,
fieldset[disabled] .btn-accent-bright.active {
  background-color: #f0f4c3;
  border-color: #f0f4c3;
}
.btn-accent-bright .badge {
  color: #f0f4c3;
  background-color: #f0f4c3;
}
.btn-accent-bright:hover,
.btn-accent-bright:focus,
.open .dropdown-toggle.btn-accent-bright {
  color: #313335;
  background-color: #e8eea5;
  border-color: #e8eea5;
}
.btn-accent-bright:active,
.btn-accent-bright.active {
  color: #313335;
  background-color: #e5ec98;
  border-color: #e5ec98;
}
.btn-accent-bright.btn-flat,
.btn-accent-bright.btn-icon-toggle {
  color: #f0f4c3;
}
.open .dropdown-toggle.btn-accent-bright.btn-flat,
.open .dropdown-toggle.btn-accent-bright.btn-icon-toggle {
  color: #f0f4c3;
}
.btn-accent-light {
  color: #81d4fa;
  color: #313335;
  background-color: #81d4fa;
  border-color: #81d4fa;
}
.btn-accent-light:hover,
.btn-accent-light:focus,
.btn-accent-light.focus,
.btn-accent-light:active,
.btn-accent-light.active,
.open > .dropdown-toggle.btn-accent-light {
  color: #81d4fa;
  background-color: #50c3f8;
  border-color: #46c0f8;
}
.btn-accent-light:active,
.btn-accent-light.active,
.open > .dropdown-toggle.btn-accent-light {
  background-image: none;
}
.btn-accent-light.disabled,
.btn-accent-light[disabled],
fieldset[disabled] .btn-accent-light,
.btn-accent-light.disabled:hover,
.btn-accent-light[disabled]:hover,
fieldset[disabled] .btn-accent-light:hover,
.btn-accent-light.disabled:focus,
.btn-accent-light[disabled]:focus,
fieldset[disabled] .btn-accent-light:focus,
.btn-accent-light.disabled.focus,
.btn-accent-light[disabled].focus,
fieldset[disabled] .btn-accent-light.focus,
.btn-accent-light.disabled:active,
.btn-accent-light[disabled]:active,
fieldset[disabled] .btn-accent-light:active,
.btn-accent-light.disabled.active,
.btn-accent-light[disabled].active,
fieldset[disabled] .btn-accent-light.active {
  background-color: #81d4fa;
  border-color: #81d4fa;
}
.btn-accent-light .badge {
  color: #81d4fa;
  background-color: #81d4fa;
}
.btn-accent-light:hover,
.btn-accent-light:focus,
.open .dropdown-toggle.btn-accent-light {
  color: #ffffff;
  background-color: #5fc8f9;
  border-color: #5fc8f9;
}
.btn-accent-light:active,
.btn-accent-light.active {
  color: #ffffff;
  background-color: #50c3f8;
  border-color: #50c3f8;
}
.btn-accent-light.btn-flat,
.btn-accent-light.btn-icon-toggle {
  color: #81d4fa;
}
.open .dropdown-toggle.btn-accent-light.btn-flat,
.open .dropdown-toggle.btn-accent-light.btn-icon-toggle {
  color: #81d4fa;
}
.btn-accent {
  color: #14BF6E;
  color: #ffffff;
  background-color: #14BF6E;
  border-color: #14BF6E;
}
.btn-accent:hover,
.btn-accent:focus,
.btn-accent.focus,
.btn-accent:active,
.btn-accent.active,
.open > .dropdown-toggle.btn-accent {
  color: #14BF6E;
  background-color: #31b0d5;
  border-color: #2aabd2;
}
.btn-accent:active,
.btn-accent.active,
.open > .dropdown-toggle.btn-accent {
  background-image: none;
}
.btn-accent.disabled,
.btn-accent[disabled],
fieldset[disabled] .btn-accent,
.btn-accent.disabled:hover,
.btn-accent[disabled]:hover,
fieldset[disabled] .btn-accent:hover,
.btn-accent.disabled:focus,
.btn-accent[disabled]:focus,
fieldset[disabled] .btn-accent:focus,
.btn-accent.disabled.focus,
.btn-accent[disabled].focus,
fieldset[disabled] .btn-accent.focus,
.btn-accent.disabled:active,
.btn-accent[disabled]:active,
fieldset[disabled] .btn-accent:active,
.btn-accent.disabled.active,
.btn-accent[disabled].active,
fieldset[disabled] .btn-accent.active {
  background-color: #14BF6E;
  border-color: #14BF6E;
}
.btn-accent .badge {
  color: #14BF6E;
  background-color: #14BF6E;
}
.btn-accent:hover,
.btn-accent:focus,
.open .dropdown-toggle.btn-accent {
  color: #ffffff;
  background-color: #3db5d8;
  border-color: #3db5d8;
}
.btn-accent:active,
.btn-accent.active {
  color: #ffffff;
  background-color: #31b0d5;
  border-color: #31b0d5;
}
.btn-accent.btn-flat,
.btn-accent.btn-icon-toggle {
  color: #14BF6E;
}
.open .dropdown-toggle.btn-accent.btn-flat,
.open .dropdown-toggle.btn-accent.btn-icon-toggle {
  color: #14BF6E;
}
.btn-accent-dark {
  color: #1a237e;
  color: #ffffff;
  background-color: #1a237e;
  border-color: #1a237e;
}
.btn-accent-dark:hover,
.btn-accent-dark:focus,
.btn-accent-dark.focus,
.btn-accent-dark:active,
.btn-accent-dark.active,
.open > .dropdown-toggle.btn-accent-dark {
  color: #1a237e;
  background-color: #111754;
  border-color: #10154b;
}
.btn-accent-dark:active,
.btn-accent-dark.active,
.open > .dropdown-toggle.btn-accent-dark {
  background-image: none;
}
.btn-accent-dark.disabled,
.btn-accent-dark[disabled],
fieldset[disabled] .btn-accent-dark,
.btn-accent-dark.disabled:hover,
.btn-accent-dark[disabled]:hover,
fieldset[disabled] .btn-accent-dark:hover,
.btn-accent-dark.disabled:focus,
.btn-accent-dark[disabled]:focus,
fieldset[disabled] .btn-accent-dark:focus,
.btn-accent-dark.disabled.focus,
.btn-accent-dark[disabled].focus,
fieldset[disabled] .btn-accent-dark.focus,
.btn-accent-dark.disabled:active,
.btn-accent-dark[disabled]:active,
fieldset[disabled] .btn-accent-dark:active,
.btn-accent-dark.disabled.active,
.btn-accent-dark[disabled].active,
fieldset[disabled] .btn-accent-dark.active {
  background-color: #1a237e;
  border-color: #1a237e;
}
.btn-accent-dark .badge {
  color: #1a237e;
  background-color: #1a237e;
}
.btn-accent-dark:hover,
.btn-accent-dark:focus,
.open .dropdown-toggle.btn-accent-dark {
  color: #ffffff;
  background-color: #141b60;
  border-color: #141b60;
}
.btn-accent-dark:active,
.btn-accent-dark.active {
  color: #ffffff;
  background-color: #111754;
  border-color: #111754;
}
.btn-accent-dark.btn-flat,
.btn-accent-dark.btn-icon-toggle {
  color: #1a237e;
}
.open .dropdown-toggle.btn-accent-dark.btn-flat,
.open .dropdown-toggle.btn-accent-dark.btn-icon-toggle {
  color: #1a237e;
}
.btn-gray-bright {
  color: #bdbfc1;
  color: #313335;
  background-color: #bdbfc1;
  border-color: #bdbfc1;
}
.btn-gray-bright:hover,
.btn-gray-bright:focus,
.btn-gray-bright.focus,
.btn-gray-bright:active,
.btn-gray-bright.active,
.open > .dropdown-toggle.btn-gray-bright {
  color: #bdbfc1;
  background-color: #a3a6a8;
  border-color: #9ea1a3;
}
.btn-gray-bright:active,
.btn-gray-bright.active,
.open > .dropdown-toggle.btn-gray-bright {
  background-image: none;
}
.btn-gray-bright.disabled,
.btn-gray-bright[disabled],
fieldset[disabled] .btn-gray-bright,
.btn-gray-bright.disabled:hover,
.btn-gray-bright[disabled]:hover,
fieldset[disabled] .btn-gray-bright:hover,
.btn-gray-bright.disabled:focus,
.btn-gray-bright[disabled]:focus,
fieldset[disabled] .btn-gray-bright:focus,
.btn-gray-bright.disabled.focus,
.btn-gray-bright[disabled].focus,
fieldset[disabled] .btn-gray-bright.focus,
.btn-gray-bright.disabled:active,
.btn-gray-bright[disabled]:active,
fieldset[disabled] .btn-gray-bright:active,
.btn-gray-bright.disabled.active,
.btn-gray-bright[disabled].active,
fieldset[disabled] .btn-gray-bright.active {
  background-color: #bdbfc1;
  border-color: #bdbfc1;
}
.btn-gray-bright .badge {
  color: #bdbfc1;
  background-color: #bdbfc1;
}
.btn-gray-bright:hover,
.btn-gray-bright:focus,
.open .dropdown-toggle.btn-gray-bright {
  color: #ffffff;
  background-color: #abaeb0;
  border-color: #abaeb0;
}
.btn-gray-bright:active,
.btn-gray-bright.active {
  color: #ffffff;
  background-color: #a3a6a8;
  border-color: #a3a6a8;
}
.btn-gray-bright.btn-flat,
.btn-gray-bright.btn-icon-toggle {
  color: #bdbfc1;
}
.open .dropdown-toggle.btn-gray-bright.btn-flat,
.open .dropdown-toggle.btn-gray-bright.btn-icon-toggle {
  color: #bdbfc1;
}
.btn-gray-light {
  color: #96999c;
  color: #ffffff;
  background-color: #96999c;
  border-color: #96999c;
}
.btn-gray-light:hover,
.btn-gray-light:focus,
.btn-gray-light.focus,
.btn-gray-light:active,
.btn-gray-light.active,
.open > .dropdown-toggle.btn-gray-light {
  color: #96999c;
  background-color: #7c8083;
  border-color: #777b7e;
}
.btn-gray-light:active,
.btn-gray-light.active,
.open > .dropdown-toggle.btn-gray-light {
  background-image: none;
}
.btn-gray-light.disabled,
.btn-gray-light[disabled],
fieldset[disabled] .btn-gray-light,
.btn-gray-light.disabled:hover,
.btn-gray-light[disabled]:hover,
fieldset[disabled] .btn-gray-light:hover,
.btn-gray-light.disabled:focus,
.btn-gray-light[disabled]:focus,
fieldset[disabled] .btn-gray-light:focus,
.btn-gray-light.disabled.focus,
.btn-gray-light[disabled].focus,
fieldset[disabled] .btn-gray-light.focus,
.btn-gray-light.disabled:active,
.btn-gray-light[disabled]:active,
fieldset[disabled] .btn-gray-light:active,
.btn-gray-light.disabled.active,
.btn-gray-light[disabled].active,
fieldset[disabled] .btn-gray-light.active {
  background-color: #96999c;
  border-color: #96999c;
}
.btn-gray-light .badge {
  color: #96999c;
  background-color: #96999c;
}
.btn-gray-light:hover,
.btn-gray-light:focus,
.open .dropdown-toggle.btn-gray-light {
  color: #ffffff;
  background-color: #84878b;
  border-color: #84878b;
}
.btn-gray-light:active,
.btn-gray-light.active {
  color: #ffffff;
  background-color: #7c8083;
  border-color: #7c8083;
}
.btn-gray-light.btn-flat,
.btn-gray-light.btn-icon-toggle {
  color: #96999c;
}
.open .dropdown-toggle.btn-gray-light.btn-flat,
.open .dropdown-toggle.btn-gray-light.btn-icon-toggle {
  color: #96999c;
}
.btn-gray {
  color: #535658;
  color: #ffffff;
  background-color: #535658;
  border-color: #535658;
}
.btn-gray:hover,
.btn-gray:focus,
.btn-gray.focus,
.btn-gray:active,
.btn-gray.active,
.open > .dropdown-toggle.btn-gray {
  color: #535658;
  background-color: #3a3c3e;
  border-color: #353738;
}
.btn-gray:active,
.btn-gray.active,
.open > .dropdown-toggle.btn-gray {
  background-image: none;
}
.btn-gray.disabled,
.btn-gray[disabled],
fieldset[disabled] .btn-gray,
.btn-gray.disabled:hover,
.btn-gray[disabled]:hover,
fieldset[disabled] .btn-gray:hover,
.btn-gray.disabled:focus,
.btn-gray[disabled]:focus,
fieldset[disabled] .btn-gray:focus,
.btn-gray.disabled.focus,
.btn-gray[disabled].focus,
fieldset[disabled] .btn-gray.focus,
.btn-gray.disabled:active,
.btn-gray[disabled]:active,
fieldset[disabled] .btn-gray:active,
.btn-gray.disabled.active,
.btn-gray[disabled].active,
fieldset[disabled] .btn-gray.active {
  background-color: #535658;
  border-color: #535658;
}
.btn-gray .badge {
  color: #535658;
  background-color: #535658;
}
.btn-gray:hover,
.btn-gray:focus,
.open .dropdown-toggle.btn-gray {
  color: #ffffff;
  background-color: #424446;
  border-color: #424446;
}
.btn-gray:active,
.btn-gray.active {
  color: #ffffff;
  background-color: #3a3c3e;
  border-color: #3a3c3e;
}
.btn-gray.btn-flat,
.btn-gray.btn-icon-toggle {
  color: #535658;
}
.open .dropdown-toggle.btn-gray.btn-flat,
.open .dropdown-toggle.btn-gray.btn-icon-toggle {
  color: #535658;
}
.btn-gray-dark {
  color: #313335;
  color: #ffffff;
  background-color: #313335;
  border-color: #313335;
}
.btn-gray-dark:hover,
.btn-gray-dark:focus,
.btn-gray-dark.focus,
.btn-gray-dark:active,
.btn-gray-dark.active,
.open > .dropdown-toggle.btn-gray-dark {
  color: #313335;
  background-color: #191a1a;
  border-color: #141415;
}
.btn-gray-dark:active,
.btn-gray-dark.active,
.open > .dropdown-toggle.btn-gray-dark {
  background-image: none;
}
.btn-gray-dark.disabled,
.btn-gray-dark[disabled],
fieldset[disabled] .btn-gray-dark,
.btn-gray-dark.disabled:hover,
.btn-gray-dark[disabled]:hover,
fieldset[disabled] .btn-gray-dark:hover,
.btn-gray-dark.disabled:focus,
.btn-gray-dark[disabled]:focus,
fieldset[disabled] .btn-gray-dark:focus,
.btn-gray-dark.disabled.focus,
.btn-gray-dark[disabled].focus,
fieldset[disabled] .btn-gray-dark.focus,
.btn-gray-dark.disabled:active,
.btn-gray-dark[disabled]:active,
fieldset[disabled] .btn-gray-dark:active,
.btn-gray-dark.disabled.active,
.btn-gray-dark[disabled].active,
fieldset[disabled] .btn-gray-dark.active {
  background-color: #313335;
  border-color: #313335;
}
.btn-gray-dark .badge {
  color: #313335;
  background-color: #313335;
}
.btn-gray-dark:hover,
.btn-gray-dark:focus,
.open .dropdown-toggle.btn-gray-dark {
  color: #ffffff;
  background-color: #202122;
  border-color: #202122;
}
.btn-gray-dark:active,
.btn-gray-dark.active {
  color: #ffffff;
  background-color: #191a1a;
  border-color: #191a1a;
}
.btn-gray-dark.btn-flat,
.btn-gray-dark.btn-icon-toggle {
  color: #313335;
}
.open .dropdown-toggle.btn-gray-dark.btn-flat,
.open .dropdown-toggle.btn-gray-dark.btn-icon-toggle {
  color: #313335;
}
.btn {
  font-size: 14px;
}
.btn:active,
.btn.active {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0;
}
.btn:focus,
.btn:active:focus,
.btn.active:focus {
  outline: 0;
}
.btn .pull-right,
.btn .pull-left {
  line-height: 1.5em;
}
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  border-color: transparent;
  background: rgba(0, 0, 0, 0.15);
  color: #313335;
  opacity: 0.4;
  filter: alpha(opacity=40);
}
.ink-reaction {
  position: relative;
  overflow: hidden;
}
.ink-reaction .ink {
  position: absolute;
  width: 200px;
  height: 200px;
  margin-left: -100px;
  margin-top: -100px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  z-index: 1;
  pointer-events: none;
  opacity: 0;
  -webkit-animation: ripple-animation 1.5s;
  animation: ripple-animation 1.5s;
}
.ink-reaction .ink.inverse {
  background-color: rgba(12, 12, 12, 0.5);
}
.ink-reaction.btn-icon-toggle,
.ink-reaction.btn-floating-action {
  overflow: visible;
}
.ink-reaction.btn-icon-toggle .ink,
.ink-reaction.btn-floating-action .ink {
  width: 100px;
  height: 100px;
  margin-left: -50px;
  margin-top: -50px;
}
.ink-reaction.btn-icon-toggle .ink,
.ink-reaction.btn-flat .ink {
  background-color: rgba(255, 255, 255, 0.25);
}
.ink-reaction.btn-icon-toggle .ink.inverse,
.ink-reaction.btn-flat .ink.inverse {
  background-color: rgba(12, 12, 12, 0.25);
}
@-webkit-keyframes ripple-animation {
  from {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 0;
  }
}
@keyframes ripple-animation {
  from {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 0;
  }
}
.btn-raised {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
  -webkit-transition: -webkit-box-shadow 0.15s ease-out;
  -moz-transition: -moz-box-shadow 0.15s ease-out;
  -o-transition: -o-box-shadow 0.15s ease-out;
  transition: box-shadow 0.15s ease-out;
}
.btn-raised:hover {
  -webkit-box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.38);
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.38);
}
.btn-raised:active,
.btn-raised.active {
  -webkit-box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.38);
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.38);
}
.btn-raised.disabled,
.btn-raised[disabled],
.pager .disabled .btn-raised {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-flat,
.btn-icon-toggle {
  background-color: transparent;
  font-weight: 500;
  border-color: transparent;
}
.btn-flat:hover,
.btn-icon-toggle:hover,
.btn-flat:focus,
.btn-icon-toggle:focus,
.open .dropdown-toggle.btn-flat,
.open .dropdown-toggle.btn-icon-toggle {
  background-color: rgba(12, 12, 12, 0.07);
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: inherit;
}
.btn-flat:active,
.btn-icon-toggle:active,
.btn-flat.active,
.btn-icon-toggle.active {
  background-color: rgba(12, 12, 12, 0.25);
  border-color: transparent;
  color: inherit;
}
.btn-flat.disabled,
.btn-icon-toggle.disabled,
.btn-flat[disabled],
.btn-icon-toggle[disabled],
fieldset[disabled] .btn-flat,
fieldset[disabled] .btn-icon-toggle {
  background: transparent;
  opacity: 0.3;
  filter: alpha(opacity=30);
}
.btn-icon-toggle {
  border-radius: 999px !important;
}
.btn-rounded {
  border-radius: 999px;
}
.btn-rounded .label {
  top: 0;
  right: 3px;
}
.btn-plane {
  display: table;
  width: 100%;
  height: 100%;
  padding-left: 0;
  padding-right: 0;
}
.btn-plane > div {
  display: table-cell;
  vertical-align: middle;
}
.btn-labeled {
  position: relative;
}
.btn-labeled .label {
  position: absolute;
  top: -6px;
  right: -6px;
}
.btn-rounded.btn-labeled .label {
  top: 7px;
  right: auto;
  left: 99%;
  margin: auto;
}
.btn-icon-toggle {
  padding: 0;
  width: 36px;
}
.btn-icon-toggle .fa,
.btn-icon-toggle .md,
.btn-icon-toggle .glyphicon {
  margin: 0;
  line-height: 34px;
}
.btn-icon-toggle.btn-lg {
  width: 46px;
}
.btn-icon-toggle.btn-lg .fa,
.btn-icon-toggle.btn-lg .md,
.btn-icon-toggle.btn-lg .glyphicon {
  line-height: 44px;
}
.btn-icon-toggle.btn-sm {
  width: 32px;
}
.btn-icon-toggle.btn-sm .fa,
.btn-icon-toggle.btn-sm .md,
.btn-icon-toggle.btn-sm .glyphicon {
  line-height: 30px;
}
.btn-icon-toggle.btn-xs {
  width: 24px;
}
.btn-icon-toggle.btn-xs .fa,
.btn-icon-toggle.btn-xs .md,
.btn-icon-toggle.btn-xs .glyphicon {
  line-height: 22px;
}
.btn-group-xs > .btn.btn-icon-toggle {
  width: 22px;
  padding: 0;
}
.btn-group-sm > .btn.btn-icon-toggle {
  width: 30px;
  padding: 0;
}
.btn-group-lg > .btn.btn-icon-toggle {
  width: 44px;
  padding: 0;
}
.checkbox-styled:not(ie8),
.radio-styled:not(ie8) {
  position: relative;
  margin-top: 0;
}
.checkbox-styled:not(ie8) input ~ span,
.radio-styled:not(ie8) input ~ span {
  position: relative;
  padding-left: 20px;
  display: inline-block;
  cursor: pointer;
  vertical-align: text-top;
}
.checkbox-styled:not(ie8) input ~ span:before,
.radio-styled:not(ie8) input ~ span:before {
  content: "";
  position: absolute;
  left: 0;
  z-index: 1;
  width: 16px;
  height: 16px;
  border: 2px solid #535658;
}
.checkbox-styled:not(ie8) input[type="radio"],
.radio-styled:not(ie8) input[type="radio"],
.checkbox-styled:not(ie8) input[type="checkbox"],
.radio-styled:not(ie8) input[type="checkbox"] {
  position: absolute;
  z-index: -1;
  opacity: 0;
  filter: alpha(opacity=0);
}
.checkbox-styled:not(ie8) label,
.radio-styled:not(ie8) label {
  padding-left: 0;
}
.checkbox-styled:not(ie8).radio-inline,
.radio-styled:not(ie8).radio-inline,
.checkbox-styled:not(ie8).checkbox-inline,
.radio-styled:not(ie8).checkbox-inline {
  padding-left: 0;
}
.checkbox-styled:not(ie8).disabled ~ span:before,
.radio-styled:not(ie8).disabled ~ span:before,
.checkbox-styled:not(ie8) input[disabled] ~ span:before,
.radio-styled:not(ie8) input[disabled] ~ span:before,
.checkbox-styled:not(ie8) input[readonly] ~ span:before,
.radio-styled:not(ie8) input[readonly] ~ span:before {
  border-color: #e5e6e6;
}
.checkbox-styled:not(ie8).disabled:checked ~ span:before,
.radio-styled:not(ie8).disabled:checked ~ span:before,
.checkbox-styled:not(ie8) input[disabled]:checked ~ span:before,
.radio-styled:not(ie8) input[disabled]:checked ~ span:before,
.checkbox-styled:not(ie8) input[readonly]:checked ~ span:before,
.radio-styled:not(ie8) input[readonly]:checked ~ span:before {
  border-color: #e5e6e6;
}
.checkbox-styled:not(ie8).disabled:checked ~ span:after,
.radio-styled:not(ie8).disabled:checked ~ span:after,
.checkbox-styled:not(ie8) input[disabled]:checked ~ span:after,
.radio-styled:not(ie8) input[disabled]:checked ~ span:after,
.checkbox-styled:not(ie8) input[readonly]:checked ~ span:after,
.radio-styled:not(ie8) input[readonly]:checked ~ span:after {
  background-color: #e5e6e6;
}
.checkbox-styled:not(ie8) input ~ span:before {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.checkbox-styled:not(ie8) input:checked ~ span:before {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  height: 10px;
  border-color: #2267a2;
  border-top-style: none;
  border-right-style: none;
}
.radio-styled:not(ie8) input ~ span:before {
  top: 0;
  border-radius: 50%;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.radio-styled:not(ie8) input ~ span {
  display: inline;
}
.radio-styled:not(ie8) input:checked ~ span:before {
  border-color: #2267a2;
}
.radio-styled:not(ie8) input ~ span:after {
  content: "";
  position: absolute;
  left: 8px;
  top: 8px;
  z-index: 1;
  width: 0;
  height: 0;
  background-color: #2267a2;
  border-radius: 50%;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.radio-styled:not(ie8) input:checked ~ span:after {
  left: 4px;
  top: 4px;
  width: 8px;
  height: 8px;
}
.form-inverse .checkbox-styled:not(ie8) input ~ span:before,
.form-inverse .radio-styled:not(ie8) input ~ span:before {
  border-color: rgba(255, 255, 255, 0.8);
}
.form-inverse .checkbox-styled:not(ie8) input:checked ~ span:before,
.form-inverse .radio-styled:not(ie8) input:checked ~ span:before {
  border-color: #2267a2;
}
.form-inverse .checkbox-styled:not(ie8).disabled ~ span:before,
.form-inverse .radio-styled:not(ie8).disabled ~ span:before,
.form-inverse .checkbox-styled:not(ie8) input[disabled] ~ span:before,
.form-inverse .radio-styled:not(ie8) input[disabled] ~ span:before,
.form-inverse .checkbox-styled:not(ie8) input[readonly] ~ span:before,
.form-inverse .radio-styled:not(ie8) input[readonly] ~ span:before,
.form-inverse .checkbox-styled:not(ie8).disabled:checked ~ span:before,
.form-inverse .radio-styled:not(ie8).disabled:checked ~ span:before,
.form-inverse .checkbox-styled:not(ie8) input[disabled]:checked ~ span:before,
.form-inverse .radio-styled:not(ie8) input[disabled]:checked ~ span:before,
.form-inverse .checkbox-styled:not(ie8) input[readonly]:checked ~ span:before,
.form-inverse .radio-styled:not(ie8) input[readonly]:checked ~ span:before {
  border-color: rgba(255, 255, 255, 0.2);
}
.form-inverse .checkbox-styled:not(ie8).disabled:checked ~ span:after,
.form-inverse .radio-styled:not(ie8).disabled:checked ~ span:after,
.form-inverse .checkbox-styled:not(ie8) input[disabled]:checked ~ span:after,
.form-inverse .radio-styled:not(ie8) input[disabled]:checked ~ span:after,
.form-inverse .checkbox-styled:not(ie8) input[readonly]:checked ~ span:after,
.form-inverse .radio-styled:not(ie8) input[readonly]:checked ~ span:after {
  background-color: rgba(255, 255, 255, 0.2);
}
.checkbox-styled:not(ie8).checkbox-default-bright input:checked ~ span:before,
.radio-styled:not(ie8).checkbox-default-bright input:checked ~ span:before,
.checkbox-styled:not(ie8).radio-default-bright input:checked ~ span:before,
.radio-styled:not(ie8).radio-default-bright input:checked ~ span:before {
  border-color: #ffffff;
}
.checkbox-styled:not(ie8).checkbox-default-bright input ~ span:after,
.radio-styled:not(ie8).checkbox-default-bright input ~ span:after,
.checkbox-styled:not(ie8).radio-default-bright input ~ span:after,
.radio-styled:not(ie8).radio-default-bright input ~ span:after {
  background-color: #ffffff;
}
.checkbox-styled:not(ie8).checkbox-default-light input:checked ~ span:before,
.radio-styled:not(ie8).checkbox-default-light input:checked ~ span:before,
.checkbox-styled:not(ie8).radio-default-light input:checked ~ span:before,
.radio-styled:not(ie8).radio-default-light input:checked ~ span:before {
  border-color: #f2f2f3;
}
.checkbox-styled:not(ie8).checkbox-default-light input ~ span:after,
.radio-styled:not(ie8).checkbox-default-light input ~ span:after,
.checkbox-styled:not(ie8).radio-default-light input ~ span:after,
.radio-styled:not(ie8).radio-default-light input ~ span:after {
  background-color: #f2f2f3;
}
.checkbox-styled:not(ie8).checkbox-default input:checked ~ span:before,
.radio-styled:not(ie8).checkbox-default input:checked ~ span:before,
.checkbox-styled:not(ie8).radio-default input:checked ~ span:before,
.radio-styled:not(ie8).radio-default input:checked ~ span:before {
  border-color: #e5e6e6;
}
.checkbox-styled:not(ie8).checkbox-default input ~ span:after,
.radio-styled:not(ie8).checkbox-default input ~ span:after,
.checkbox-styled:not(ie8).radio-default input ~ span:after,
.radio-styled:not(ie8).radio-default input ~ span:after {
  background-color: #e5e6e6;
}
.checkbox-styled:not(ie8).checkbox-default-dark input:checked ~ span:before,
.radio-styled:not(ie8).checkbox-default-dark input:checked ~ span:before,
.checkbox-styled:not(ie8).radio-default-dark input:checked ~ span:before,
.radio-styled:not(ie8).radio-default-dark input:checked ~ span:before {
  border-color: #2B2A65;
}
.checkbox-styled:not(ie8).checkbox-default-dark input ~ span:after,
.radio-styled:not(ie8).checkbox-default-dark input ~ span:after,
.checkbox-styled:not(ie8).radio-default-dark input ~ span:after,
.radio-styled:not(ie8).radio-default-dark input ~ span:after {
  background-color: #2B2A65;
}
.checkbox-styled:not(ie8).checkbox-primary-bright input:checked ~ span:before,
.radio-styled:not(ie8).checkbox-primary-bright input:checked ~ span:before,
.checkbox-styled:not(ie8).radio-primary-bright input:checked ~ span:before,
.radio-styled:not(ie8).radio-primary-bright input:checked ~ span:before {
  border-color: #c7dbec;
}
.checkbox-styled:not(ie8).checkbox-primary-bright input ~ span:after,
.radio-styled:not(ie8).checkbox-primary-bright input ~ span:after,
.checkbox-styled:not(ie8).radio-primary-bright input ~ span:after,
.radio-styled:not(ie8).radio-primary-bright input ~ span:after {
  background-color: #c7dbec;
}
.checkbox-styled:not(ie8).checkbox-primary-light input:checked ~ span:before,
.radio-styled:not(ie8).checkbox-primary-light input:checked ~ span:before,
.checkbox-styled:not(ie8).radio-primary-light input:checked ~ span:before,
.radio-styled:not(ie8).radio-primary-light input:checked ~ span:before {
  border-color: #78a6ce;
}
.checkbox-styled:not(ie8).checkbox-primary-light input ~ span:after,
.radio-styled:not(ie8).checkbox-primary-light input ~ span:after,
.checkbox-styled:not(ie8).radio-primary-light input ~ span:after,
.radio-styled:not(ie8).radio-primary-light input ~ span:after {
  background-color: #78a6ce;
}
.checkbox-styled:not(ie8).checkbox-primary input:checked ~ span:before,
.radio-styled:not(ie8).checkbox-primary input:checked ~ span:before,
.checkbox-styled:not(ie8).radio-primary input:checked ~ span:before,
.radio-styled:not(ie8).radio-primary input:checked ~ span:before {
  border-color: #2267a2;
}
.checkbox-styled:not(ie8).checkbox-primary input ~ span:after,
.radio-styled:not(ie8).checkbox-primary input ~ span:after,
.checkbox-styled:not(ie8).radio-primary input ~ span:after,
.radio-styled:not(ie8).radio-primary input ~ span:after {
  background-color: #2267a2;
}
.checkbox-styled:not(ie8).checkbox-primary-dark input:checked ~ span:before,
.radio-styled:not(ie8).checkbox-primary-dark input:checked ~ span:before,
.checkbox-styled:not(ie8).radio-primary-dark input:checked ~ span:before,
.radio-styled:not(ie8).radio-primary-dark input:checked ~ span:before {
  border-color: #164271;
}
.checkbox-styled:not(ie8).checkbox-primary-dark input ~ span:after,
.radio-styled:not(ie8).checkbox-primary-dark input ~ span:after,
.checkbox-styled:not(ie8).radio-primary-dark input ~ span:after,
.radio-styled:not(ie8).radio-primary-dark input ~ span:after {
  background-color: #164271;
}
.checkbox-styled:not(ie8).checkbox-accent-bright input:checked ~ span:before,
.radio-styled:not(ie8).checkbox-accent-bright input:checked ~ span:before,
.checkbox-styled:not(ie8).radio-accent-bright input:checked ~ span:before,
.radio-styled:not(ie8).radio-accent-bright input:checked ~ span:before {
  border-color: #f0f4c3;
}
.checkbox-styled:not(ie8).checkbox-accent-bright input ~ span:after,
.radio-styled:not(ie8).checkbox-accent-bright input ~ span:after,
.checkbox-styled:not(ie8).radio-accent-bright input ~ span:after,
.radio-styled:not(ie8).radio-accent-bright input ~ span:after {
  background-color: #f0f4c3;
}
.checkbox-styled:not(ie8).checkbox-accent-light input:checked ~ span:before,
.radio-styled:not(ie8).checkbox-accent-light input:checked ~ span:before,
.checkbox-styled:not(ie8).radio-accent-light input:checked ~ span:before,
.radio-styled:not(ie8).radio-accent-light input:checked ~ span:before {
  border-color: #81d4fa;
}
.checkbox-styled:not(ie8).checkbox-accent-light input ~ span:after,
.radio-styled:not(ie8).checkbox-accent-light input ~ span:after,
.checkbox-styled:not(ie8).radio-accent-light input ~ span:after,
.radio-styled:not(ie8).radio-accent-light input ~ span:after {
  background-color: #81d4fa;
}
.checkbox-styled:not(ie8).checkbox-accent input:checked ~ span:before,
.radio-styled:not(ie8).checkbox-accent input:checked ~ span:before,
.checkbox-styled:not(ie8).radio-accent input:checked ~ span:before,
.radio-styled:not(ie8).radio-accent input:checked ~ span:before {
  border-color: #14BF6E;
}
.checkbox-styled:not(ie8).checkbox-accent input ~ span:after,
.radio-styled:not(ie8).checkbox-accent input ~ span:after,
.checkbox-styled:not(ie8).radio-accent input ~ span:after,
.radio-styled:not(ie8).radio-accent input ~ span:after {
  background-color: #14BF6E;
}
.checkbox-styled:not(ie8).checkbox-accent-dark input:checked ~ span:before,
.radio-styled:not(ie8).checkbox-accent-dark input:checked ~ span:before,
.checkbox-styled:not(ie8).radio-accent-dark input:checked ~ span:before,
.radio-styled:not(ie8).radio-accent-dark input:checked ~ span:before {
  border-color: #1a237e;
}
.checkbox-styled:not(ie8).checkbox-accent-dark input ~ span:after,
.radio-styled:not(ie8).checkbox-accent-dark input ~ span:after,
.checkbox-styled:not(ie8).radio-accent-dark input ~ span:after,
.radio-styled:not(ie8).radio-accent-dark input ~ span:after {
  background-color: #1a237e;
}
.checkbox-styled:not(ie8).checkbox-success input:checked ~ span:before,
.radio-styled:not(ie8).checkbox-success input:checked ~ span:before,
.checkbox-styled:not(ie8).radio-success input:checked ~ span:before,
.radio-styled:not(ie8).radio-success input:checked ~ span:before {
  border-color: #4caf50;
}
.checkbox-styled:not(ie8).checkbox-success input ~ span:after,
.radio-styled:not(ie8).checkbox-success input ~ span:after,
.checkbox-styled:not(ie8).radio-success input ~ span:after,
.radio-styled:not(ie8).radio-success input ~ span:after {
  background-color: #4caf50;
}
.checkbox-styled:not(ie8).checkbox-warning input:checked ~ span:before,
.radio-styled:not(ie8).checkbox-warning input:checked ~ span:before,
.checkbox-styled:not(ie8).radio-warning input:checked ~ span:before,
.radio-styled:not(ie8).radio-warning input:checked ~ span:before {
  border-color: #ff9800;
}
.checkbox-styled:not(ie8).checkbox-warning input ~ span:after,
.radio-styled:not(ie8).checkbox-warning input ~ span:after,
.checkbox-styled:not(ie8).radio-warning input ~ span:after,
.radio-styled:not(ie8).radio-warning input ~ span:after {
  background-color: #ff9800;
}
.checkbox-styled:not(ie8).checkbox-danger input:checked ~ span:before,
.radio-styled:not(ie8).checkbox-danger input:checked ~ span:before,
.checkbox-styled:not(ie8).radio-danger input:checked ~ span:before,
.radio-styled:not(ie8).radio-danger input:checked ~ span:before {
  border-color: #f44336;
}
.checkbox-styled:not(ie8).checkbox-danger input ~ span:after,
.radio-styled:not(ie8).checkbox-danger input ~ span:after,
.checkbox-styled:not(ie8).radio-danger input ~ span:after,
.radio-styled:not(ie8).radio-danger input ~ span:after {
  background-color: #f44336;
}
.checkbox-styled:not(ie8).checkbox-info input:checked ~ span:before,
.radio-styled:not(ie8).checkbox-info input:checked ~ span:before,
.checkbox-styled:not(ie8).radio-info input:checked ~ span:before,
.radio-styled:not(ie8).radio-info input:checked ~ span:before {
  border-color: #2196f3;
}
.checkbox-styled:not(ie8).checkbox-info input ~ span:after,
.radio-styled:not(ie8).checkbox-info input ~ span:after,
.checkbox-styled:not(ie8).radio-info input ~ span:after,
.radio-styled:not(ie8).radio-info input ~ span:after {
  background-color: #2196f3;
}
.btn-floating-action {
  width: 44px;
  height: 44px;
  border-radius: 999px !important;
  padding: 0;
  font-size: 17px;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
  -webkit-transition: -webkit-box-shadow 0.15s ease-out;
  -moz-transition: -moz-box-shadow 0.15s ease-out;
  -o-transition: -o-box-shadow 0.15s ease-out;
  transition: box-shadow 0.15s ease-out;
}
.btn-floating-action .fa,
.btn-floating-action .md,
.btn-floating-action .glyphicon {
  top: 0;
  margin: 0;
  line-height: 42px;
}
.btn-floating-action.btn-lg {
  width: 56px;
  height: 56px;
}
.btn-floating-action.btn-lg .fa,
.btn-floating-action.btn-lg .md,
.btn-floating-action.btn-lg .glyphicon {
  line-height: 54px;
  font-size: 1.3em;
}
.btn-floating-action.btn-lg .md {
  font-size: 1.5em;
}
.btn-floating-action.btn-sm {
  width: 36px;
  height: 36px;
}
.btn-floating-action.btn-sm .fa,
.btn-floating-action.btn-sm .md,
.btn-floating-action.btn-sm .glyphicon {
  line-height: 34px;
}
.btn-floating-action.btn-xs {
  width: 28px;
  height: 28px;
}
.btn-floating-action.btn-xs .fa,
.btn-floating-action.btn-xs .md,
.btn-floating-action.btn-xs .glyphicon {
  line-height: 26px;
}
.btn-floating-action:hover {
  -webkit-box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.38);
  box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.38);
}
.btn-floating-action:active,
.btn-floating-action.active {
  -webkit-box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.38);
  box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.38);
}
.btn-group-xs > .btn.btn-floating-action {
  width: 22px;
  padding: 0;
}
.btn-group-sm > .btn.btn-floating-action,
.input-group-sm .btn.btn-floating-action {
  width: 30px;
  padding: 0;
}
.btn-group-lg > .btn.btn-floating-action,
.input-group-lg .btn.btn-floating-action {
  width: 44px;
  padding: 0;
}
.btn-group-vertical.full-width {
  width: 100%;
}
.btn-group-vertical.full-width .btn,
.btn-group-vertical.full-width .btn-group {
  width: 100%;
}
.link-default {
  text-decoration: none;
  font-weight: 500;
  color: inherit;
}
.link-default:hover {
  opacity: 1;
}
.link-default .fa,
.link-default .md,
.link-default .glyphicon {
  margin-right: 12px;
}
.btn-lg {
  font-size: 18px;
}
.btn-sm {
  font-size: 12px;
}
.btn-xs {
  font-size: 12px;
}
.fa.icon-tilt,
.md.icon-tilt,
.glyphicon.icon-tilt,
.icon-tilt {
  -webkit-transform: rotate(10deg);
  -ms-transform: rotate(10deg);
  -o-transform: rotate(10deg);
  transform: rotate(10deg);
}
.fa.icon-tilt-alt,
.md.icon-tilt-alt,
.glyphicon.icon-tilt-alt,
.icon-tilt-alt {
  -webkit-transform: rotate(350deg);
  -ms-transform: rotate(350deg);
  -o-transform: rotate(350deg);
  transform: rotate(350deg);
}
.btn + .dropdown-toggle .fa,
.btn + .dropdown-toggle .md {
  margin: 0;
}
.btn .fa + .fa,
.btn .md + .md {
  margin-right: 0;
  margin-left: 6px;
}
.btn-icon-toggle .fa,
.btn-icon-toggle .md,
.btn-icon-toggle .glyphicon {
  margin: 0;
}
.nav a + .dropdown-toggle .fa,
.nav a + .dropdown-toggle .md {
  margin: 0;
}
.nav a .fa + .fa,
.nav a .md + .md {
  margin-right: 0;
  margin-left: 6px;
}
/*
body [class*=" md-"], [class^=md-] {
  font-size: 1.2em;
  margin-top: -0.2em;
  margin-bottom: -0.2em;
}*/
.dropdown-menu {
  margin: 0;
  border-color: rgba(255, 255, 255, 0.9);
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
}
.dropdown-menu > li > a {
  padding: 5px 20px;
}
.dropdown-menu .alert {
  min-width: 250px;
  margin: 10px;
  padding: 10px;
}
.dropdown-menu .alert:before,
.dropdown-menu .alert:after {
  content: " ";
  display: table;
}
.dropdown-menu .alert:after {
  clear: both;
}
.dropdown-menu img.pull-right {
  margin-left: 10px;
  margin-bottom: 2px;
}
.dropdown-menu img.pull-left {
  margin-right: 10px;
  margin-bottom: 2px;
}
.dropdown-header {
  padding: 15px 20px 5px 20px;
  font-size: 10px;
  font-weight: 500;
  opacity: .6;
}
.dropdown-avatar {
  max-width: 40px;
}
li.dropdown-progress {
  min-width: 250px;
}
li.dropdown-progress > a {
  padding: 15px 0px;
}
li.dropdown-progress .dropdown-label {
  padding: 5px 20px;
}
li.dropdown-progress .progress {
  margin-bottom: 0;
  height: 5px;
}
.dropdown-label:before,
.dropdown-label:after {
  content: " ";
  display: table;
}
.dropdown-label:after {
  clear: both;
}
.dropup .dropdown-menu {
  margin-bottom: 0;
}
.open > .dropdown-menu.animation-zoom {
  -webkit-animation: zoom-down 0.25s ease-out;
  -moz-animation: zoom-down 0.25s ease-out;
  -ms-animation: zoom-down 0.25s ease-out;
  -o-animation: zoom-down 0.25s ease-out;
  animation: zoom-down 0.25s ease-out;
}
.dropup.open > .dropdown-menu.animation-zoom {
  -webkit-animation: zoom-up 0.25s ease-out;
  -moz-animation: zoom-up 0.25s ease-out;
  -ms-animation: zoom-up 0.25s ease-out;
  -o-animation: zoom-up 0.25s ease-out;
  animation: zoom-up 0.25s ease-out;
}
.open > .dropdown-menu.animation-slide {
  -webkit-animation: slide-right 0.3s ease-out;
  -moz-animation: slide-right 0.3s ease-out;
  -ms-animation: slide-right 0.3s ease-out;
  -o-animation: slide-right 0.3s ease-out;
  animation: slide-right 0.3s ease-out;
}
.open > .dropdown-menu.animation-dock {
  -webkit-animation: slide-up 0.3s ease-out;
  -moz-animation: slide-up 0.3s ease-out;
  -ms-animation: slide-up 0.3s ease-out;
  -o-animation: slide-up 0.3s ease-out;
  animation: slide-up 0.3s ease-out;
}
.dropup.open > .dropdown-menu.animation-dock {
  -webkit-animation: slide-down 0.3s ease-out;
  -moz-animation: slide-down 0.3s ease-out;
  -ms-animation: slide-down 0.3s ease-out;
  -o-animation: slide-down 0.3s ease-out;
  animation: slide-down 0.3s ease-out;
}
.open > .dropdown-menu.animation-expand {
  -webkit-animation: slide-down 0.3s ease-out;
  -moz-animation: slide-down 0.3s ease-out;
  -ms-animation: slide-down 0.3s ease-out;
  -o-animation: slide-down 0.3s ease-out;
  animation: slide-down 0.3s ease-out;
}
.dropup.open > .dropdown-menu.animation-expand {
  -webkit-animation: slide-up 0.3s ease-out;
  -moz-animation: slide-up 0.3s ease-out;
  -ms-animation: slide-up 0.3s ease-out;
  -o-animation: slide-up 0.3s ease-out;
  animation: slide-up 0.3s ease-out;
}
.nav-tabs > li > a {
  margin-right: 2px;
  line-height: 1.84615385;
  border: none;
  border-bottom: 2px solid transparent;
  border-radius: none;
  opacity: 0.55;
  font-weight: 500;
  text-transform: uppercase;
}
.nav-tabs > li > a:hover {
  border-color: #e5e6e6 #e5e6e6 #dddddd;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  border-bottom: 2px solid #2267a2;
  color: #2267a2;
  cursor: default;
  opacity: 1;
}
.card-head.nav-tabs,
.card-head > .nav-tabs {
  padding: 0 24px;
}
.card-head.nav-tabs > li > a,
.card-head > .nav-tabs > li > a {
  line-height: 54px;
  padding-top: 0;
  padding-bottom: 0;
}
.card-head.card-head-xs.nav-tabs > li > a,
.card-head.card-head-xs > .nav-tabs > li > a {
  line-height: 34px;
}
.card-head.card-head-sm.nav-tabs > li > a,
.card-head.card-head-sm > .nav-tabs > li > a {
  line-height: 46px;
}
.card-head.card-head-lg.nav-tabs > li > a,
.card-head.card-head-lg > .nav-tabs > li > a {
  line-height: 78px;
}
.nav-tabs.nav-justified {
  padding: 0;
}
.nav-tabs.nav-justified > .active > a,
.nav-tabs.nav-justified > .active > a:hover,
.nav-tabs.nav-justified > .active > a:focus {
  border: none;
  border-bottom: 2px solid #2267a2;
}
.nav-tabs.tabs-center > li {
  float: none;
  display: inline-block;
}
.nav-tabs.tabs-center {
  text-align: center;
}
.tabs-text-contrast {
  border-bottom-color: rgba(255, 255, 255, 0.15);
}
.tabs-text-contrast > li > a:hover,
.tabs-text-contrast > li > a:focus {
  background-color: rgba(255, 255, 255, 0.35);
}
.tabs-text-contrast > li.active > a,
.tabs-text-contrast > li.active > a:hover,
.tabs-text-contrast > li.active > a:focus {
  color: inherit !important;
}
.card-underline .card-head .nav-tabs {
  border-bottom: none;
}
.nav-tabs a small {
  color: inherit;
  text-transform: initial;
}
.tabs-below > .nav-tabs,
.tabs-right > .nav-tabs,
.tabs-left > .nav-tabs {
  border: none;
}
.tab-content > .tab-pane,
.pill-content > .pill-pane {
  display: none;
}
.tab-content > .active,
.pill-content > .active {
  display: block;
}
.tabs-below > .nav-tabs {
  border-top: 1px solid #dddddd;
}
.tabs-below > .nav-tabs > li {
  margin-bottom: 0;
}
.tabs-below > .nav-tabs > li > a {
  border-bottom: none;
}
.tabs-below > .nav-tabs > li > a:hover,
.tabs-below > .nav-tabs > li > a:focus {
  border-top-color: #dddddd;
  border-bottom-color: transparent;
}
.tabs-below > .nav-tabs > .active > a,
.tabs-below > .nav-tabs > .active > a:hover,
.tabs-below > .nav-tabs > .active > a:focus {
  border-bottom: none;
  line-height: 50px;
  border-top: 2px solid #2267a2;
}
.tabs-left > .tab-content,
.tabs-right > .tab-content {
  overflow: auto;
}
.tabs-left > .nav-tabs,
.tabs-right > .nav-tabs {
  padding-left: 0;
  padding-right: 0;
}
.tabs-left > .nav-tabs > li,
.tabs-right > .nav-tabs > li {
  float: none;
}
.tabs-left > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a {
  min-width: 74px;
  margin-right: 0;
}
.tabs-left > .nav-tabs {
  float: left;
}
.tabs-left > .nav-tabs > li > a {
  border-bottom: none;
  border-right: 2px solid transparent;
}
.tabs-left > .nav-tabs > li > a:hover,
.tabs-left > .nav-tabs > li > a:focus {
  border-color: transparent;
  border-right-color: #dddddd;
}
.tabs-left > .nav-tabs .active > a,
.tabs-left > .nav-tabs .active > a:hover,
.tabs-left > .nav-tabs .active > a:focus {
  border-bottom: none;
  border-right: 2px solid #2267a2;
}
.tabs-right > .nav-tabs {
  float: right;
  border-left: 1px solid #ddd;
}
.tabs-right > .nav-tabs > li > a {
  border-bottom: none;
  border-left: 2px solid transparent;
}
.tabs-right > .nav-tabs > li > a:hover,
.tabs-right > .nav-tabs > li > a:focus {
  border-color: transparent;
  border-left-color: #dddddd;
}
.tabs-right > .nav-tabs .active > a,
.tabs-right > .nav-tabs .active > a:hover,
.tabs-right > .nav-tabs .active > a:focus {
  border-bottom: none;
  border-left: 2px solid #2267a2;
}
.nav-tabs.tabs-default-dark > li.active > a,
.nav-tabs.tabs-default-dark > li.active > a:hover,
.nav-tabs.tabs-default-dark > li.active > a:focus {
  border-color: #2B2A65;
  color: #2B2A65;
}
.nav-tabs.tabs-default > li.active > a,
.nav-tabs.tabs-default > li.active > a:hover,
.nav-tabs.tabs-default > li.active > a:focus {
  border-color: #e5e6e6;
  color: #e5e6e6;
}
.nav-tabs.tabs-default-light > li.active > a,
.nav-tabs.tabs-default-light > li.active > a:hover,
.nav-tabs.tabs-default-light > li.active > a:focus {
  border-color: #f2f2f3;
  color: #f2f2f3;
}
.nav-tabs.tabs-default-bright > li.active > a,
.nav-tabs.tabs-default-bright > li.active > a:hover,
.nav-tabs.tabs-default-bright > li.active > a:focus {
  border-color: #ffffff;
  color: #ffffff;
}
.nav-tabs.tabs-primary-dark > li.active > a,
.nav-tabs.tabs-primary-dark > li.active > a:hover,
.nav-tabs.tabs-primary-dark > li.active > a:focus {
  border-color: #164271;
  color: #164271;
}
.nav-tabs.tabs-primary > li.active > a,
.nav-tabs.tabs-primary > li.active > a:hover,
.nav-tabs.tabs-primary > li.active > a:focus {
  border-color: #2267a2;
  color: #2267a2;
}
.nav-tabs.tabs-primary-light > li.active > a,
.nav-tabs.tabs-primary-light > li.active > a:hover,
.nav-tabs.tabs-primary-light > li.active > a:focus {
  border-color: #78a6ce;
  color: #78a6ce;
}
.nav-tabs.tabs-primary-bright > li.active > a,
.nav-tabs.tabs-primary-bright > li.active > a:hover,
.nav-tabs.tabs-primary-bright > li.active > a:focus {
  border-color: #c7dbec;
  color: #c7dbec;
}
.nav-tabs.tabs-accent-dark > li.active > a,
.nav-tabs.tabs-accent-dark > li.active > a:hover,
.nav-tabs.tabs-accent-dark > li.active > a:focus {
  border-color: #1a237e;
  color: #1a237e;
}
.nav-tabs.tabs-accent > li.active > a,
.nav-tabs.tabs-accent > li.active > a:hover,
.nav-tabs.tabs-accent > li.active > a:focus {
  border-color: #14BF6E;
  color: #14BF6E;
}
.nav-tabs.tabs-accent-light > li.active > a,
.nav-tabs.tabs-accent-light > li.active > a:hover,
.nav-tabs.tabs-accent-light > li.active > a:focus {
  border-color: #81d4fa;
  color: #81d4fa;
}
.nav-tabs.tabs-accent-bright > li.active > a,
.nav-tabs.tabs-accent-bright > li.active > a:hover,
.nav-tabs.tabs-accent-bright > li.active > a:focus {
  border-color: #f0f4c3;
  color: #f0f4c3;
}
.nav-tabs.tabs-success > li.active > a,
.nav-tabs.tabs-success > li.active > a:hover,
.nav-tabs.tabs-success > li.active > a:focus {
  border-color: #4caf50;
  color: #4caf50;
}
.nav-tabs.tabs-warning > li.active > a,
.nav-tabs.tabs-warning > li.active > a:hover,
.nav-tabs.tabs-warning > li.active > a:focus {
  border-color: #ff9800;
  color: #ff9800;
}
.nav-tabs.tabs-danger > li.active > a,
.nav-tabs.tabs-danger > li.active > a:hover,
.nav-tabs.tabs-danger > li.active > a:focus {
  border-color: #f44336;
  color: #f44336;
}
.nav-tabs.tabs-info > li.active > a,
.nav-tabs.tabs-info > li.active > a:hover,
.nav-tabs.tabs-info > li.active > a:focus {
  border-color: #2196f3;
  color: #2196f3;
}
.card {
  position: relative;
  margin-bottom: 24px;
  background-color: #ffffff;
  color: #313335;
  border-radius: 6px;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
}
.card:before,
.card:after {
  content: " ";
  display: table;
}
.card:after {
  clear: both;
}
.card .btn-icon-toggle:hover,
.card .btn-flat:hover {
  background-color: rgba(12, 12, 12, 0.07);
}
.card > .nano:last-child {
  border-radius: 0 0 6px 6px;
}
.card.no-shadow {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.card-head {
  position: relative;
  line-height: 52px;
  min-height: 56px;
  vertical-align: middle;
  border-radius: 6px 6px 0 0;
}
.card-head:before,
.card-head:after {
  content: " ";
  display: table;
}
.card-head:after {
  clear: both;
}
.card-head header {
  display: inline-block;
  padding: 11px 24px;
  vertical-align: middle;
  line-height: .97;
  font-size: 22px;
}
.card-head header > h1,
.card-head header > h2,
.card-head header > h3,
.card-head header > h4,
.card-head header > h5,
.card-head header > h6 {
  vertical-align: middle;
  margin: 0;
  line-height: 1;
}
.card-head header small {
  color: inherit;
  opacity: 0.6;
  font-size: 75%;
}
.card-head > div {
  display: inline-block;
}
.card-head .tools {
  padding-right: 16px;
  float: right;
}
.card-head .tools form {
  margin-top: 7px;
  margin-bottom: 7px;
  margin-left: 24px;
  line-height: normal;
  vertical-align: middle;
}
.card-head .tools .btn-floating-action {
  margin-left: 8px;
}
.card-head .tools > .btn-group {
  margin-right: 4px;
}
.card-head .tools > .btn-group-xs {
  margin-right: 10px;
}
.card-head .tools > .btn-group-sm {
  margin-right: 4px;
}
.card-head .tools > .btn-group-lg {
  margin-right: 0;
}
.card-head .tools.pull-left > .btn-group {
  margin-left: 4px;
}
.card-head .tools.pull-left > .btn-group-xs {
  margin-left: 10px;
}
.card-head .tools.pull-left > .btn-group-sm {
  margin-left: 4px;
}
.card-head .tools.pull-left > .btn-group-lg {
  margin-left: 0;
}
.card-head.card-head-xs {
  line-height: 32px;
  min-height: 36px;
}
.card-head.card-head-xs header {
  font-size: 15px;
  padding: 8px 24px;
}
.card-head.card-head-sm {
  line-height: 44px;
  min-height: 48px;
}
.card-head.card-head-sm header {
  font-size: 15px;
}
.card-head.card-head-lg {
  line-height: 76px;
  min-height: 80px;
}
.card-head.card-head-lg header {
  font-size: 24px;
}
.card-body {
  padding: 24px;
  position: relative;
}
.card-body:before,
.card-body:after {
  content: " ";
  display: table;
}
.card-body:after {
  clear: both;
}
.card-body:last-child {
  border-radius: 0 0 6px 6px;
}
.card-body.table-responsive {
  margin: 0;
}
.card-actionbar {
  padding-bottom: 8px;
  position: relative;
}
.card-actionbar:before,
.card-actionbar:after {
  content: " ";
  display: table;
}
.card-actionbar:after {
  clear: both;
}
.card-actionbar:last-child {
  border-radius: 0 0 6px 6px;
}
.card-actionbar-row {
  padding: 6px 16px;
  text-align: right;
}
.card-tiles > .row,
.card-type-blog-masonry > .row {
  margin: 0;
}
.card-tiles > .row > [class^="col-"],
.card-type-blog-masonry > .row > [class^="col-"] {
  padding: 0;
}
.card-underline .card-head {
  border-bottom: 1px solid rgba(150, 153, 156, 0.4);
}
.card-underline .card-foot {
  border-top: 1px solid rgba(150, 153, 156, 0.4);
}
.card-bordered,
.card-outlined {
  border: 1px solid #96999c;
}
section .style-default-dark,
.offcanvas-pane .style-default-dark,
.card .style-default-dark,
section.style-default-dark,
.offcanvas-pane.style-default-dark,
.card.style-default-dark {
  background-color: #2B2A65;
  border-color: #2B2A65;
  color: #ffffff;
}
section .style-default-dark .btn-icon-toggle:hover,
.offcanvas-pane .style-default-dark .btn-icon-toggle:hover,
.card .style-default-dark .btn-icon-toggle:hover,
section.style-default-dark .btn-icon-toggle:hover,
.offcanvas-pane.style-default-dark .btn-icon-toggle:hover,
.card.style-default-dark .btn-icon-toggle:hover,
section .style-default-dark .btn-flat:hover,
.offcanvas-pane .style-default-dark .btn-flat:hover,
.card .style-default-dark .btn-flat:hover,
section.style-default-dark .btn-flat:hover,
.offcanvas-pane.style-default-dark .btn-flat:hover,
.card.style-default-dark .btn-flat:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
.card-body-darken .style-default-dark,
.card-body-darken.style-default-dark {
  background-color: #093458;
  border-color: #093458;
  color: #ffffff;
}
.badge.style-default-dark {
  background-color: #2B2A65;
  border-color: #2B2A65;
  color: #ffffff;
}
section .style-default,
.offcanvas-pane .style-default,
.card .style-default,
section.style-default,
.offcanvas-pane.style-default,
.card.style-default {
  background-color: #e5e6e6;
  border-color: #e5e6e6;
  color: #313335;
}
section .style-default .btn-icon-toggle:hover,
.offcanvas-pane .style-default .btn-icon-toggle:hover,
.card .style-default .btn-icon-toggle:hover,
section.style-default .btn-icon-toggle:hover,
.offcanvas-pane.style-default .btn-icon-toggle:hover,
.card.style-default .btn-icon-toggle:hover,
section .style-default .btn-flat:hover,
.offcanvas-pane .style-default .btn-flat:hover,
.card .style-default .btn-flat:hover,
section.style-default .btn-flat:hover,
.offcanvas-pane.style-default .btn-flat:hover,
.card.style-default .btn-flat:hover {
  background-color: rgba(49, 51, 53, 0.3);
}
.card-body-darken .style-default,
.card-body-darken.style-default {
  background-color: #dfe0e1;
  border-color: #dfe0e1;
  color: #313335;
}
.badge.style-default {
  background-color: #e5e6e6;
  border-color: #e5e6e6;
  color: #313335;
}
section .style-default-light,
.offcanvas-pane .style-default-light,
.card .style-default-light,
section.style-default-light,
.offcanvas-pane.style-default-light,
.card.style-default-light {
  background-color: #f2f2f3;
  border-color: #f2f2f3;
  color: #313335;
}
section .style-default-light .btn-icon-toggle:hover,
.offcanvas-pane .style-default-light .btn-icon-toggle:hover,
.card .style-default-light .btn-icon-toggle:hover,
section.style-default-light .btn-icon-toggle:hover,
.offcanvas-pane.style-default-light .btn-icon-toggle:hover,
.card.style-default-light .btn-icon-toggle:hover,
section .style-default-light .btn-flat:hover,
.offcanvas-pane .style-default-light .btn-flat:hover,
.card .style-default-light .btn-flat:hover,
section.style-default-light .btn-flat:hover,
.offcanvas-pane.style-default-light .btn-flat:hover,
.card.style-default-light .btn-flat:hover {
  background-color: rgba(49, 51, 53, 0.3);
}
.card-body-darken .style-default-light,
.card-body-darken.style-default-light {
  background-color: #ededee;
  border-color: #ededee;
  color: #313335;
}
.badge.style-default-light {
  background-color: #f2f2f3;
  border-color: #f2f2f3;
  color: #313335;
}
section .style-default-bright,
.offcanvas-pane .style-default-bright,
.card .style-default-bright,
section.style-default-bright,
.offcanvas-pane.style-default-bright,
.card.style-default-bright {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #313335;
}
section .style-default-bright .btn-icon-toggle:hover,
.offcanvas-pane .style-default-bright .btn-icon-toggle:hover,
.card .style-default-bright .btn-icon-toggle:hover,
section.style-default-bright .btn-icon-toggle:hover,
.offcanvas-pane.style-default-bright .btn-icon-toggle:hover,
.card.style-default-bright .btn-icon-toggle:hover,
section .style-default-bright .btn-flat:hover,
.offcanvas-pane .style-default-bright .btn-flat:hover,
.card .style-default-bright .btn-flat:hover,
section.style-default-bright .btn-flat:hover,
.offcanvas-pane.style-default-bright .btn-flat:hover,
.card.style-default-bright .btn-flat:hover {
  background-color: rgba(49, 51, 53, 0.3);
}
.card-body-darken .style-default-bright,
.card-body-darken.style-default-bright {
  background-color: #fafafa;
  border-color: #fafafa;
  color: #313335;
}
.badge.style-default-bright {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #313335;
}
section .style-gray-dark,
.offcanvas-pane .style-gray-dark,
.card .style-gray-dark,
section.style-gray-dark,
.offcanvas-pane.style-gray-dark,
.card.style-gray-dark {
  background-color: #313335;
  border-color: #313335;
  color: #ffffff;
}
section .style-gray-dark .btn-icon-toggle:hover,
.offcanvas-pane .style-gray-dark .btn-icon-toggle:hover,
.card .style-gray-dark .btn-icon-toggle:hover,
section.style-gray-dark .btn-icon-toggle:hover,
.offcanvas-pane.style-gray-dark .btn-icon-toggle:hover,
.card.style-gray-dark .btn-icon-toggle:hover,
section .style-gray-dark .btn-flat:hover,
.offcanvas-pane .style-gray-dark .btn-flat:hover,
.card .style-gray-dark .btn-flat:hover,
section.style-gray-dark .btn-flat:hover,
.offcanvas-pane.style-gray-dark .btn-flat:hover,
.card.style-gray-dark .btn-flat:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
.card-body-darken .style-gray-dark,
.card-body-darken.style-gray-dark {
  background-color: #2d2e2f;
  border-color: #2d2e2f;
  color: #ffffff;
}
.badge.style-gray-dark {
  background-color: #313335;
  border-color: #313335;
  color: #ffffff;
}
section .style-gray,
.offcanvas-pane .style-gray,
.card .style-gray,
section.style-gray,
.offcanvas-pane.style-gray,
.card.style-gray {
  background-color: #535658;
  border-color: #535658;
  color: #ffffff;
}
section .style-gray .btn-icon-toggle:hover,
.offcanvas-pane .style-gray .btn-icon-toggle:hover,
.card .style-gray .btn-icon-toggle:hover,
section.style-gray .btn-icon-toggle:hover,
.offcanvas-pane.style-gray .btn-icon-toggle:hover,
.card.style-gray .btn-icon-toggle:hover,
section .style-gray .btn-flat:hover,
.offcanvas-pane .style-gray .btn-flat:hover,
.card .style-gray .btn-flat:hover,
section.style-gray .btn-flat:hover,
.offcanvas-pane.style-gray .btn-flat:hover,
.card.style-gray .btn-flat:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
.card-body-darken .style-gray,
.card-body-darken.style-gray {
  background-color: #4e5053;
  border-color: #4e5053;
  color: #ffffff;
}
.badge.style-gray {
  background-color: #535658;
  border-color: #535658;
  color: #ffffff;
}
section .style-gray-light,
.offcanvas-pane .style-gray-light,
.card .style-gray-light,
section.style-gray-light,
.offcanvas-pane.style-gray-light,
.card.style-gray-light {
  background-color: #96999c;
  border-color: #96999c;
  color: #ffffff;
}
section .style-gray-light .btn-icon-toggle:hover,
.offcanvas-pane .style-gray-light .btn-icon-toggle:hover,
.card .style-gray-light .btn-icon-toggle:hover,
section.style-gray-light .btn-icon-toggle:hover,
.offcanvas-pane.style-gray-light .btn-icon-toggle:hover,
.card.style-gray-light .btn-icon-toggle:hover,
section .style-gray-light .btn-flat:hover,
.offcanvas-pane .style-gray-light .btn-flat:hover,
.card .style-gray-light .btn-flat:hover,
section.style-gray-light .btn-flat:hover,
.offcanvas-pane.style-gray-light .btn-flat:hover,
.card.style-gray-light .btn-flat:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
.card-body-darken .style-gray-light,
.card-body-darken.style-gray-light {
  background-color: #919497;
  border-color: #919497;
  color: #ffffff;
}
.badge.style-gray-light {
  background-color: #96999c;
  border-color: #96999c;
  color: #ffffff;
}
section .style-gray-bright,
.offcanvas-pane .style-gray-bright,
.card .style-gray-bright,
section.style-gray-bright,
.offcanvas-pane.style-gray-bright,
.card.style-gray-bright {
  background-color: #bdbfc1;
  border-color: #bdbfc1;
  color: #313335;
}
section .style-gray-bright .btn-icon-toggle:hover,
.offcanvas-pane .style-gray-bright .btn-icon-toggle:hover,
.card .style-gray-bright .btn-icon-toggle:hover,
section.style-gray-bright .btn-icon-toggle:hover,
.offcanvas-pane.style-gray-bright .btn-icon-toggle:hover,
.card.style-gray-bright .btn-icon-toggle:hover,
section .style-gray-bright .btn-flat:hover,
.offcanvas-pane .style-gray-bright .btn-flat:hover,
.card .style-gray-bright .btn-flat:hover,
section.style-gray-bright .btn-flat:hover,
.offcanvas-pane.style-gray-bright .btn-flat:hover,
.card.style-gray-bright .btn-flat:hover {
  background-color: rgba(49, 51, 53, 0.3);
}
.card-body-darken .style-gray-bright,
.card-body-darken.style-gray-bright {
  background-color: #b8babc;
  border-color: #b8babc;
  color: #313335;
}
.badge.style-gray-bright {
  background-color: #bdbfc1;
  border-color: #bdbfc1;
  color: #313335;
}
section .style-primary,
.offcanvas-pane .style-primary,
.card .style-primary,
section.style-primary,
.offcanvas-pane.style-primary,
.card.style-primary {
  background-color: #2267a2;
  border-color: #2267a2;
  color: #ffffff;
}
section .style-primary .btn-icon-toggle:hover,
.offcanvas-pane .style-primary .btn-icon-toggle:hover,
.card .style-primary .btn-icon-toggle:hover,
section.style-primary .btn-icon-toggle:hover,
.offcanvas-pane.style-primary .btn-icon-toggle:hover,
.card.style-primary .btn-icon-toggle:hover,
section .style-primary .btn-flat:hover,
.offcanvas-pane .style-primary .btn-flat:hover,
.card .style-primary .btn-flat:hover,
section.style-primary .btn-flat:hover,
.offcanvas-pane.style-primary .btn-flat:hover,
.card.style-primary .btn-flat:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
.card-body-darken .style-primary,
.card-body-darken.style-primary {
  background-color: #20629a;
  border-color: #20629a;
  color: #ffffff;
}
.badge.style-primary {
  background-color: #2267a2;
  border-color: #2267a2;
  color: #ffffff;
}
section .style-primary-light,
.offcanvas-pane .style-primary-light,
.card .style-primary-light,
section.style-primary-light,
.offcanvas-pane.style-primary-light,
.card.style-primary-light {
  background-color: #78a6ce;
  border-color: #78a6ce;
  color: #ffffff;
}
section .style-primary-light .btn-icon-toggle:hover,
.offcanvas-pane .style-primary-light .btn-icon-toggle:hover,
.card .style-primary-light .btn-icon-toggle:hover,
section.style-primary-light .btn-icon-toggle:hover,
.offcanvas-pane.style-primary-light .btn-icon-toggle:hover,
.card.style-primary-light .btn-icon-toggle:hover,
section .style-primary-light .btn-flat:hover,
.offcanvas-pane .style-primary-light .btn-flat:hover,
.card .style-primary-light .btn-flat:hover,
section.style-primary-light .btn-flat:hover,
.offcanvas-pane.style-primary-light .btn-flat:hover,
.card.style-primary-light .btn-flat:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
.card-body-darken .style-primary-light,
.card-body-darken.style-primary-light {
  background-color: #70a1cb;
  border-color: #70a1cb;
  color: #ffffff;
}
.badge.style-primary-light {
  background-color: #78a6ce;
  border-color: #78a6ce;
  color: #ffffff;
}
section .style-primary-dark,
.offcanvas-pane .style-primary-dark,
.card .style-primary-dark,
section.style-primary-dark,
.offcanvas-pane.style-primary-dark,
.card.style-primary-dark {
  background-color: #164271;
  border-color: #164271;
  color: #ffffff;
}
section .style-primary-dark .btn-icon-toggle:hover,
.offcanvas-pane .style-primary-dark .btn-icon-toggle:hover,
.card .style-primary-dark .btn-icon-toggle:hover,
section.style-primary-dark .btn-icon-toggle:hover,
.offcanvas-pane.style-primary-dark .btn-icon-toggle:hover,
.card.style-primary-dark .btn-icon-toggle:hover,
section .style-primary-dark .btn-flat:hover,
.offcanvas-pane .style-primary-dark .btn-flat:hover,
.card .style-primary-dark .btn-flat:hover,
section.style-primary-dark .btn-flat:hover,
.offcanvas-pane.style-primary-dark .btn-flat:hover,
.card.style-primary-dark .btn-flat:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
.card-body-darken .style-primary-dark,
.card-body-darken.style-primary-dark {
  background-color: #143d68;
  border-color: #143d68;
  color: #ffffff;
}
.badge.style-primary-dark {
  background-color: #164271;
  border-color: #164271;
  color: #ffffff;
}
section .style-primary-bright,
.offcanvas-pane .style-primary-bright,
.card .style-primary-bright,
section.style-primary-bright,
.offcanvas-pane.style-primary-bright,
.card.style-primary-bright {
  background-color: #c7dbec;
  border-color: #c7dbec;
  color: #313335;
}
section .style-primary-bright .btn-icon-toggle:hover,
.offcanvas-pane .style-primary-bright .btn-icon-toggle:hover,
.card .style-primary-bright .btn-icon-toggle:hover,
section.style-primary-bright .btn-icon-toggle:hover,
.offcanvas-pane.style-primary-bright .btn-icon-toggle:hover,
.card.style-primary-bright .btn-icon-toggle:hover,
section .style-primary-bright .btn-flat:hover,
.offcanvas-pane .style-primary-bright .btn-flat:hover,
.card .style-primary-bright .btn-flat:hover,
section.style-primary-bright .btn-flat:hover,
.offcanvas-pane.style-primary-bright .btn-flat:hover,
.card.style-primary-bright .btn-flat:hover {
  background-color: rgba(49, 51, 53, 0.3);
}
.card-body-darken .style-primary-bright,
.card-body-darken.style-primary-bright {
  background-color: #bfd6ea;
  border-color: #bfd6ea;
  color: #313335;
}
.badge.style-primary-bright {
  background-color: #c7dbec;
  border-color: #c7dbec;
  color: #313335;
}
section .style-accent,
.offcanvas-pane .style-accent,
.card .style-accent,
section.style-accent,
.offcanvas-pane.style-accent,
.card.style-accent {
  background-color: #14BF6E;
  border-color: #14BF6E;
  color: #ffffff;
}
section .style-accent .btn-icon-toggle:hover,
.offcanvas-pane .style-accent .btn-icon-toggle:hover,
.card .style-accent .btn-icon-toggle:hover,
section.style-accent .btn-icon-toggle:hover,
.offcanvas-pane.style-accent .btn-icon-toggle:hover,
.card.style-accent .btn-icon-toggle:hover,
section .style-accent .btn-flat:hover,
.offcanvas-pane .style-accent .btn-flat:hover,
.card .style-accent .btn-flat:hover,
section.style-accent .btn-flat:hover,
.offcanvas-pane.style-accent .btn-flat:hover,
.card.style-accent .btn-flat:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
.card-body-darken .style-accent,
.card-body-darken.style-accent {
  background-color: #53bddc;
  border-color: #53bddc;
  color: #ffffff;
}
.badge.style-accent {
  background-color: #14BF6E;
  border-color: #14BF6E;
  color: #ffffff;
}
section .style-accent-light,
.offcanvas-pane .style-accent-light,
.card .style-accent-light,
section.style-accent-light,
.offcanvas-pane.style-accent-light,
.card.style-accent-light {
  background-color: #81d4fa;
  border-color: #81d4fa;
  color: #313335;
}
section .style-accent-light .btn-icon-toggle:hover,
.offcanvas-pane .style-accent-light .btn-icon-toggle:hover,
.card .style-accent-light .btn-icon-toggle:hover,
section.style-accent-light .btn-icon-toggle:hover,
.offcanvas-pane.style-accent-light .btn-icon-toggle:hover,
.card.style-accent-light .btn-icon-toggle:hover,
section .style-accent-light .btn-flat:hover,
.offcanvas-pane .style-accent-light .btn-flat:hover,
.card .style-accent-light .btn-flat:hover,
section.style-accent-light .btn-flat:hover,
.offcanvas-pane.style-accent-light .btn-flat:hover,
.card.style-accent-light .btn-flat:hover {
  background-color: rgba(49, 51, 53, 0.3);
}
.card-body-darken .style-accent-light,
.card-body-darken.style-accent-light {
  background-color: #77d1fa;
  border-color: #77d1fa;
  color: #313335;
}
.badge.style-accent-light {
  background-color: #81d4fa;
  border-color: #81d4fa;
  color: #313335;
}
section .style-accent-dark,
.offcanvas-pane .style-accent-dark,
.card .style-accent-dark,
section.style-accent-dark,
.offcanvas-pane.style-accent-dark,
.card.style-accent-dark {
  background-color: #1a237e;
  border-color: #1a237e;
  color: #ffffff;
}
section .style-accent-dark .btn-icon-toggle:hover,
.offcanvas-pane .style-accent-dark .btn-icon-toggle:hover,
.card .style-accent-dark .btn-icon-toggle:hover,
section.style-accent-dark .btn-icon-toggle:hover,
.offcanvas-pane.style-accent-dark .btn-icon-toggle:hover,
.card.style-accent-dark .btn-icon-toggle:hover,
section .style-accent-dark .btn-flat:hover,
.offcanvas-pane .style-accent-dark .btn-flat:hover,
.card .style-accent-dark .btn-flat:hover,
section.style-accent-dark .btn-flat:hover,
.offcanvas-pane.style-accent-dark .btn-flat:hover,
.card.style-accent-dark .btn-flat:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
.card-body-darken .style-accent-dark,
.card-body-darken.style-accent-dark {
  background-color: #182176;
  border-color: #182176;
  color: #ffffff;
}
.badge.style-accent-dark {
  background-color: #1a237e;
  border-color: #1a237e;
  color: #ffffff;
}
section .style-accent-bright,
.offcanvas-pane .style-accent-bright,
.card .style-accent-bright,
section.style-accent-bright,
.offcanvas-pane.style-accent-bright,
.card.style-accent-bright {
  background-color: #f0f4c3;
  border-color: #f0f4c3;
  color: #313335;
}
section .style-accent-bright .btn-icon-toggle:hover,
.offcanvas-pane .style-accent-bright .btn-icon-toggle:hover,
.card .style-accent-bright .btn-icon-toggle:hover,
section.style-accent-bright .btn-icon-toggle:hover,
.offcanvas-pane.style-accent-bright .btn-icon-toggle:hover,
.card.style-accent-bright .btn-icon-toggle:hover,
section .style-accent-bright .btn-flat:hover,
.offcanvas-pane .style-accent-bright .btn-flat:hover,
.card .style-accent-bright .btn-flat:hover,
section.style-accent-bright .btn-flat:hover,
.offcanvas-pane.style-accent-bright .btn-flat:hover,
.card.style-accent-bright .btn-flat:hover {
  background-color: rgba(49, 51, 53, 0.3);
}
.card-body-darken .style-accent-bright,
.card-body-darken.style-accent-bright {
  background-color: #eef2ba;
  border-color: #eef2ba;
  color: #313335;
}
.badge.style-accent-bright {
  background-color: #f0f4c3;
  border-color: #f0f4c3;
  color: #313335;
}
section .style-success,
.offcanvas-pane .style-success,
.card .style-success,
section.style-success,
.offcanvas-pane.style-success,
.card.style-success {
  background-color: #4caf50;
  border-color: #4caf50;
  color: #ffffff;
}
section .style-success .btn-icon-toggle:hover,
.offcanvas-pane .style-success .btn-icon-toggle:hover,
.card .style-success .btn-icon-toggle:hover,
section.style-success .btn-icon-toggle:hover,
.offcanvas-pane.style-success .btn-icon-toggle:hover,
.card.style-success .btn-icon-toggle:hover,
section .style-success .btn-flat:hover,
.offcanvas-pane .style-success .btn-flat:hover,
.card .style-success .btn-flat:hover,
section.style-success .btn-flat:hover,
.offcanvas-pane.style-success .btn-flat:hover,
.card.style-success .btn-flat:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
.card-body-darken .style-success,
.card-body-darken.style-success {
  background-color: #49a84d;
  border-color: #49a84d;
  color: #ffffff;
}
.badge.style-success {
  background-color: #4caf50;
  border-color: #4caf50;
  color: #ffffff;
}
section .style-warning,
.offcanvas-pane .style-warning,
.card .style-warning,
section.style-warning,
.offcanvas-pane.style-warning,
.card.style-warning {
  background-color: #ff9800;
  border-color: #ff9800;
  color: #ffffff;
}
section .style-warning .btn-icon-toggle:hover,
.offcanvas-pane .style-warning .btn-icon-toggle:hover,
.card .style-warning .btn-icon-toggle:hover,
section.style-warning .btn-icon-toggle:hover,
.offcanvas-pane.style-warning .btn-icon-toggle:hover,
.card.style-warning .btn-icon-toggle:hover,
section .style-warning .btn-flat:hover,
.offcanvas-pane .style-warning .btn-flat:hover,
.card .style-warning .btn-flat:hover,
section.style-warning .btn-flat:hover,
.offcanvas-pane.style-warning .btn-flat:hover,
.card.style-warning .btn-flat:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
.card-body-darken .style-warning,
.card-body-darken.style-warning {
  background-color: #f59200;
  border-color: #f59200;
  color: #ffffff;
}
.badge.style-warning {
  background-color: #ff9800;
  border-color: #ff9800;
  color: #ffffff;
}
section .style-danger,
.offcanvas-pane .style-danger,
.card .style-danger,
section.style-danger,
.offcanvas-pane.style-danger,
.card.style-danger {
  background-color: #f44336;
  border-color: #f44336;
  color: #ffffff;
}
section .style-danger .btn-icon-toggle:hover,
.offcanvas-pane .style-danger .btn-icon-toggle:hover,
.card .style-danger .btn-icon-toggle:hover,
section.style-danger .btn-icon-toggle:hover,
.offcanvas-pane.style-danger .btn-icon-toggle:hover,
.card.style-danger .btn-icon-toggle:hover,
section .style-danger .btn-flat:hover,
.offcanvas-pane .style-danger .btn-flat:hover,
.card .style-danger .btn-flat:hover,
section.style-danger .btn-flat:hover,
.offcanvas-pane.style-danger .btn-flat:hover,
.card.style-danger .btn-flat:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
.card-body-darken .style-danger,
.card-body-darken.style-danger {
  background-color: #f33a2c;
  border-color: #f33a2c;
  color: #ffffff;
}
.badge.style-danger {
  background-color: #f44336;
  border-color: #f44336;
  color: #ffffff;
}
section .style-info,
.offcanvas-pane .style-info,
.card .style-info,
section.style-info,
.offcanvas-pane.style-info,
.card.style-info {
  background-color: #2196f3;
  border-color: #2196f3;
  color: #ffffff;
}
section .style-info .btn-icon-toggle:hover,
.offcanvas-pane .style-info .btn-icon-toggle:hover,
.card .style-info .btn-icon-toggle:hover,
section.style-info .btn-icon-toggle:hover,
.offcanvas-pane.style-info .btn-icon-toggle:hover,
.card.style-info .btn-icon-toggle:hover,
section .style-info .btn-flat:hover,
.offcanvas-pane .style-info .btn-flat:hover,
.card .style-info .btn-flat:hover,
section.style-info .btn-flat:hover,
.offcanvas-pane.style-info .btn-flat:hover,
.card.style-info .btn-flat:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
.card-body-darken .style-info,
.card-body-darken.style-info {
  background-color: #1791f2;
  border-color: #1791f2;
  color: #ffffff;
}
.badge.style-info {
  background-color: #2196f3;
  border-color: #2196f3;
  color: #ffffff;
}
.card.style-transparent,
.card .style-transparent {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: inherit;
}
.card-transparent {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: inherit;
}
.card-transparent .card-head header,
.card-transparent .card-head .tools {
  padding-left: 4px;
  padding-right: 4px;
}
.card-transparent .card-body {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
}
.card.card-outlined {
  background-color: #ffffff;
  background-image: none;
  color: #313335;
}
.card.card-outlined .btn-icon-toggle:hover,
.card.card-outlined .btn-flat:hover {
  background-color: rgba(12, 12, 12, 0.07);
}
.card-loader {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 999;
}
.card .btn-collapse {
  -webkit-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
}
.card-collapsed .btn-collapse {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.card .prettyprint {
  margin: 0;
  border-left: none;
  border-right: none;
  border-bottom: none;
}
.card-type-pricing .list-unstyled {
  margin: 0;
}
.card-type-pricing .list-unstyled li {
  padding: 15px 30px;
  border-top: 1px solid #e5e6e6;
}
.card-type-pricing .list-unstyled li:first-child {
  border-top: none;
}
.card-type-pricing .price h1,
.card-type-pricing .price h2,
.card-type-pricing .price h3,
.card-type-pricing .price h4,
.card-type-pricing .price h5,
.card-type-pricing .price h6 {
  display: inline-block;
  margin: 0;
}
.card-type-blog-masonry article .blog-image img {
  width: 100%;
}
.card-type-blog-masonry article .blog-text {
  position: relative;
}
.card-type-blog-masonry article > div + div.blog-text:before {
  position: absolute;
  margin: auto;
  top: -20px;
  left: 0;
  right: 0;
  content: "";
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 20px 20px 20px;
  border-color: transparent transparent #ffffff transparent;
  z-index: 1;
}
.card-type-blog-masonry article .blog-text:first-child:before {
  position: absolute;
  margin: auto;
  bottom: -20px;
  left: 0;
  right: 0;
  content: "";
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 20px 20px 0 20px;
  border-color: #ffffff transparent transparent transparent;
  z-index: 1;
}
@media (max-width: 768px) {
  .card {
    margin-bottom: 16px;
  }
  .card-head header {
    padding: 11px 16px;
  }
  .card-head.card-head-xs header {
    padding: 8px 16px;
  }
  .card-body {
    padding: 16px;
  }
  .card .force-padding {
    padding: 16px;
  }
  .card .small-padding {
    padding: 8px;
  }
  .card .form-padding {
    padding: 0 16px;
  }
  .card .no-side-padding {
    padding: 16px 0;
  }
}
.offcanvas {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1032;
}
body.offcanvas-expanded {
  overflow: hidden;
}
.offcanvas:first-child {
  left: 0;
  right: auto;
}
.offcanvas-pane {
  position: absolute;
  top: 0;
  bottom: 0;
  min-width: 8px;
  background-color: #ffffff;
  overflow: auto;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: -webkit-transform 0.33s ease-in;
  -moz-transition: -moz-transform 0.33s ease-in;
  -o-transition: -o-transform 0.33s ease-in;
  transition: transform 0.33s ease-in;
}
.offcanvas-pane.active {
  display: block;
  -webkit-box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.38);
  box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.38);
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.22, 0.99, 0.68, 0.98);
  -moz-transition: -moz-transform 0.5s cubic-bezier(0.22, 0.99, 0.68, 0.98);
  -o-transition: -o-transform 0.5s cubic-bezier(0.22, 0.99, 0.68, 0.98);
  transition: transform 0.5s cubic-bezier(0.22, 0.99, 0.68, 0.98);
}
.offcanvas:first-child .offcanvas-pane {
  right: 0;
}
.offcanvas-body {
  padding: 16px;
}
.offcanvas-head {
  position: relative;
  padding: 4px 16px;
}
.offcanvas-head header {
  font-size: 22px;
}
.offcanvas-head .offcanvas-tools {
  position: absolute;
  left: 4px;
  right: 4px;
  top: 4px;
}
sup.badge {
  font-size: 70%;
  top: -0.9em;
  margin-left: -5px;
  margin-right: -5px;
}
sub.badge {
  font-size: 70%;
  bottom: -0.9em;
  margin-left: -5px;
  margin-right: -5px;
}
.badge.style-default-dark {
  background-color: #2B2A65;
  color: #ffffff;
}
.badge.style-default {
  background-color: #e5e6e6;
  color: #313335;
}
.badge.style-default-light {
  background-color: #f2f2f3;
  color: #313335;
}
.badge.style-default-bright {
  background-color: #ffffff;
  color: #313335;
}
.badge.style-primary {
  background-color: #2267a2;
  color: #ffffff;
}
.badge.style-primary-light {
  background-color: #78a6ce;
  color: #ffffff;
}
.badge.style-primary-dark {
  background-color: #164271;
  color: #ffffff;
}
.badge.style-primary-bright {
  background-color: #c7dbec;
  color: #313335;
}
.badge.style-accent {
  background-color: #14BF6E;
  color: #ffffff;
}
.badge.style-accent-light {
  background-color: #81d4fa;
  color: #313335;
}
.badge.style-accent-dark {
  background-color: #1a237e;
  color: #ffffff;
}
.badge.style-accent-bright {
  background-color: #f0f4c3;
  color: #313335;
}
.badge.style-success {
  background-color: #4caf50;
  color: #ffffff;
}
.badge.style-warning {
  background-color: #ff9800;
  color: #ffffff;
}
.badge.style-danger {
  background-color: #f44336;
  color: #ffffff;
}
.badge.style-info {
  background-color: #2196f3;
  color: #ffffff;
}
.progress-hairline {
  margin-top: 8px;
  height: 2px;
}
.progress-bar-default-dark {
  background-color: #2B2A65;
}
.progress-striped .progress-bar-default-dark {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-default {
  background-color: #e5e6e6;
}
.progress-striped .progress-bar-default {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-default-light {
  background-color: #f2f2f3;
}
.progress-striped .progress-bar-default-light {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-default-bright {
  background-color: #ffffff;
}
.progress-striped .progress-bar-default-bright {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-primary-dark {
  background-color: #2267a2;
}
.progress-striped .progress-bar-primary-dark {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-primary {
  background-color: #78a6ce;
}
.progress-striped .progress-bar-primary {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-primary-light {
  background-color: #164271;
}
.progress-striped .progress-bar-primary-light {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-primary-bright {
  background-color: #c7dbec;
}
.progress-striped .progress-bar-primary-bright {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-accent-dark {
  background-color: #14BF6E;
}
.progress-striped .progress-bar-accent-dark {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-accent {
  background-color: #81d4fa;
}
.progress-striped .progress-bar-accent {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-accent-light {
  background-color: #1a237e;
}
.progress-striped .progress-bar-accent-light {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-accent-bright {
  background-color: #f0f4c3;
}
.progress-striped .progress-bar-accent-bright {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-success {
  background-color: #4caf50;
}
.progress-striped .progress-bar-success {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-warning {
  background-color: #ff9800;
}
.progress-striped .progress-bar-warning {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-danger {
  background-color: #f44336;
}
.progress-striped .progress-bar-danger {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-info {
  background-color: #2196f3;
}
.progress-striped .progress-bar-info {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.list {
  margin: 0;
  padding-left: 0;
  list-style: none;
  line-height: 26px;
}
.list .tile {
  position: relative;
  display: table;
  width: 100%;
  min-height: 48px;
}
.list .tile > a {
  text-decoration: none;
}
.list .tile > a:hover {
  color: #2267a2;
}
.list .tile .tile-content {
  display: table-cell;
  padding-left: 16px;
}
.list .tile .tile-content:last-child {
  padding-right: 16px;
}
.list .tile .tile-content > div {
  display: table-cell;
  vertical-align: middle;
}
.list .tile > .btn {
  display: table-cell;
  vertical-align: middle;
  min-width: 56px;
  width: 56px;
  text-align: center;
}
.list .tile > .btn .fa,
.list .tile > .btn .md,
.list .tile > .btn .glyphicon {
  width: 24px;
  font-size: 20px;
  text-align: center;
}
.list .tile .tile-icon {
  min-width: 56px;
  width: 56px;
  padding: 8px 0;
  text-align: right;
}
.list .tile .tile-icon:first-child {
  text-align: left;
}
.list .tile .tile-icon img {
  width: 40px;
  height: 40px;
  border-radius: 40px;
}
.list .tile .tile-icon .fa,
.list .tile .tile-icon .md,
.list .tile .tile-icon .glyphicon {
  width: 24px;
  margin-top: 4px;
  font-size: 20px;
  text-align: center;
}
.list .tile .tile-text {
  padding: 12px 0;
  font-size: 16px;
  width: 100%;
}
.list .tile .tile-text small {
  display: block;
  font-size: 14px;
  opacity: 0.6;
}
.list .tile .tile-text small span {
  opacity: 1;
}
.list .tile .checkbox-styled:not(ie8),
.list .tile .radio-styled:not(ie8) {
  display: table-cell;
  padding: 0;
}
.list .tile .checkbox-styled:not(ie8) label,
.list .tile .radio-styled:not(ie8) label {
  display: block;
  padding-left: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.list .tile .checkbox-styled:not(ie8):last-child label,
.list .tile .radio-styled:not(ie8):last-child label {
  padding-right: 16px;
}
.list .tile .checkbox-styled:not(ie8) input ~ span,
.list .tile .radio-styled:not(ie8) input ~ span {
  padding-left: 54px;
}
.list .tile .ink-reaction .ink {
  width: 600px;
  height: 600px;
  margin-left: -300px;
  margin-top: -300px;
  background-color: rgba(83, 86, 88, 0.15);
}
.list li.divider-full-bleed,
.list li.divider-inset {
  position: relative;
}
.list li.divider-full-bleed:after,
.list li.divider-inset:after,
.list.divider-full-bleed li:after,
.list.divider-inset li:after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  display: block;
  height: 1px;
  background: rgba(150, 153, 156, 0.3);
}
.list li.divider-full-bleed:after,
.list.divider-full-bleed li:after {
  left: 0;
}
.list li.divider-inset:after,
.list.divider-inset li:after {
  left: 72px;
}
.list-group .fa,
.list-group .md,
.list-group .glyphicon {
  margin-right: 5px;
}
.list-group .btn[class^="btn-radio"],
.list-group .btn[class^="btn-checkbox"],
.list-group .btn[class*=" btn-radio"],
.list-group .btn[class*=" btn-checkbox"] {
  padding: 0;
}
.list-group-item {
  color: #313335;
}
.list-group-item.focus,
.list-group-item.focus:hover,
.list-group-item.focus:focus {
  background-color: #f2f2f3;
}
.list-group-item.focus .list-group-item-heading,
.list-group-item.focus:hover .list-group-item-heading,
.list-group-item.focus:focus .list-group-item-heading {
  color: inherit;
}
.list-group-item.focus .list-group-item-text,
.list-group-item.focus:hover .list-group-item-text,
.list-group-item.focus:focus .list-group-item-text {
  opacity: .75;
}
.card-body .list-group:first-child:nth-last-child(1) {
  margin-bottom: 0;
}
.list-email {
  margin-bottom: 20px !important;
}
.list-email a {
  border-left: none;
  border-right: none;
  padding: 20px 20px 20px 40px;
}
.list-email a:first-child {
  border-top: none;
}
.list-email h2,
.list-email h3,
.list-email h4,
.list-email h5,
.list-email h6 {
  margin-top: 0;
  margin-bottom: 5px;
}
.list-email p {
  color: #a3a6a8;
  margin-bottom: 0;
  line-height: 1.3;
}
.list-tags .btn {
  margin-bottom: 3px;
}
.list-comments {
  padding-left: 0;
  list-style: none;
}
.list-comments > li {
  margin-bottom: 20px;
}
.list-comments > li ul {
  padding-top: 10px;
  padding-left: 60px;
  list-style: none;
  border-left: 1px solid #d9d9d9;
}
.list-comments > li ul li {
  padding-top: 10px;
}
.list-comments .card {
  margin: 0;
}
.list-comments .comment-title {
  margin: 0 50px 7px 0;
}
.list-comments .comment-title small {
  font-size: 11px;
  margin-left: 7px;
}
.list-comments .comment-avatar {
  position: relative;
  float: left;
  width: 60px;
  height: 60px;
  margin: 20px 0 0 20px;
  text-align: center;
  border: 1px solid #d9d9d9;
  border-radius: 999px;
}
.list-comments .comment-avatar img {
  width: 100%;
}
.list-comments .comment-avatar .fa,
.list-comments .comment-avatar .md {
  line-height: 60px;
  font-size: 28px;
}
.list-comments .comment-avatar .glyphicon {
  line-height: 52px;
  font-size: 20px;
}
.list-comments .card-body {
  margin-left: 80px;
}
.list-chats {
  padding-left: 0;
  list-style: none;
}
.list-chats .chat {
  position: relative;
  min-height: 40px;
  margin: 16px 0 0 0;
  padding: 0 52px;
  text-align: right;
}
.list-chats .chat:before,
.list-chats .chat:after {
  content: " ";
  display: table;
}
.list-chats .chat:after {
  clear: both;
}
.list-chats .chat-avatar {
  position: absolute;
  right: 0;
  width: 40px;
  height: 40px;
  margin: 0;
  text-align: center;
  border-radius: 999px;
}
.list-chats .chat-avatar img {
  width: 100%;
}
.list-chats .chat-avatar .fa,
.list-chats .chat-avatar .md {
  line-height: 40px;
  font-size: 18px;
}
.list-chats .chat-avatar .glyphicon {
  line-height: 32px;
  font-size: 16px;
}
.list-chats .chat-body {
  position: relative;
  display: inline-block;
  float: right;
  clear: both;
  padding: 12px;
  margin-bottom: 2px;
  text-align: left;
  color: #313335;
  background: #ffffff;
}
.list-chats .chat-body small {
  display: block;
  margin-bottom: -4px;
  opacity: 0.7;
}
.list-chats .chat-avatar + .chat-body:after {
  position: absolute;
  content: '';
  z-index: 0;
  width: 0;
  height: 0;
  border-style: solid;
  top: 0;
  right: -12px;
  border-width: 0 0 12px 12px;
  border-color: transparent transparent transparent #ffffff;
}
.list-chats .chat-left .chat {
  text-align: left;
}
.list-chats .chat-left .chat-avatar {
  left: 0;
  right: auto;
}
.list-chats .chat-left .chat-body {
  float: left;
  background-color: #2267a2;
  color: #ffffff;
}
.list-chats .chat-left .chat-avatar + .chat-body:after {
  top: 0;
  left: -12px;
  border-width: 0 12px 12px 0;
  border-color: transparent #2267a2 transparent transparent;
}
.list-results {
  margin-bottom: 24px;
  margin-top: 1px;
  border-left: 1px solid #f2f2f3;
}
.list-results:before,
.list-results:after {
  content: " ";
  display: table;
}
.list-results:after {
  clear: both;
}
.list-results > div {
  padding: 20px 10px;
  margin-top: -1px;
  border-top: 1px solid #f2f2f3;
  border-right: 1px solid #f2f2f3;
  border-bottom: 1px solid #f2f2f3;
  background: inherit;
  color: inherit;
  text-decoration: none;
}
.list-results > div:hover {
  background: #f2f2f3;
}
.list-results > div img {
  margin-right: 10px;
}
.list-results.list-results-underlined {
  border-left: none;
}
.list-results.list-results-underlined > div {
  padding: 20px 0;
  border-right: none;
  border-top: none;
}
.nav-stacked {
  border-bottom: none;
}
.nav-stacked > li {
  margin-bottom: 0;
}
.nav-stacked > li + li {
  margin-top: 1px;
  margin-bottom: 0;
}
.nav-stacked > li > a {
  margin-right: 0;
}
.nav-stacked.nav-tabs > li.active > a,
.nav-stacked.nav-tabs > li.active > a:hover,
.nav-stacked.nav-tabs > li.active > a:focus {
  border: none;
}
.nav > li > small {
  display: block;
  margin: 20px 0 10px 10px;
  font-size: 10px;
  font-weight: 500;
  opacity: .6;
}
.nav > li:first-child > small {
  margin-top: 0;
}
.nav.nav-icon > li a {
  padding-left: 32px;
}
.nav.nav-icon > li a .fa,
.nav.nav-icon > li a .md,
.nav.nav-icon > li a .glyphicon {
  position: relative;
  width: 32px;
  text-align: center;
  margin-left: -32px;
  float: left;
  font-size: 1.3em;
}
.nav-transparent a {
  color: inherit;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.nav-transparent a:hover {
  color: #313335;
}
.nav-pills img {
  margin-right: 10px;
}
.nav-pills li.focus > a,
.nav-pills li.focus > a:hover,
.nav-pills li.focus > a:focus {
  background-color: #f2f2f3;
}
.navbar-search {
  position: relative;
  display: inline-table;
  padding: 0;
  white-space: nowrap;
  border-radius: 2px;
  -webkit-transition: background 0.25s ease-in-out;
  -o-transition: background 0.25s ease-in-out;
  transition: background 0.25s ease-in-out;
}
.navbar-search .form-group {
  display: table-cell;
  margin-bottom: 0;
  max-width: 0;
  vertical-align: middle;
  overflow: hidden;
  -webkit-transition: max-width 0.25s ease-in-out;
  -o-transition: max-width 0.25s ease-in-out;
  transition: max-width 0.25s ease-in-out;
}
.navbar-search input {
  display: inline-block;
  padding: 0 10px;
  max-width: 0;
  height: 36px;
  border: none;
  -webkit-transition: max-width 0.25s ease-in-out;
  -o-transition: max-width 0.25s ease-in-out;
  transition: max-width 0.25s ease-in-out;
}
.navbar-search .btn {
  display: table-cell;
}
.navbar-search.expanded {
  background-color: #ffffff;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
}
.navbar-search.expanded .form-group {
  max-width: 500px;
}
.navbar-search.expanded .btn {
  color: #0c0c0c;
  background: inherit;
}
.navbar-search.expanded input {
  max-width: 500px;
}
.nav li.padded {
  padding: 10px;
}
.alert.alert-callout {
  position: relative;
  padding-left: 20px;
  background: #ffffff;
  color: #313335;
  border-radius: 3px;
  border-color: rgba(83, 86, 88, 0.15);
}
.alert.alert-callout:before {
  content: '';
  position: absolute;
  display: block;
  width: 4px;
  left: -1px;
  top: -1px;
  bottom: -1px;
  background: red;
}
.alert-callout.alert-success:before {
  background: #4caf50;
}
.alert-callout.alert-info:before {
  background: #2196f3;
}
.alert-callout.alert-warning:before {
  background: #ff9800;
}
.alert-callout.alert-danger:before {
  background: #f44336;
}
.breadcrumb {
  padding: 0;
  margin-bottom: 0;
  font-size: 12px;
  text-shadow: none;
}
.breadcrumb > li {
  opacity: 0.8;
  display: inline-block;
}
.breadcrumb > li + li:before {
  color: inherit;
  content: "/";
  font-size: 10px;
  padding: 0 5px;
}
.breadcrumb > .active {
  color: inherit;
  opacity: 0.7;
  font-weight: 400;
  font-size: 22px;
}
.panel-group .card {
  border: inherit;
  -webkit-transition: margin 0.33s ease-out;
  -o-transition: margin 0.33s ease-out;
  transition: margin 0.33s ease-out;
}
.panel-group .card .fa-angle-down,
.panel-group .card .fa-chevron-down,
.panel-group .card .fa-caret-down {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
}
.panel-group .card .collapsed .fa-angle-down,
.panel-group .card .collapsed .fa-chevron-down,
.panel-group .card .collapsed .fa-caret-down {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.panel-group .card .fa-plus {
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.panel-group .card .collapsed .fa-plus {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}
.panel-group .card + .card {
  margin-top: 1px;
}
.panel-group .card.expanded {
  margin: 16px 0px;
}
.panel-group .card.expanded:first-child {
  margin-top: 0;
}
.timeline {
  position: relative;
  list-style: none;
  padding: 20px 0 0 0;
  left: 50%;
}
.timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 4px;
  background-color: #0c0c0c;
  opacity: 0.15;
  left: 0;
  margin-left: -2px;
}
.timeline-hairline.timeline:before {
  width: 2px;
  margin-left: -1px;
}
.timeline li {
  position: relative;
  display: block;
  width: 50%;
  left: -50%;
  white-space: nowrap;
  min-height: 30px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.timeline-entry {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 0;
  vertical-align: top;
  margin: 0 -3px 0 0;
  white-space: normal;
}
.timeline-entry .card {
  position: relative;
  margin-right: 30px;
}
.timeline-entry .card img,
.timeline-entry .card .fa,
.timeline-entry .card .md,
.timeline-entry .card .glyphicon {
  margin-right: 10px;
}
.timeline-inverted .timeline-entry {
  left: 100%;
}
.timeline-inverted .timeline-entry .card {
  margin-left: 30px;
  margin-bottom: 15px;
  margin-right: 0;
}
.timeline-entry .card:after {
  content: "";
  position: absolute;
  top: 16px;
  right: -9px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 9px 0 9px 9px;
  border-color: transparent transparent transparent #ffffff;
}
.timeline-inverted .timeline-entry .card:after {
  right: auto;
  left: -9px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.timeline-entry .card.style-default-dark:after {
  border-color: transparent transparent transparent #2B2A65;
}
.timeline-entry .card.style-default:after {
  border-color: transparent transparent transparent #e5e6e6;
}
.timeline-entry .card.style-default-light:after {
  border-color: transparent transparent transparent #f2f2f3;
}
.timeline-entry .card.style-default-bright:after {
  border-color: transparent transparent transparent #ffffff;
}
.timeline-entry .card.style-primary:after {
  border-color: transparent transparent transparent #2267a2;
}
.timeline-entry .card.style-primary-light:after {
  border-color: transparent transparent transparent #78a6ce;
}
.timeline-entry .card.style-primary-dark:after {
  border-color: transparent transparent transparent #164271;
}
.timeline-entry .card.style-primary-bright:after {
  border-color: transparent transparent transparent #c7dbec;
}
.timeline-entry .card.style-accent:after {
  border-color: transparent transparent transparent #14BF6E;
}
.timeline-entry .card.style-accent-light:after {
  border-color: transparent transparent transparent #81d4fa;
}
.timeline-entry .card.style-accent-dark:after {
  border-color: transparent transparent transparent #1a237e;
}
.timeline-entry .card.style-accent-bright:after {
  border-color: transparent transparent transparent #f0f4c3;
}
.timeline-entry .card.style-success:after {
  border-color: transparent transparent transparent #4caf50;
}
.timeline-entry .card.style-warning:after {
  border-color: transparent transparent transparent #ff9800;
}
.timeline-entry .card.style-danger:after {
  border-color: transparent transparent transparent #f44336;
}
.timeline-entry .card.style-info:after {
  border-color: transparent transparent transparent #2196f3;
}
.timeline-circ {
  position: absolute;
  top: 15px;
  left: 100%;
  width: 20px;
  height: 20px;
  line-height: 20px;
  margin-left: -10px;
  text-align: center;
  background: #96999c;
  color: #ffffff;
  border-width: 3px;
  border-style: solid;
  border-color: #fff !important;
  border-radius: 50%;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
}
.timeline-circ .glyphicon,
.timeline-circ .fa,
.timeline-circ .md {
  top: 0;
  margin: 0;
}
.timeline-circ.circ-lg {
  top: 10px;
  width: 30px;
  height: 30px;
  margin-left: -15px;
}
.timeline-circ.circ-lg .glyphicon,
.timeline-circ.circ-lg .fa,
.timeline-circ.circ-lg .md {
  font-size: 14px;
  line-height: 24px;
}
.timeline-circ.circ-xl {
  top: 6px;
  width: 38px;
  height: 38px;
  margin-left: -19px;
}
.timeline-circ.circ-xl .glyphicon,
.timeline-circ.circ-xl .fa,
.timeline-circ.circ-xl .md {
  font-size: 18px;
  line-height: 32px;
}
.timeline.no-shadow .card,
.timeline.no-shadow .timeline-circ {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.timeline.no-card-shadow .card {
  -webkit-box-shadow: none;
  box-shadow: none;
}
@media (max-width: 768px) {
  .timeline.collapse-xs {
    left: 0;
  }
  .timeline.collapse-xs:before {
    left: 10px;
  }
  .timeline.collapse-xs li {
    left: 0;
    width: 100%;
  }
  .timeline.collapse-xs .timeline-entry {
    left: 0 !important;
  }
  .timeline.collapse-xs .timeline-entry .card {
    margin-left: 40px !important;
    margin-right: 0;
  }
  .timeline.collapse-xs .timeline-entry .card:after {
    right: auto;
    left: -9px;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .timeline.collapse-xs .timeline-circ {
    left: 10px;
  }
}
@media (max-width: 991px) {
  .timeline.collapse-sm {
    left: 0;
  }
  .timeline.collapse-sm:before {
    left: 10px;
  }
  .timeline.collapse-sm li {
    left: 0;
    width: 100%;
  }
  .timeline.collapse-sm .timeline-entry {
    left: 0 !important;
  }
  .timeline.collapse-sm .timeline-entry .card {
    margin-left: 40px !important;
    margin-right: 0;
  }
  .timeline.collapse-sm .timeline-entry .card:after {
    right: auto;
    left: -9px;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .timeline.collapse-sm .timeline-circ {
    left: 10px;
  }
}
@media (max-width: 1199px) {
  .timeline.collapse-md {
    left: 0;
  }
  .timeline.collapse-md:before {
    left: 10px;
  }
  .timeline.collapse-md li {
    left: 0;
    width: 100%;
  }
  .timeline.collapse-md .timeline-entry {
    left: 0 !important;
  }
  .timeline.collapse-md .timeline-entry .card {
    margin-left: 40px !important;
    margin-right: 0;
  }
  .timeline.collapse-md .timeline-entry .card:after {
    right: auto;
    left: -9px;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .timeline.collapse-md .timeline-circ {
    left: 10px;
  }
}
.timeline.collapse-lg {
  left: 0;
}
.timeline.collapse-lg:before {
  left: 10px;
}
.timeline.collapse-lg li {
  left: 0;
  width: 100%;
}
.timeline.collapse-lg .timeline-entry {
  left: 0 !important;
}
.timeline.collapse-lg .timeline-entry .card {
  margin-left: 40px !important;
  margin-right: 0;
}
.timeline.collapse-lg .timeline-entry .card:after {
  right: auto;
  left: -9px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.timeline.collapse-lg .timeline-circ {
  left: 10px;
}
.knob {
  position: relative;
  display: inline-block;
  border-color: #e0e0e0;
}
.knob canvas {
  position: relative;
  vertical-align: top;
}
.knob.knob-shadow:before {
  position: absolute;
  content: "";
  height: 15%;
  left: 30%;
  right: 30%;
  bottom: -8%;
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.2), 0 0 40px 20px rgba(0, 0, 0, 0.2), inset 0 0 50px 100px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  -webkit-transform: scaleY(0.07);
  -ms-transform: scaleY(0.07);
  -o-transform: scaleY(0.07);
  transform: scaleY(0.07);
  z-index: 0;
}
.knob-stack {
  position: relative;
  display: block;
}
.knob-stack .knob-pane {
  display: block;
}
.knob-stack .knob-pane:after {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
  content: "\25CF";
  display: block;
  color: #ffffff;
  text-align: center;
}
.knob-stack .knob-pane .knob-legend {
  position: absolute;
}
.knob-stack .knob-pane .knob-legend strong {
  position: absolute;
  top: -22px;
  font-size: 16px;
  display: block;
  width: 100%;
}
.knob-stack .knob-pane .knob-legend span {
  position: relative;
  top: 2px;
}
.knob-stack .knob-pane .knob-legend:after,
.knob-stack .knob-pane .knob-legend:before {
  position: absolute;
  z-index: 10;
  content: "";
  display: block;
  height: 1px;
  background-color: #bdbfc1;
}
.knob-stack .knob-pane .knob-legend:after {
  width: 176.7766953px;
}
.knob-stack .knob-pane:nth-child(1) .knob-legend {
  left: 5%;
  right: 50%;
  top: 10px;
}
.knob-stack .knob-pane:nth-child(1) .knob-legend:before {
  top: 0;
  right: 0;
  left: 0;
}
.knob-stack .knob-pane:nth-child(1) .knob-legend:after {
  width: 0;
}
.knob-stack .knob-pane:nth-child(2):after {
  top: 20px;
}
.knob-stack .knob-pane:nth-child(2) .knob-legend {
  left: 50%;
  right: 5%;
  top: 30px;
  text-align: right;
}
.knob-stack .knob-pane:nth-child(2) .knob-legend:before {
  top: 0;
  right: 0;
  left: 0;
}
.knob-stack .knob-pane:nth-child(2) .knob-legend:after {
  width: 0;
}
.knob-stack .knob-pane:nth-child(3):after {
  top: 40px;
}
.knob-stack .knob-pane:nth-child(3) .knob-legend {
  left: 5%;
  right: 50%;
  top: 175px;
}
.knob-stack .knob-pane:nth-child(3) .knob-legend:before {
  top: 0;
  right: 125px;
  left: 0;
}
.knob-stack .knob-pane:nth-child(3) .knob-legend:after {
  right: -25.88834765px;
  top: -62.5px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.knob-stack .knob-pane:nth-child(4):after {
  top: 60px;
}
.knob-stack .knob-pane:nth-child(4) .knob-legend {
  left: 50%;
  right: 5%;
  top: 195px;
  text-align: right;
}
.knob-stack .knob-pane:nth-child(4) .knob-legend:before {
  top: 0;
  right: 0;
  left: 125px;
}
.knob-stack .knob-pane:nth-child(4) .knob-legend:after {
  left: -25.88834765px;
  top: -62.5px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.knob-stack .knob {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.knob-default-dark {
  color: #2B2A65;
}
.knob-default-dark-track {
  border-color: #2B2A65;
}
.knob-default {
  color: #e5e6e6;
}
.knob-default-track {
  border-color: #e5e6e6;
}
.knob-default-light {
  color: #f2f2f3;
}
.knob-default-light-track {
  border-color: #f2f2f3;
}
.knob-default-bright {
  color: #ffffff;
}
.knob-default-bright-track {
  border-color: #ffffff;
}
.knob-primary-dark {
  color: #164271;
}
.knob-primary-dark-track {
  border-color: #164271;
}
.knob-primary {
  color: #2267a2;
}
.knob-primary-track {
  border-color: #2267a2;
}
.knob-primary-light {
  color: #78a6ce;
}
.knob-primary-light-track {
  border-color: #78a6ce;
}
.knob-primary-bright {
  color: #c7dbec;
}
.knob-primary-bright-track {
  border-color: #c7dbec;
}
.knob-accent-dark {
  color: #1a237e;
}
.knob-accent-dark-track {
  border-color: #1a237e;
}
.knob-accent {
  color: #14BF6E;
}
.knob-accent-track {
  border-color: #14BF6E;
}
.knob-accent-light {
  color: #81d4fa;
}
.knob-accent-light-track {
  border-color: #81d4fa;
}
.knob-accent-bright {
  color: #f0f4c3;
}
.knob-accent-bright-track {
  border-color: #f0f4c3;
}
.knob-success {
  color: #4caf50;
}
.knob-success-track {
  border-color: #4caf50;
}
.knob-warning {
  color: #ff9800;
}
.knob-warning-track {
  border-color: #ff9800;
}
.knob-danger {
  color: #f44336;
}
.knob-danger-track {
  border-color: #f44336;
}
.knob-info {
  color: #2196f3;
}
.knob-info-track {
  border-color: #2196f3;
}
.flot {
  min-height: 20px;
}
.flot div.legend > div {
  border: 1px solid rgba(255, 255, 255, 0.4);
}
.flot div.legend > table {
  color: inherit !important;
}
.flot div.legend > table td {
  padding: 4px;
}
.legendColorBox > div {
  margin: 5px;
  border: none!important;
}
.legendColorBox > div > div {
  border-radius: 50%;
}
.flot-legend-horizontal table tr {
  float: left;
  padding-right: 8px;
}
.piechart {
  position: relative;
  display: inline-table;
  min-width: 10px;
  min-height: 10px;
  text-align: center;
}
.piechart canvas {
  position: absolute;
  top: 0;
  left: 0;
}
.piechart .display {
  display: table-cell;
  width: 100%;
  vertical-align: middle;
}
.piechart .display .percent:after {
  content: '%';
  font-weight: 300;
  margin-left: 0.1em;
  font-size: .6em;
}
.jqstooltip {
  box-sizing: content-box;
}
.modal {
  color: #313335;
}
.modal-content {
  border-radius: 6px;
}
.modal-header {
  border-radius: 6px 6px 0 0;
}
.modal-footer {
  border-radius: 0 0 6px 6px;
}
.popover {
  color: #313335;
}
.gui-popover {
  position: fixed;
  background-color: #f2f2f3;
  color: #313335;
}
.gui-popover .popover-title {
  background-color: #d8d9da;
  color: rgba(49, 51, 53, 0.9);
  border-bottom-color: rgba(49, 51, 53, 0.2);
}
.gui-popover.top > .arrow :after {
  border-top-color: #f2f2f3;
}
.gui-popover.right > .arrow:after {
  border-right-color: #f2f2f3;
}
.gui-popover.bottom > .arrow:after {
  border-bottom-color: #f2f2f3;
}
.gui-popover.left > .arrow:after {
  border-left-color: #f2f2f3;
}
.gui-popover.popover-info {
  background-color: #2196f3;
  color: #ffffff;
}
.gui-popover.popover-info .popover-title {
  background-color: #0c7cd5;
  color: rgba(255, 255, 255, 0.9);
  border-bottom-color: rgba(255, 255, 255, 0.2);
}
.gui-popover.popover-info.top > .arrow :after {
  border-top-color: #2196f3;
}
.gui-popover.popover-info.right > .arrow:after {
  border-right-color: #2196f3;
}
.gui-popover.popover-info.bottom > .arrow:after {
  border-bottom-color: #2196f3;
}
.gui-popover.popover-info.left > .arrow:after {
  border-left-color: #2196f3;
}
.gui-popover.popover-warning {
  background-color: #ff9800;
  color: #ffffff;
}
.gui-popover.popover-warning .popover-title {
  background-color: #cc7a00;
  color: rgba(255, 255, 255, 0.9);
  border-bottom-color: rgba(255, 255, 255, 0.2);
}
.gui-popover.popover-warning.top > .arrow :after {
  border-top-color: #ff9800;
}
.gui-popover.popover-warning.right > .arrow:after {
  border-right-color: #ff9800;
}
.gui-popover.popover-warning.bottom > .arrow:after {
  border-bottom-color: #ff9800;
}
.gui-popover.popover-warning.left > .arrow:after {
  border-left-color: #ff9800;
}
.gui-popover.popover-success {
  background-color: #4caf50;
  color: #ffffff;
}
.gui-popover.popover-success .popover-title {
  background-color: #3d8b40;
  color: rgba(255, 255, 255, 0.9);
  border-bottom-color: rgba(255, 255, 255, 0.2);
}
.gui-popover.popover-success.top > .arrow :after {
  border-top-color: #4caf50;
}
.gui-popover.popover-success.right > .arrow:after {
  border-right-color: #4caf50;
}
.gui-popover.popover-success.bottom > .arrow:after {
  border-bottom-color: #4caf50;
}
.gui-popover.popover-success.left > .arrow:after {
  border-left-color: #4caf50;
}
.gui-popover.popover-danger {
  background-color: #f44336;
  color: #ffffff;
}
.gui-popover.popover-danger .popover-title {
  background-color: #ea1c0d;
  color: rgba(255, 255, 255, 0.9);
  border-bottom-color: rgba(255, 255, 255, 0.2);
}
.gui-popover.popover-danger.top > .arrow :after {
  border-top-color: #f44336;
}
.gui-popover.popover-danger.right > .arrow:after {
  border-right-color: #f44336;
}
.gui-popover.popover-danger.bottom > .arrow:after {
  border-bottom-color: #f44336;
}
.gui-popover.popover-danger.left > .arrow:after {
  border-left-color: #f44336;
}
.carousel-control.left {
  background: none;
}
.carousel-control.right {
  background: none;
}
/** initial setup **/
.nano {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.nano > .nano-content {
  position: absolute;
  overflow: scroll;
  overflow-x: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.nano > .nano-content:focus {
  outline: thin dotted;
}
.nano > .nano-content::-webkit-scrollbar {
  display: none;
}
.nano > .nano-pane {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
  width: 10px;
  right: 0;
  top: 0;
  bottom: 0;
  /* Target only IE7 and IE8 with this hack */
  visibility: hidden\9;
  opacity: .01;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}
.nano > .nano-pane > .nano-slider {
  background: #444;
  background: rgba(0, 0, 0, 0.5);
  position: relative;
  margin: 0 1px;
}
.nano:hover > .nano-pane,
.nano-pane.active,
.nano-pane.flashed {
  /* Target only IE7 and IE8 with this hack */
  visibility: visible\9;
  opacity: 0.99;
}
.has-scrollbar > .nano-content::-webkit-scrollbar {
  display: block;
}
.holder {
  position: relative;
}
.no-radius {
  border-radius: 0;
}
.opacity-25 {
  opacity: 0.25;
  filter: alpha(opacity=25);
}
.opacity-50 {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.opacity-75 {
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.opacity-100 {
  opacity: 1;
  filter: alpha(opacity=100);
}
.no-margin {
  margin: 0 !important;
}
.margin-bottom-lg {
  margin-bottom: 5px;
}
.margin-bottom-xl {
  margin-bottom: 10px;
}
.margin-bottom-xxl {
  margin-bottom: 20px;
}
.margin-top-lg {
  margin-top: 5px;
}
.margin-top-xl {
  margin-top: 10px;
}
.margin-top-xxl {
  margin-top: 20px;
}
.no-padding {
  padding: 0 !important;
}
.force-padding {
  padding: 24px;
}
.large-padding {
  padding: 48px;
}
.small-padding {
  padding: 12px;
}
.no-y-padding {
  padding: 0 24px;
}
.no-side-padding {
  padding: 24px 0;
}
.border-gray,
.border-black,
.border-white,
.border-lg,
.border-xl,
.border-xxl {
  border-style: solid;
  border-width: 1px;
  border-color: #0c0c0c;
}
.border-lg {
  border-width: 2px;
}
.border-xl {
  border-width: 3px;
}
.border-xxl {
  border-width: 5px;
}
.border-white {
  border-color: #ffffff;
}
.border-gray {
  border-color: #96999c;
}
.border-transparent {
  border-color: transparent;
}
.hidden-border {
  border-color: transparent !important;
}
.border-dashed {
  border-style: dashed;
}
.overlay {
  position: absolute;
  left: 0;
  right: 0;
  padding: 15px;
}
.overlay-default {
  background-color: rgba(150, 153, 156, 0.75);
  color: #ffffff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}
.overlay-primary {
  background-color: rgba(34, 103, 162, 0.75);
  color: #ffffff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}
.overlay-shade-top {
  background-image: -webkit-linear-gradient(top, rgba(12, 12, 12, 0.6) 0%, rgba(12, 12, 12, 0) 100%);
  background-image: -o-linear-gradient(top, rgba(12, 12, 12, 0.6) 0%, rgba(12, 12, 12, 0) 100%);
  background-image: linear-gradient(to bottom, rgba(12, 12, 12, 0.6) 0%, rgba(12, 12, 12, 0) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#990c0c0c', endColorstr='#000c0c0c', GradientType=0);
}
.overlay-shade-bottom {
  background-image: -webkit-linear-gradient(top, rgba(12, 12, 12, 0) 0%, rgba(12, 12, 12, 0.6) 100%);
  background-image: -o-linear-gradient(top, rgba(12, 12, 12, 0) 0%, rgba(12, 12, 12, 0.6) 100%);
  background-image: linear-gradient(to bottom, rgba(12, 12, 12, 0) 0%, rgba(12, 12, 12, 0.6) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000c0c0c', endColorstr='#990c0c0c', GradientType=0);
}
.mask {
  position: relative;
  overflow: hidden;
}
.bottom-layer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
}
.top-layer {
  position: relative;
  z-index: 1;
}
.stick-top-left,
.stick-top-right,
.stick-top-left-right {
  position: absolute;
  top: 0;
}
.stick-bottom-left,
.stick-bottom-right,
.stick-bottom-left-right {
  position: absolute;
  bottom: 0;
}
.stick-top-left,
.stick-bottom-left,
.stick-top-left-right,
.stick-bottom-left-right {
  left: 0;
}
.stick-top-right,
.stick-bottom-right,
.stick-top-left-right,
.stick-bottom-left-right {
  right: 0;
}
.height-1,
.size-1 {
  height: 40px;
}
.height-2,
.size-2 {
  height: 80px;
}
.height-3,
.size-3 {
  height: 120px;
}
.height-4,
.size-4 {
  height: 160px;
}
.height-5,
.size-5 {
  height: 200px;
}
.height-6,
.size-6 {
  height: 240px;
}
.height-7,
.size-7 {
  height: 280px;
}
.height-8,
.size-8 {
  height: 320px;
}
.height-9,
.size-9 {
  height: 360px;
}
.height-10,
.size-10 {
  height: 400px;
}
.height-11,
.size-11 {
  height: 440px;
}
.height-12,
.size-12 {
  height: 480px;
}
.width-1,
.size-1 {
  width: 40px;
}
.width-2,
.size-2 {
  width: 80px;
}
.width-3,
.size-3 {
  width: 120px;
}
.width-4,
.size-4 {
  width: 160px;
}
.width-5,
.size-5 {
  width: 200px;
}
.width-6,
.size-6 {
  width: 240px;
}
.width-7,
.size-7 {
  width: 280px;
}
.width-8,
.size-8 {
  width: 320px;
}
.width-9,
.size-9 {
  width: 360px;
}
.width-10,
.size-10 {
  width: 400px;
}
.width-11,
.size-11 {
  width: 440px;
}
.width-12,
.size-12 {
  width: 480px;
}
.auto-width {
  width: auto;
}
@media (min-width: 769px) {
  .scroll-xs {
    height: auto !important;
  }
}
@media (min-width: 992px) {
  .scroll-sm {
    height: auto !important;
  }
}
@media (min-width: 1200px) {
  .scroll-md {
    height: auto !important;
  }
}
.contain-xs {
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
}
.contain-sm {
  max-width: 769px;
  margin-left: auto;
  margin-right: auto;
}
.contain-md {
  max-width: 992px;
  margin-left: auto;
  margin-right: auto;
}
.contain-lg {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.star-rating,
.demo-color {
  display: inline-block;
}
pre.prettyprint {
  display: block;
  background-color: #f7f7f9;
  font-size: 13px;
  padding: 9px 14px !important;
  border-color: #e1e1e8 !important;
  tab-size: 2;
  -moz-tab-size: 2;
  -o-tab-size: 2;
}
pre .nocode {
  background-color: none;
  color: #ffffff;
}
pre .str {
  color: #da4950;
}
/* string */
pre .kwd {
  color: #2f6f9f;
}
/* js function */
pre .com {
  color: #999999;
}
/* comment */
pre .typ {
  color: #2f6f9f;
}
/* type */
pre .lit {
  color: #cd5c5c;
}
/* literal */
pre .pun {
  color: #4f9fcf;
}
/* punctuation */
pre .pln {
  color: #333333;
}
/* plaintext */
pre .tag {
  color: #2f6f9f;
}
/* html/xml tag */
pre .atn {
  color: #4f9fcf;
}
/* attribute name */
pre .atv {
  color: #da4950;
}
/* attribute value */
pre .dec {
  color: #009999;
}
/* decimal */
/* Specify class=linenums on a pre to get line numbering */
ol.linenums {
  margin-top: 0;
  margin-bottom: 0;
  color: #aeaeae;
}
/* IE indents via margin-left */
li.L0,
li.L1,
li.L2,
li.L3,
li.L5,
li.L6,
li.L7,
li.L8 {
  list-style-type: none;
}
/* Alternate shading for lines */
.capital-first {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
}
.capital-first > div > h1 {
  text-transform: uppercase;
  font-weight: 400;
  color: #191a1a;
  font-size: 32px;
  margin: 0;
  padding: 0;
}
.capital-first > div:first-child {
  text-shadow: none;
  font-size: 80px;
  line-height: 66px;
  vertical-align: top;
  margin-right: 5px;
  min-width: 55px;
  font-weight: 300;
  color: #2267a2;
  margin: 0;
  padding: 0;
}
.capital-first > div > p {
  font-size: 16px;
  font-weight: normal;
  text-transform: none;
  color: #313335;
  margin: 0;
  padding: 0;
}

.panel-heading {
  border-bottom: 1px solid #bdbfc1;
}
.service-menu.nav > li > a:hover,
.service-menu.nav > li > a:focus {
  text-decoration: none;
  background-color: #2267a2;
}
.service-menu.nav li.active rect {
  fill: #fff;
}
.service-menu.nav li.active a {
  background-color: #2267a2;
}
rect.nav-bg {
  fill: #14BF6E;
}
.user-steps-menu-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.user-steps-menu {
  background-color: #2B2A65;
  color: #ffffff;
  padding: 11px 0;
  /* Below rules needed for fixed view */
  width: 100%;
  z-index: 3;
}
.user-steps-menu a {
  color: #ffffff;
}
.user-steps-menu-fixed-top-placeholder {
  height: 66px;
}
.user-forms > .user-steps-menu-fixed-top-placeholder:first-child {
  margin-top: 0;
}
.user-steps-menu-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.user-steps-menu input[type=checkbox] {
  display: none;
}
.user-steps-menu-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  list-style-type: none;
  line-height: 22px;
  margin-bottom: 0;
  padding: 0;
}
.user-steps-menu-list li {
  margin: 0 2px;
  border: none;
  margin: 0 15px;
  background: white;
  border-radius: 5px 0 0 5px;
  position: relative;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
  height: 44px;
}
.user-steps-toggle {
  padding: 0;
  margin: 0;
  width: 100%;
  float: none;
}
.navbar-collapse {
  padding: 0;
}
.user-steps-navbar-header {
  display: flex;
  justify-content: center;
  padding: 0 10px;
}
label.user-steps-menu-show {
  cursor: pointer;
  display: none;
  width: 100%;
}
@media only screen and (max-width: 760px) {
  .user-steps-menu-list li > a {
    font-size: 14px;
  }
}
.user-steps-menu-list li {
  margin: 0 15px;
  background: white;
  border-radius: 5px 0 0 5px;
  position: relative;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}
.user-steps-menu-list li:after {
  content: url('../../images/arrow-mask.png');
  position: absolute;
  right: 0;
}
.user-steps-menu-list li:first-child {
  margin-left: 0;
}
.user-steps-menu-list li:last-child {
  margin-right: 0;
}
.user-steps-menu-list li > a {
  width: 100%;
  white-space: nowrap;
  background-color: transparent;
  border-radius: 6px;
  color: #949494;
  display: inline-block;
  font-size: 18px;
  padding: 11px 22px 11px 10px;
  text-align: left;
  text-decoration: none;
  position: relative;
  z-index: 4;
}
.user-steps-menu-list li.active > a {
  color: #000;
}
.user-steps-menu-item-progress {
  height: 100%;
  top: 0;
  width: 0;
  position: absolute;
  border-radius: 5px 0 0 5px;
  border-right: none;
  opacity: .9;
}

@media only screen and (max-width: 780px) {
  .user-steps-menu-list li > a {
    font-size: 14px;
  }
}
/*
Styles applied while screen size is less than 640px
*/
@media only screen and (max-width: 769px) {
  .user-steps-menu-list {
    display: block;
  }
  .user-steps-menu-show {
    display: block;
    background-color: #ffffff;
    border-radius: 6px;
    color: black;
    font-size: 18px;
    padding: 11px 0 11px 20px;
    text-align: center;
  }
  .user-steps-menu-list li {
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    margin: 10px 0 0 0;
  }
  .user-steps-menu-list li > a {
    font-size: 18px;
    text-align: center;
  }
  .navbar-collapse {
    padding: 0 12px 0 12px;
  }
  .navbar-nav {
    margin: 0;
  }
}
.bigfont {
  line-height: 44px !important;
  font-size: 44px !important;
}
.has-feedback input[type=number].form-control {
  padding-right: 9.5px;
}
.input-group input[type=currency].form-control {
  border-radius: 0 6px 6px 0 !important;
}
.nav-tabs > li {
  height: 48px;
}
.line-height-sm {
  line-height: 16px;
}
.loader {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: auto;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
