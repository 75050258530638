
.alert {
    line-height: 1.4 !important;
    font-size: 14px;
    font-weight: 400 !important;
    padding: 0 1rem !important;
    margin: 0;
    margin-bottom: 1.5rem;
    position: relative !important;
    color: @dark !important;
    border: none !important;
    background: transparent !important;
    display: block;
    width: 100%;

    &.m-0 {
        margin: 0 !important;
    }
    &:after {
        content: " " !important;
        height: calc(100% - 0.48rem) !important;
        top: 0.325rem !important;
        width: 2px !important;
        inset-inline-start: 0 !important;
        position: absolute !important;
        background: @blue !important;
        display: block !important;
    }

    &.alert-success {
        &:after {
            background: @success !important;
        }
    }

    &.alert-warning {
        &:after {
            background: @warning !important;
        }
    }

    &.alert-danger {
        &:after {
            background: @danger !important;
        }
    }

    p {
        &:only-child,
        &:last-child {
            margin: 0 !important;
        }
    }

}

.ui-notification {
    margin: 0;
    background: white;
    padding: 0;
    border-radius: 0 3px 3px 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
    position: fixed;
    z-index: 999999;
    top: auto !important;
    inset-inline-start: auto !important;
    inset-inline-end: 2.4rem !important;
    bottom: 2.4rem;
    font-size: 1.8rem;

    .message,
    .toast {

        &.toast-info {
            border-inline-start: 1px solid @yellow-dark;
            background: @yellow;
            color: @yellow-dark;
            padding: 0.6rem 1.2rem;
            .toast-close-button {
                color: @yellow-dark !important;
            }
        }

        &.toast-error {
            border-inline-start: 1px solid @danger;
        }

        &.toast-success {
            border-inline-start: 1px solid @success;
        }
    }

    &.success {
        .message {
            border-inline-start: 1px solid @success;
            background: @success-light;
            color: @white;
            padding: 0.6rem 1.2rem;
            font-size: 1.8rem !important;
        }
    }

    &.error {
        .message {
            border-inline-start: 1px solid @yellow-dark;
            background: @yellow;
            color: @yellow-dark;
            padding: 0.6rem 1.2rem;
        }
    }

    &.warning {
        .message {
            border-inline-start: 1px solid @yellow-dark;
            background: @yellow;
            color: @yellow-dark;
            padding: 0.6rem 1.2rem;
        }
    }

    &.killed {
        .message {
            display: none;
        }
    }

    .toast-close-button {
        position: relative;
        top: -0.3em;
        float: right;
        font-size: 20px;
        font-weight: bold;
        color: @grey;
        opacity: 0.8;
        filter: alpha(opacity=80);
    }

    button.toast-close-button {
        padding: 0;
        cursor: pointer;
        background: transparent;
        border: 0;
        -webkit-appearance: none;
    }
}

.callback-error {
    margin: 20px 0 !important;
    border-inline-start: 1px solid @yellow-dark;
    background: @yellow;
    color: @yellow-dark;
    padding: 0.6rem 1.2rem !important;
    
    >.panel {
        background: transparent;
        >.panel-heading {
            padding: 0 !important;
            margin-bottom: 1.3rem !important;
            >h3 {
                color: @yellow-dark !important;
                font-size: 1.8rem !important;
                line-height: 1.8 !important;
            }
        }
        >.panel-body {
            padding: 0 !important;
            .alert {
                padding: 0 !important;
                border: none !important;
                color: @yellow-dark !important;
                font-size: 1.8rem !important;
                line-height: 1.8 !important;
                &:after {
                    display: none !important;
                }
            }
        }
    }
}

