.btn-default {
    color: @dark;
    &:not([data-toggle="tooltip"]) {
        border-color: @greylines;
    }
}

#partb-fields-changed {
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 18px;
    text-align: center;
    line-height: 20px;
    position: fixed;
    top: calc(50% - 10px);
    z-index: 1041;
    inset-inline-end: 29px;
}

.button {
    text-decoration: none;
    border-radius: 2px !important;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    box-shadow: 0 1px 3px rgba(0,0,0,0.16) !important;
    width: auto;
    padding: .5rem 1rem;
    display: block;
    text-align: center;
    transition: all .3s;
    line-height: 1;

    &.button-primary {
        color: @white;
        background-color: @primary;
        border-color: @primary;

        &:active,
        &:focus,
        &:hover {
            border-color: @primary !important;
            background-color: darken(@primary, 5%) !important;
            color: @white;
        }

        &.disabled {
            color: @white !important;
            background-color: fade(@primary,50%) !important;
        }
    }

    &.button-success {
        color: @white;
        background-color: @success;
        border-color: @success;

        &:active,
        &:focus,
        &:hover {
            border-color: @success !important;
            background-color: darken(@success, 5%) !important;
            color: @white;
            outline: none;
        }

        &.disabled {
            color: @white !important;
            background-color: fade(darken(@success, 5%),50%) !important;
        }
    }

    &.button-warning {
        color: @white;
        background-color: @warning;
        border-color: @warning;

        &:active,
        &:focus,
        &:hover {
            border-color: @warning !important;
            background-color: darken(@warning, 5%) !important;
            color: @white;
            outline: none;
        }

        &.disabled {
            color: @white !important;
            background-color: fade(darken(@warning, 5%),50%) !important;
        }
    }

    &.flying-button {
        position: fixed;
        inset-inline-start: calc(100% - 38px);
        top: 50%;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        z-index: 1040;
        padding: 2rem 2.5rem;
        border: none !important;
        transition: all .2s;

        &.correction-drawer-opener {
            top: calc(50% - 42px);
        }

        &.correction-drawer-opener,
        &.draft-save {
            align-items: baseline;
            border-radius: 32px 0 0 32px !important;
            box-shadow: 0 1px 3px rgba(0, 0, 0, .16) !important;
            display: flex;
            gap: 10px;
            line-height: 13px;
            padding: 8px 10px;
            text-transform: none;
            white-space: nowrap;
            background: white;
            
            span {
                width: auto !important;
                transition: all .2s;
                // writing-mode: vertical-rl;
                // text-orientation: mixed;
                // transform: rotateZ(180deg);
                margin-inline: 0;
            }
            i {
                width: 24px;
                height: 24px;
                line-height: 24px;
                text-align: center;
                border-radius: 32px;
                color: white;
                transition: all .2s;
                &.bg-warning {
                    color: @warningNew;
                    background: @warningNewLight;

                }
                &.bg-success {
                    background: @successNewLight;
                    color: @successNew;
                }
            }
            &.loading {
                i {
                    animation: fa-spin 1s infinite steps(8);

                    &:before {
                        content: "\f110";
                    }
                }
            }
            &:where(:hover, :focus) {
                box-shadow: -10px 5px 30px rgb(162 162 162 / 30%) !important;

                span {
                    margin-inline: 0 !important;
                }
                i {
                    &.bg-warning {
                        background: @warningNew;
                        color: @warningNewLight;
    
                    }
                    &.bg-success {
                        background: @successNew;
                        color: @successNewLight;
                    }
                }
            }
            &::before {
                display: none;
            }
        }

        &.btn2 {
            top: 30%;
            height: 55px;
        }

        span {
            width: 0 !important;
            display: inline-block;
            overflow: hidden;
            vertical-align: top;
            transition: all .2s;
            font-family: "Open Sans", sans-serif, Helvetica, Arial, sans-serif;
        }

        &:focus,
        &:hover {
            border: none !important;
            outline: none;
            transition: all .2s;
            inset-inline-start: unset;
            inset-inline-end: 0px;
            span {
                width: auto !important;
                margin-inline-start: 1rem;
                transition: all .2s;
            }
        }

        &:before {
            font-weight: 700;
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            display: inline-block;
            vertical-align: baseline;
        }

        &.loading {
            &:before {
                content: "\f110";
                animation: fa-spin 1s infinite steps(8);
            }
        }
    }
    
    &.button-grey {
        color: @white;
        background-color: @grey;
        border-color: @grey;

        &:active,
        &:focus,
        &:hover {
            color: @white;
            border-color: @grey !important;
            background-color: darken(@grey, 5%) !important;
        }

        &.disabled {
            color: @white;
            background-color: fade(@grey,50%) !important;
        }
    }

}
@keyframes fa-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}