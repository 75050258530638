.label-status {
    font-size: 12px;
    font-weight: 500;
    border: none;
    background-color: transparent !important;
    position: relative;
    padding: 0 !important;
    color: @dark;
    text-transform: uppercase;

    &::before {
        display: none;
    }

    &.warning {
        color: @warning;
    }

}

.label-default:not(.label-status) {
    background-color: lighten(@grey, 15%);
}
// Pending
.label-info:not(.label-status) {
    background-color: @info;
}
// Validated/completed
.label-success:not(.label-status) {
    background-color: @success-light;
}
// Declined/payment
.label-warning:not(.label-status) {
    background-color: @warning;
}
// Rejected
.label-danger:not(.label-status) {
    background-color: @danger;
}

.popover-file-statuses {
    > span {
        display: inline-block;
        vertical-align: middle;
        margin-inline-end: 6px;
        margin-bottom: 1px;
    }
}
