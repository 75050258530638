.homepage {

    @import (css) url('/assets/fonts/opensans/open-sans.css');

    .container {
        max-width: 960px;
    }

    .jumbotron {
        min-height: 468px;
        background-size: cover;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        border-radius: 0;
        padding: 14rem 2rem;
    }
    
    .home-description {
        margin-top: -88px;
    }
    
    .home-description .card {
        background-color: rgba(255, 255, 255, 0.9);
        border: none;
        border-radius: 0;
        box-shadow: none;
        height: 100%;
    }
    
    .home-description .card .card-title p {
        margin: 0;
        font-family: 'Open Sans', serif;
    }
    
    .ptms-node {
        position: relative;
        border: 1px dotted #ffc107;
        display: inline-block;
        width: auto;
        padding: 4px;
        border-radius: 4px;
    }
    
    .ptms-info {
        position: absolute;
        z-index: 24000;
        top: -10px;
        inset-inline-end: -10px;
        font-size: 10px;
        color: white;
    }
      
    .jumbotron * {
        color: white;
    }

    .jumbotron h1 {
        font-family: 'Open Sans', serif;
        font-weight: 700;
        font-size: 4rem;
    }

    .jumbotron p {
        margin: 0;
    }


    .page-pushes {
        margin-top: -11rem;
    }

    .page-pushes .push-card {
        background: white;
        border-inline-end: 1px solid rgba(90, 186, 205, 0.5);
    }

    .page-pushes .push-card:last-child {
        border-inline-end: none;
    }

    .page-pushes .card-title p {
        margin: 0;
        font-family: 'Open Sans', serif;
        font-size: 2rem;
    }

    .page-pushes ul {
        list-style: none;
        padding: 0;
    }

    .page-pushes ul li {
        padding-inline-start: 1.8em;
        margin-bottom: 12px;
    }

    .page-pushes ul li:before {
    content: "\f00c";
        font-family: 'Font Awesome\ 5 Pro';
        font-size: 1.5rem;
        color: #5abacd;
        display: inline-block;
        margin-inline-start: -1.8em;
        width: 1.8em;
        vertical-align: top;
        font-weight: bold;
    }

    .card {
        border: none;
        text-align: center;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        border-radius: 0;

        .card-body {
            .card-text {
                font-size: 1.6rem;
            }
            &:after {
                display: none !important;
            }
        }
    }

    .card img {
        height: 60px;
    }

    .btn-primary {
        color: white;
        background: linear-gradient(90deg, #79bc83 0, #5abacd);
        border: none;
        padding: .5rem 1.5rem;
        font-size: 2rem;
        line-height: 1.5;
        border-radius: .5rem;
    }

    @media (max-width: 768px) {
        
        .jumbotron {
            padding: 7rem 1rem;
        }
    
        .col-md-6 {
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
        

}