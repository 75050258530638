.message-container {
    border: 1px solid #edeef1;
    padding: 24px;
    padding-inline-end: 48px;
    border-radius: 12px;
    position: relative;
    &:first-child {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        border-top: 1px solid #edeef1;
    }
    &:last-child {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        border-bottom: 1px solid #edeef1;
    }
    h4 {
        margin-bottom: 4px;
        margin-top: 0;
    }
    .message-content {
        margin-top: 8px;
        margin-bottom: 12px;
    }
    .message-tags {
        display: flex;
        gap: 5px;
        color: rgba(107, 114, 128, 1);
        margin-bottom: 0;
    }
    .message-toggler {
        position: absolute;
        cursor: pointer;
        right: 12px;
        width: 24px;
        height: 24px;
        text-align: center;
        line-height: 24px;
        font-size: 16px;
        top: calc(50% - 12px);
        border-radius: 32px;
        background: white;
        transition: all .3s ease-in-out;
        &:hover {
            transition: all .3s ease-in-out;
            font-size: 16px;
            background: #edeef1;
        }
    }
}
