.modal {
    .modal-content {
        .panel-heading {
            .panel-title,
            h2 {
                margin: 0;
            }
        }
    }
}

#select-business-entity {
    &.in {
        .modal-dialog {
            transform: translate(0, 35vh);
        }
    }
    a.button-grey {
        top: -37px !important;
        float: right;
        padding: 11px;
        border-radius: 0 6px 6px 0 !important;
        &:hover,
        &:active {
            text-decoration: none;
        }
    }
    .ui-select-container {
        &.open + a.button-grey {
            border-radius: 0 6px 0 0 !important;
        }
    }
}