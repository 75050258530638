.demo-mode-heading {
    display: block;
    padding: 2.4rem 2.4rem;
    background-color: @dark;
    color: @white;

    & + .section-body {
        margin-top: 2.4rem;
    }

    .row {
        display: block;
        >div {
            flex: none;

            h4 {
                margin-top: 0;
                font-size: 2rem;
            }
        }
    }
}