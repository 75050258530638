.text-success {
    color: @success !important;
}

.text-danger {
    color: @danger !important;
}

.text-warning {
    color: @warning !important;
}

.text-info {
    color: @blue !important;
}

.text-right {
    text-align: end !important;
}

h3 {
    color: @dark;
    font-size: 20px;
    font-weight: 500;
    border: none;
    position: relative;
    padding: 0.5rem 0;
    margin: 1rem 0;
}

.iconed-link {
    padding-inline-start: 16px;
    position: relative;
    display: block;
    >i {
        position: absolute;
        inset-inline-start: 0;
        top: 3px;
    }
}