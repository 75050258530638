.drawer {
    position: fixed;
    z-index: 123456789;
    top: 0;
    bottom: 0;
    width: 800px;
    transition: all .2s;
    padding: 24px 0;
    &.in-page {
        top: 140px;
        height: calc(100% - 140px);
    }
    &.closed {
        transition: all .2s;
        inset-inline-start: 100%;
    }
    &:not(.closed) {
        inset-inline-end: 0;
    }
    display: flex;
    flex-direction: column;
    height: 100%;
    background: white;

    .drawer-header {
        padding-top: 0;
        padding-inline-start: 64px;
        padding-bottom: 24px;
        padding-inline-end: 24px;
        background: white;
        z-index: 1;
        flex: 1;
        flex-grow: 0;
        h2 {
            font-weight: 600;
        }
        .drawer-closer {
            margin-inline-start: auto;
            display: block;
            font-size: 24px;
            width: 24px;
            height: 24px;
            text-align: center;
            margin-inline-end: 0;
            cursor: pointer;
        }
    }
    .drawer-content {
        padding: 0 64px;
        z-index: 1;
        flex: 1;
        flex-grow: 1;
        overflow-y: auto;
    }
}

.drawer-backdrop {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(17, 24, 39, 1);
    top: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    z-index: 123456788;
    opacity: .1;
    transition: all .2s;
    &.closed {
        opacity: 0;
        inset-inline-start: 100%;
        transition: all .2s;
    }
}

.drawer-opener {
    background: white;
    border-radius: 32px;
    box-shadow: 0 1px 3px rgba(0,0,0,.16)!important;
    padding-top: 8px;
    padding-inline-end: 16px;
    padding-bottom: 8px;
    padding-inline-start: 8px;
    transition: all .3s ease-in-out;
    i {
        height: 24px;
        width: 24px;
        font-size: 12px;
        line-height: 24px;
        background: #edeef1;
        border-radius: 24px;
        text-align: center;
        display: inline-block;
        margin-inline-end: 8px;
        transition: all .3s ease-in-out;
    }
    span {
        font-size: 14px;
        font-weight: 600;
    }
    &:hover {
        transition: all .3s ease-in-out;
        box-shadow: -10px 5px 30px rgb(162 162 162 / 30%) !important;
        i {
            transition: all .3s ease-in-out;
            font-size: 14px;
        }
    }
}