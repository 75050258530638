.guide {
    font-size: 13px;

    p {
        margin: 0;
    }

    .formio-component-registrationspanel {
        >.panel {
            >.panel-body {
                padding-top: 0 !important;

                .panel#formio-panel-columnsPanel2Mandatory {
                    margin-top: 0;
                }
            }
        }
    }

    .form-field-type-columns:not(.formio-component-questionscolumns) .panel-body .form-field-type-mycontent,
    .card-body,
    blockquote {
        margin-inline-start: -2.5rem !important;
        margin-inline-end: -2.5rem !important;
        padding: 1.5rem 2.5rem !important;
    }

    .panel {
        border: none;
        border-radius: 0 !important;
        box-shadow: none;
    }
    .form-field-type-panel {
        box-shadow: 0 1px 3px rgba(0,0,0,0.16);
        border-radius: 0;
        margin-bottom: 0;
        padding: 0;

        .form-field-type-panel {
            box-shadow: none;
            padding: 0;

            .panel-body {
                padding: 0 !important;
            }
        }

        + .form-field-type-panel {
            margin-top: -1px;
        }
        .panel {

            &.panel-success {
                background-color: @success;
                color: white;
                .panel-heading {
                    h3 {
                        &.panel-title {
                            font-weight: 500;
                            color: white;
                            .formio-collapse-icon {
                                color: white !important;
                            }
                            &::after {
                                display: none;
                            }
                        }
                    }
                }

                .panel-body {
                    .form-field-type-columns {
                        border-top: 1px solid fade(@white, 30%);

                        .form-group {
                            margin: 0.5rem 0;
                        }

                        +.form-field-type-columns {
                            margin-top: -1px !important;
                        }
                    }
                }
            }

            .panel-heading {
                background-color: transparent;
                border-radius: 0;
                border: none !important;
    
                h3 {
                    &.panel-title {
                        color: @dark;
                        font-size: 20px;
                        font-weight: 500;
                        border: none;
                        position: relative;
                        padding: 0;
                        .formio-collapse-icon {
                            position: absolute;
                            inset-inline-end: 0;
                            border: none;
                            top: 24px;
                            font-size: 16px;
        
                            &.fa-plus {
                                &:before {
                                    content: "\f107";
                                }
                            }
        
                            &.fa-minus {
                                &:before {
                                    content: "\f106";
                                }
                            }
                        }
                    }
                }
                
            }

            .panel-body {
                padding: 0 2.5rem;
            }
    
            .card-body {
                border-radius: 0 !important;
                border: none !important;
                padding: .5rem 0;
                line-height: 1.2;
                margin-top: 0 !important;
                margin-bottom: 3rem !important;
    
                .control-label:not(.form-check-label) {
                    font-size: 12px;
                    opacity: .85;
                    font-weight: 700;
                    margin-bottom: .2rem;
                }
                .formio-component {
                    .card-body {
                        border-inline-start: 2px solid @blue !important;
                        padding: 0 .75rem;
                        margin-bottom: 1.25rem;
                        font-size: 13px;
                    }
                }
    
            }
        }
        
        &.block-fees {
            .panel {
                .panel-heading {
                    h3 {
                        &.panel-title {
                            &::after {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        span.card-title {
            display: inline-block;
            margin: 2.5rem 0;
            font-weight: 500;
            color: @dark;
            font-size: 20px;
            font-weight: 500;
            border: none;
            position: relative;
            padding: 0;
            vertical-align: middle;
            line-height: 1;
        }
    }

    .block-fees {
        box-shadow: 0 1px 3px rgba(0,0,0,0.16);
        border-radius: 0;
        margin-bottom: 0;
        padding: 0;
        color: @white;
        background-color: @success;
        margin-top: 0 !important;

        .formio-component-feeTitleColumns {
            margin: 0 !important;

            >.row {
                position: relative;
                >div {
                    &:first-child {
                        width: 100% !important;
                        z-index: 123;

                        .form-field-type-button {
                            position: relative;

                            &:before {
                                content: "\F078";
                                font-family: 'Font Awesome 5 Pro';
                                font-weight: 500;
                                -moz-osx-font-smoothing: grayscale;
                                -webkit-font-smoothing: antialiased;
                                display: inline-block;
                                font-style: normal;
                                font-variant: normal;
                                text-rendering: auto;
                                line-height: 1;
                                color: @white;
                                font-size: 1.25rem;
                                position: absolute;
                                top: calc( 50% - 0.4rem );
                                inset-inline-end: 1rem;
                            }

                            &.opened {
                                &:before {
                                    content: "\F077";
                                }
                            }
                        }
                    }
                    &:last-child {
                        width: auto !important;
                        position: absolute;
                        inset-inline-end: 0;
                        height: 100%;
                        vertical-align: middle;
                        margin-inline-end: 2rem;
                        font-size: 2.2rem;
                        font-weight: 500;
                        padding: 2rem;
                        line-height: 1.6;
                        margin-top: 0;
                    }
                }
            }
        }

        .form-field-type-htmlelement {
            .card-body {
                margin-inline-start: 2rem !important;
                margin-inline-end: 2rem !important;
                margin-top: 0 !important;
                color: @white !important;
                background: transparent !important;
                &:after {
                    background: @white !important;
                }
            }
        }

        .form-field-type-columns:not(.formio-component-feeTitleColumns) {
            border-top: 1px solid rgba(255, 255, 255, 0.3);
            margin-inline-start: 2rem !important;
            margin-inline-end: 2.75rem !important;
            margin-top: 0 !important;
            padding: .5rem;
            .form-group {
                margin: 0;
                p {
                    padding-top: 5px;
                    line-height: 16px;
                    margin-bottom: 5px;
                    font-weight: 500;
                }
                span {
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }

        .form-field-type-button {
            margin: 0;
        }

        button {
            width: 100%;
            border-radius: 0;
            margin: 0!important;
            background: transparent;
            border: none;
            font-size: 20px;
            font-weight: 500;
            border: none;
            position: relative;
            padding: 2rem;
            line-height: 1.6;
            text-align: start;
            letter-spacing: -0.2pt;
        }
        .form-group {
            margin-top: 0 !important;
        }
    }

    .formio-component-columnsPrint {
        margin-top: 1.5rem !important;
    }

    .block-conditions {
        box-shadow: 0 1px 3px rgba(0,0,0,0.16);
        border-radius: 0;
        margin-bottom: 0;
        padding: 0;
        color: @dark;
        background-color: @white;
        border-top: 1px solid fade(@grey, 25%);

        .form-field-type-columns:not(.formio-component-conditionTitleColumns) {
            
            .form-group {
                margin: 0;
                p {
                    padding-top: 5px;
                    line-height: 16px;
                    margin-bottom: 5px;
                    font-weight: 100;
                }
                span {
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }

        .formio-component-ConditionsRows {
            margin-bottom: 0;
            padding: 0 2rem;
            .card-body {
                margin: 1rem 0;
                padding: 0;
                margin-top: 0 !important;
            }

            .col-sm-4 {
                flex: 2 !important;
                border-top: 0.1rem solid @main-bg;
                padding-inline-start: 0;
                li {
                    border-top: none !important;
                }
            }

            .col-sm-2 {
                flex: 0 !important;
                border-top: 0.1rem solid @main-bg;
                padding-inline-end: 0;
            }
        }

        .form-field-type-button {
            margin: 0;
        }

        button {
            width: 100%;
            border-radius: 0;
            margin: 0!important;
            background: transparent;
            color: @dark;
            font-size: 20px;
            font-weight: 500;
            border: none;
            position: relative;
            padding: 2rem;
            line-height: 1.6;
            text-align: start;
            text-transform: capitalize;
            letter-spacing: -0.2pt;

            &.btn-link {
                font-size: 1.2rem;
                padding: 1rem 0;
                position: relative;
                line-height: 16px;
            }
        }

        .form-group.form-field-type-columns {
            & + .form-group.form-field-type-columns {
                margin: 0 !important;
            }
        }

        > div {
            > .input-group {
                > .formio-container-component {
                    .formio-component-conditionTitleColumns {
                        padding: 0;
                        margin: 0;

                        > .row {
                            position: relative;

                            > div {
                                &:first-child {
                                    width: 100% !important;
                                    z-index: 123;
            
                                    .form-field-type-button {
                                        position: relative;
            
                                        &:before {
                                            content: "\F078";
                                            font-family: 'Font Awesome 5 Pro';
                                            font-weight: 500;
                                            -moz-osx-font-smoothing: grayscale;
                                            -webkit-font-smoothing: antialiased;
                                            display: inline-block;
                                            font-style: normal;
                                            font-variant: normal;
                                            text-rendering: auto;
                                            line-height: 1;
                                            color: @dark;
                                            font-size: 1.25rem;
                                            position: absolute;
                                            top: calc( 50% - 0.4rem );
                                            inset-inline-end: 1rem;
                                        }
            
                                        &.opened {
                                            &:before {
                                                content: "\F077";
                                            }            
                                            button {
                                                color: @dark;
                                                &:after {
                                                    display: block;
                                                }
                                            }
                                        }
                                    }
                                }
                                .form-field-type-mycontent {
                                    position: absolute;
                                    top: 0;
                                    inset-inline-end: 4rem;
                                    margin: 0;
                                    color: #404451;
                                    font-size: 1.7rem;
                                    font-weight: 500;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                }
                                .formio-component-conditionOpenCloseButton {
                                    margin-top: 0 !important;
                                }
                            }
                        }
            
                        +.form-field-type-container {
                            padding-bottom: 1rem;
            
                            .row {
                                margin: 0;
                            }
                        }
                    }
                    .formio-component-ConditionsRows {
                        margin: 0;

                        > div {
                            > .input-group {
                                > .formio-container-component {
                                    .form-field-type-columns {
                                        margin: 0;

                                        .form-field-type-button {
                                            margin-top: 0 !important;

                                            button {
                                                transition: none;
                                                padding: 0.5rem;
                                            }
                                        }
                                        > .row {
                                            justify-content: space-between;

                                            > [class^="col-"] {
                                                align-items: center;
                                                display: flex;
                                                flex: auto !important;
                                                width: auto;

                                                &:first-of-type {
                                                    width: 100%;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .block-requirements {
        box-shadow: 0 1px 3px rgba(0,0,0,0.16);
        border-radius: 0;
        margin-bottom: 0;
        padding: 0;
        color: @dark;
        background-color: @white;
        margin-top: 0 !important;

        .formio-component-requirementTitleColumns {
            padding: 0;
            margin: 0 !important;

            >.row {
                position: relative;
                >div {
                    &:first-child {
                        width: 100% !important;
                        z-index: 123;

                        .form-field-type-button {
                            position: relative;

                            &:before {
                                content: "\F078";
                                font-family: 'Font Awesome 5 Pro';
                                font-weight: 500;
                                -moz-osx-font-smoothing: grayscale;
                                -webkit-font-smoothing: antialiased;
                                display: inline-block;
                                font-style: normal;
                                font-variant: normal;
                                text-rendering: auto;
                                line-height: 1;
                                color: @dark;
                                font-size: 1.25rem;
                                position: absolute;
                                top: calc( 50% - 0.4rem );
                                inset-inline-end: 1rem;
                            }

                            &.opened {
                                &:before {
                                    content: "\F077";
                                }

                                button {
                                    color: @dark;
                                    &:after {
                                        display: block;
                                    }
                                }
                            }

                        }
                    }

                }

                .form-field-type-mycontent {
                    position: absolute;
                    top: 2rem;
                    inset-inline-end: 4rem;
                    margin: 0;
                    color: @dark;
                    font-size: 1.7rem;
                    font-weight: 500;
                }
            }

            /*+.form-field-type-container {
                padding-bottom: 1rem;
            }*/
        }

        .form-field-type-columns:not(.formio-component-requirementTitleColumns) {
            margin-inline-start: 2rem !important;
            margin-inline-end: 2.75rem !important;
            padding: .5rem;
            .form-group {
                margin: 0;
                p {
                    padding-top: 5px;
                    line-height: 16px;
                    margin-bottom: 5px;
                    font-weight: 100;
                }
                span {
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }

        .formio-component-requirementsRows {
            margin-bottom: 0;
            margin-top: 0;
            padding: 0 2rem;
            .card-body {
                margin: 0 -2rem !important;
                padding: 0;
                border-radius: 0;
            }
        }

        .form-field-type-button {
            margin: 0;
        }

        button {
            width: 100%;
            border-radius: 0;
            margin: 0!important;
            background: transparent;
            color: @dark;
            font-size: 20px;
            font-weight: 500;
            border: none;
            position: relative;
            padding: 2rem;
            line-height: 1.6;
            text-align: start;
            text-transform: capitalize;
            letter-spacing: -0.2pt;
        }
    }

    .form-field-type-htmlelement {
        margin: 0;

        .card-body {
            margin-top: 0.5rem;
            margin-bottom: 1rem;
            margin-inline: 0;
            padding: 0 2rem;
            line-height: 1.2;
        }
    }

    .form-field-type-htmlelement {
        .glyphicon-menu-right {
            position: absolute;
            top: 45%;
            inset-inline-start: 5%;
            color: #d4d8de;
            &:before {
                border-style: solid;
                border-width: .35em 0.35em 0 0;
                content: '';
                display: inline-block;
                height: 3em;
                inset-inline-start: 0.15em;
                position: relative;
                top: 0.15em;
                -webkit-transform: rotate(-45deg);
                transform: rotate(45deg);
                vertical-align: top;
                width: 3em;
            }
        }
    }

    li {
        >div {
            display: inline-block;
            &.ui-select-choices-row {
                display: block;
            }
            a {
                width: auto !important;
                display: inline-block !important;
            }
        }
        list-style: none;
        padding: 1rem 0;
        position: relative;
        border-top: 0.1rem solid @main-bg;
        line-height: 1.6rem;

    }

    .ui-select-container .dropdown-menu:nth-child(4) {
        /*top: 57px !important;*/
    }

    #formio-panel-questionspanel {
        .form-field-type-mytooltip {
            a {
                position: absolute;
                top: -0.4rem;
                border: none;
                
                >i {
                    &:after {
                        content: "\F059";
                        font-family: 'Font Awesome 5 Pro';
                        font-weight: 700;
                        -moz-osx-font-smoothing: grayscale;
                        -webkit-font-smoothing: antialiased;
                        display: inline-block;
                        font-style: normal;
                        font-variant: normal;
                        text-rendering: auto;
                        line-height: 1;
                        color: @grey;
                        font-size: 1.8rem;
                    }
                }
                &:focus {
                    background-color: transparent;
                }
                &:active,
                &:hover {
                    background-color: transparent;
                    >i {
                        &:after {
                            color: @dark;
                            transition: all .2s;
                        }
                    }
                }
            }
        }
    }

    // form {
    //     > .form-field-type-columns {
    //         >.row {
    //             >div {
    //                 &:nth-child(1) {
    //                     width: 42%;
    //                     flex: auto;
    //                     max-width: initial;
    //                 }
                    
    //                 &:nth-child(2) {
    //                     width: 25%;
    //                     flex: auto;
    //                     max-width: initial;
    //                     &:empty {
    //                         display: none;

    //                         & + div {
    //                             .glyphicon-menu-right {
    //                                 inset-inline-start: 33% !important;
    //                             }
    //                         }
    //                     }
    //                 }

    //                 &:nth-child(3) {
    //                     width: 5%;
    //                     flex: auto;
    //                     max-width: initial;
    //                 }

    //                 &:nth-child(4) {
    //                     width: 28%;
    //                     flex: auto;
    //                     max-width: initial;

    //                     > .form-field-type-button {
    //                         button {
    //                             justify-content: center;
    //                         }
    //                     }
    //                 }

    //                 .form-field-type-panel,
    //                 .panel {
    //                     height: 100%;
    //                 }

    //                 @media (max-width: 1024px) {
    //                     &:nth-child(1) {
    //                         width: 35%;
    //                         flex: auto;
    //                         max-width: initial;
    //                     }
                        
    //                     &:nth-child(2) {
    //                         width: 25%;
    //                         flex: auto;
    //                         max-width: initial;
    //                     }
    
    //                     &:nth-child(3) {
    //                         width: 5%;
    //                         flex: auto;
    //                         max-width: initial;
    //                     }
    
    //                     &:nth-child(4) {
    //                         width: 35%;
    //                         flex: auto;
    //                         max-width: initial;
    //                     }
    //                 }
    //                 @media (max-width: 768px) {
    //                     &:nth-child(1) {
    //                         width: 100%;
    //                         flex: auto;
    //                         max-width: initial;
    //                         margin-bottom: 2rem;
    //                     }
                        
    //                     &:nth-child(2) {
    //                         width: 40%;
    //                         flex: auto;
    //                         max-width: initial;
    //                     }
    
    //                     &:nth-child(3) {
    //                         width: 10%;
    //                         flex: auto;
    //                         max-width: initial;
    //                     }
    
    //                     &:nth-child(4) {
    //                         width: 40%;
    //                         flex: auto;
    //                         max-width: initial;
    //                     }
    //                 }

    //                 @media (max-width: 640px) {
    //                     &:nth-child(1) {
    //                         width: 100%;
    //                         flex: auto;
    //                         max-width: initial;
    //                         margin-bottom: 2rem;
    //                     }
                        
    //                     &:nth-child(2) {
    //                         width: 100%;
    //                         flex: auto;
    //                         max-width: initial;
    //                         margin-bottom: 2rem;
    //                     }
    
    //                     &:nth-child(3) {
    //                         display: none;
    //                     }
    
    //                     &:nth-child(4) {
    //                         width: 100%;
    //                         flex: auto;
    //                         max-width: initial;
    //                     }
    //                 }
    //                 &.col-sm-4 {
    //                     &:first-of-type {
    //                         > .form-field-type-panel {
    //                             .panel {
    //                                 .panel-body {
    //                                     .form-field-type-panel {
    //                                         .panel {
    //                                             border-inline-start: none;

    //                                             .panel-heading {
    //                                                 padding: 0;
    //                                                 margin-bottom: 2.5rem !important;
    //                                             }
    //                                         }
    //                                     }
    //                                     .form-field-type-radio {
    //                                         .radio {
    //                                             label {
    //                                                 &.control-label {
    //                                                     display: flex;
    //                                                     align-items: center;

    //                                                     input[type="radio"] {
    //                                                         margin-top: 0;
    //                                                     }
    //                                                 }
    //                                             }
    //                                         }
    //                                     }
    //                                 }
    //                             }
    //                         }
    //                     }
    //                 }
    //             }

    //             @media (max-width: 768px) {
    //                 flex-wrap: wrap;
    //             }

    //             .panel {
    //                 padding: 0;
    //             }
    //         }
    //         &.formio-component-questionscolumns {
    //             > .row {
    //                 > div[class^="col-"] {
    //                     &:first-child {
    //                         .formio-component-initialquestionspanel {
    //                             > .panel {
    //                                 > .panel-body {
    //                                     > .formio-component-guideInitialDescription {
    //                                         & + .form-field-type-columns {
    //                                             > .row {
    //                                                 > div[class^="col-"] {
    //                                                     &:first-child {
    //                                                         > .form-group {
    //                                                             &:first-child {
    //                                                                 margin-top: 0;
    //                                                             }
    //                                                         }
    //                                                     }
    //                                                 }
    //                                             }
    //                                         }
    //                                     }
    //                                 }
    //                             }
    //                         }
    //                         .form-group {
    //                             &.form-field-type-panel {
    //                                 > .panel {
    //                                     > .panel-body {
    //                                         .form-group {
    //                                             &.form-field-type-fieldset {
    //                                                 fieldset {
    //                                                     > .form-group {
    //                                                         &.form-field-type-table {
    //                                                             table {
    //                                                                 tbody {
    //                                                                     tr {
    //                                                                         td {
    //                                                                             > .form-group {
    //                                                                                 &.form-field-type-columns {
    //                                                                                     margin-inline-start: 0;
    //                                                                                     margin-inline-end: 0;

    //                                                                                     > .row {
    //                                                                                         > div {
    //                                                                                             > .form-group {
    //                                                                                                 &.form-field-type-button {
    //                                                                                                     &.top-label-space {
    //                                                                                                         margin: 0 !important;
    //                                                                                                         display: flex;
    //                                                                                                         align-items: flex-end;
    //                                                                                                         height: 100%;
    //                                                                                                     }
    //                                                                                                 }
    //                                                                                             }
    //                                                                                         }
    //                                                                                     }
    //                                                                                 }
    //                                                                             }
    //                                                                         }
    //                                                                         &:first-child {
    //                                                                             > td {
    //                                                                                 .form-group {
    //                                                                                     &.form-field-type-columns {
    //                                                                                         &:first-child {
    //                                                                                             .form-group {
    //                                                                                                 &:first-child {
    //                                                                                                     margin-top: 0;

    //                                                                                                     &.top-label-space {
    //                                                                                                         margin-top: calc(15px + 0.8rem) !important;
    //                                                                                                     }
    //                                                                                                     &.form-field-type-button {
    //                                                                                                         &.top-label-space {
    //                                                                                                             margin: 0 !important;
    //                                                                                                             display: flex;
    //                                                                                                             align-items: flex-end;
    //                                                                                                             height: 100%;
    //                                                                                                         }
    //                                                                                                     }
    //                                                                                                 }
    //                                                                                             }
    //                                                                                         }
    //                                                                                     }
    //                                                                                 }
    //                                                                             }
    //                                                                             & ~ tr {
    //                                                                                 td {
    //                                                                                     > .form-group {
    //                                                                                         &:first-child {
    //                                                                                             margin-top: 3rem;
    //                                                                                         }
    //                                                                                     }
    //                                                                                 }
    //                                                                             }
    //                                                                         }
    //                                                                     }
    //                                                                 }
    //                                                             }
    //                                                         }
    //                                                         &.form-field-type-columns {
    //                                                             margin-inline-start: 0;
    //                                                             margin-inline-end: 0;

    //                                                             & + .form-field-type-columns {
    //                                                                 margin-top: 3rem;
    //                                                             }
    //                                                         }
    //                                                     }
    //                                                 }
    //                                             }
    //                                         }
    //                                     }
    //                                 }
    //                             }
    //                         }
    //                     }
    //                     &.col-sm-3 {
    //                         .block-fees {
    //                             .form-field-type-button {
    //                                 &.formio-component-feeOpenCloseButton {
    //                                     > button {
    //                                         justify-content: left;
    //                                         box-shadow: none !important;
    //                                         text-transform: none;
    //                                     }
    //                                     &.formio-component-btnValidateEnabled {
    //                                         margin-top: 0 !important;

    //                                         > button {
    //                                             justify-content: left;
    //                                         }
    //                                     }
    //                                 }
    //                                 &.formio-component-requirementOpenCloseButton {
    //                                     > button {
    //                                         justify-content: left !important;
    //                                     }
    //                                     &.formio-component-btnValidateEnabled {
    //                                         margin-top: 0 !important;

    //                                         > button {
    //                                             justify-content: left !important;
    //                                         }
    //                                     }
    //                                 }
    //                             }
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }
    //     .form-field-type-columns {
    //         &:not(.formio-component-questionscolumns) {
    //             .form-field-type-mycontent,
    //             .panel-body .form-field-type-mycontent {
    //                 margin-inline-start: 0 !important;
    //                 margin-inline-end: 0 !important;
    //             }
    //         }
    //     }
    //     > :not(.formio-component-questionscolumns) {
    //         .form-field-type-panel {
    //             > .panel {
    //                 > .panel-heading {
    //                     padding-top: 0 !important;
    //                     margin-bottom: 0 !important;
    //                     padding-bottom: 3rem;
    //                 }
    //                 > .panel-body {
    //                     padding-inline-start: 2.5rem !important;
    //                 }
    //             }
    //         }
    //         .form-field-type-textfield {
    //             & + .form-field-type-panel {
    //                 margin-top: 3rem;
    //             }
    //         }
    //     }
    // }

    .guide-alert-danger {
        margin-top: 3rem;
        border-radius: 2px !important;
        overflow: hidden;
        height: auto !important;

        >.panel {
            background: @danger !important;
            
            >.panel-heading {
                padding-bottom: 1.25rem !important;
                >h3 {
                    &.panel-title {
                        color: @white !important;
                    }
                }
            }
            >.panel-body {
                padding-bottom: 2.5rem !important;
                >.form-group {
                    &.form-field-type-mycontent {
                        margin: 0 !important;
                        padding: 0 !important;
                        background: transparent !important;
                        color: @white !important;
                        font-size: 1.5rem !important;
                        &:after {
                            display: none !important;
                        }
                    }
                }
            }
        }

    }

    .formio-component-btnValidateEnabled:not(.formio-component-requirementOpenCloseButton),
    .formio-component-columnsValidateandcontinue {
        margin-top: 1.5rem !important;

        &.margin-top-zero {
            margin-top: 0 !important;
        }
    }
    .formio-component-requirementOpenCloseButton {
        button {
            box-shadow: none !important;
        }
    }
    
    .form-group {
        &.form-field-type-radio {
            /*margin-bottom: -9px !important;*/
        }
    }
    > .form-group,
    form > .form-group {
        margin: 0;
    }
}
.guide-with-cart {
    .guide {
        .formio-form {
            .form-group {
                &.form-field-type-panel {
                    .form-field-type-panel {
                        .panel-body {
                            padding: 2.7rem 2.5rem 2.5rem !important;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 768px) {
    .col-sm-11 {
        +.col-sm-1 {
            position: absolute;
            inset-inline-end: .5rem;
            top: 0;
            margin: 0 !important;

            .form-field-type-mytooltip {
                a {
                    top: 0 !important;
                }
            }
        }
    }
    .guide .form-field-type-panel .panel .panel-body {
        padding: 0 2rem 2rem;
    }
}