// Margins
.mb-0 {
    margin-bottom: 0 !important;
}
.mb-1 {
    margin-bottom: 1rem * @marginbase !important;
}
.mb-2 {
    margin-bottom: 2rem * @marginbase !important;
}
.mb-3 {
    margin-bottom: 3rem * @marginbase !important;
}
.mb-4 {
    margin-bottom: 4rem * @marginbase !important;
}
.mt-0 {
    margin-top: 0 !important;
}
.mt-1 {
    margin-top: 1rem * @marginbase !important;
}
.mt-2 {
    margin-top: 2rem * @marginbase !important;
}
.mt-3 {
    margin-top: 3rem * @marginbase !important;
}
.mx-0 {
    margin-inline-start: 0 !important;
    margin-inline-end: 0 !important;
}
.ml-0 {
    margin-inline-start: 0 !important;
}
.ml-1 {
    margin-inline-start: 1rem * @marginbase !important;
}
.ml-2 {
    margin-inline-start: 2rem * @marginbase !important;
}
.mr-0 {
    margin-inline-end: 0 !important;
}
.mr-1 {
    margin-inline-end: 1rem * @marginbase !important;
}
.mr-2 {
    margin-inline-end: 2rem * @marginbase !important;
}
.mx-1 {
    margin-inline-start: 1rem * @marginbase !important;
    margin-inline-end: 1rem * @marginbase !important;
}

.mx-2 {
    margin-inline-start: 2rem * @marginbase !important;
    margin-inline-end: 2rem * @marginbase !important;
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.my-1 {
    margin-top: 1rem * @marginbase !important;
    margin-bottom: 1rem * @marginbase !important;
}

.my-2 {
    margin-top: 2rem * @marginbase !important;
    margin-bottom: 2rem * @marginbase !important;
}

.m-0 { margin: 0 !important; }

.pb-0 {
    padding-bottom: 0 !important;
}
.pb-1 {
    padding-bottom: 1rem * @marginbase !important;
}
.pb-2 {
    padding-bottom: 2rem * @marginbase !important;
}
.pt-0 {
    padding-top: 0 !important;
}
.pt-1 {
    padding-top: 1rem * @marginbase !important;
}
.pt-2 {
    padding-top: 2rem * @marginbase !important;
}
.px-0 {
    padding-inline-start: 0 !important;
    padding-inline-end: 0 !important;
}
.px-1 {
    padding-inline-start: 1rem * @marginbase !important;
    padding-inline-end: 1rem * @marginbase !important;
}

.px-2 {
    padding-inline-start: 2rem * @marginbase !important;
    padding-inline-end: 2rem * @marginbase !important;
}
.px-3 {
    padding-inline-start: 3rem * @marginbase !important;
    padding-inline-end: 3rem * @marginbase !important;
}
.pl-0 {
    padding-inline-start: 0 !important;
}
.pl-1 {
    padding-inline-start: 1rem * @marginbase !important;
}
.pl-2 {
    padding-inline-start: 2rem * @marginbase !important;
}
.pr-0 {
    padding-inline-end: 0 !important;
}
.pr-1 {
    padding-inline-end: 1rem * @marginbase !important;
}
.pr-2 {
    padding-inline-end: 2rem * @marginbase !important;
}
.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.py-1 {
    padding-top: 1rem * @marginbase !important;
    padding-bottom: 1rem * @marginbase !important;
}

.py-2 {
    padding-top: 2rem * @marginbase !important;
    padding-bottom: 2rem * @marginbase !important;
}

.p-0 { padding: 0 !important; }

.w-100 { width: 100%; }

.h-auto { height: auto !important; }

.d-inline-block {
    display: inline-block !important;
}