.drawer-content {
    .extra-history-options {
        .process-box-toggler {
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 6px;

            &.process-box-toggler-advanced {
                &.closed {
                    padding: 4px 8px 4px 0;
                    height: 35px;
                }
            }
            
            .fa-times {
                display: none;
            }
            span {
                color: var(--color-gray-800);
                line-height: 1.2;
                border-bottom: solid 1px;
            }
            &:not(.closed) {
                .fa-times {
                    display: inline-block;
                    margin-inline-end: 10px;
                }
                .fa-cog {
                    display: none;
                }
                span {
                    display: none;
                }
            }
        }
        .restart-process-box {
            margin-top: 0 !important;
            padding: 4px 8px;
            border-radius: 8px;
            background-color: var(--color-gray-75);
    
            &.closed {
                display: none;
            }
        }
    }
}

.history-container {
    .history-item {
        display: flex;
        &:first-child {
            .history-item-rail {
                &:before {
                    top: 8px;
                }
            }
        }
        &:last-child {
            .history-item-rail {
                &:before {
                    height: 8px;
                }
            }
        }
        &.closed {
            .history-alert {
                display: none;
            }
        }
        &.clickable {
            cursor: pointer;
        }
        .history-item-rail {
            width: 24px;
            text-align: center;
            flex-grow: 0;
            position: relative;
            margin-inline-end: 12px;
            i {
                color: var(--color-gray-600);
                z-index: 2;
                position: relative;
                &.text-warning {
                    font-size: 20px;
                }
            }
            &:before {
                content: " ";
                position: absolute;
                inset-inline-start: 50%;
                width: 1px;
                background: var(--color-gray-300);
                top: 0;
                bottom: 0;
                z-index: 1;
            }
        }
        .history-item-content {
            flex-grow: 1;
            small {
                display: flex;
                gap: 5px;
                color: rgba(107, 114, 128, 1);
                margin-bottom: 8px;
            }
            .status-badge {
                display: inline-block;
            }
            .history-info {
                padding: 3px 8px;
                margin-inline-start: 8px;
            }
            margin-bottom: 24px;
        }
    }
}
.corrections-list {
    padding-inline-start: 16px !important;
    &:empty {
        display: none;
    }
}
.history-alert {
    margin-top: 12px;
    padding: 24px;
    border-radius: 12px;
    background-color: #FFF0EB;
    &:is(.status-default, .status-unavailable) {
        color: var(--main-text-color);
        background-color: #E6E6EA !important;
    }
    &:is(.status-info, .status-pending, .status-filepending) {
        color: #1D86C8;
        background-color: #E4F2FB !important;
    }
    &:is(.status-success, .status-processed, .status-filevalidated) {
        color: #10B981;
        background-color: #E8F7F1;
    }
    &:is(.status-warning, .status-correction, .status-filedecline) {
        color: #FF7233;
        background-color: #FFF0EB !important;
    }
    &:is(.status-error, .status-rejected, .status-filereject, .status-danger) {
        color: #F53D3D;
        background-color: #FEE7E7 !important;
    }
}